<div class="container">
    <h2>Edit Sensor Name</h2>
    <mat-form-field>
        <mat-label>Edit</mat-label>
        <input type="text" matInput placeholder="Write something..." [(ngModel)]="sensor.name">
        <mat-icon matSuffix>mode_edit</mat-icon>
    </mat-form-field>
    <mat-dialog-actions align="end">
        <button mat-button [mat-dialog-close]="undefined">Cancel</button>
        <button mat-flat-button color="primary" (click)="onConfirm()">Save</button>
    </mat-dialog-actions>
    <div class="loading-wrapper" *ngIf="isLoading">
        <mat-spinner diameter="30"></mat-spinner>
    </div>
</div>