<div class="container">
    <div class="header" color="primary">
        <h2>Notifications</h2>
        <mat-icon class="button close-icon" (click)="dialogRef.close()">close</mat-icon>
    </div>
    <div class="content">
        <mat-card (mouseenter)="isActionsGroupVisible = true" (mouseleave)="isActionsGroupVisible = false"
                  class="card button" *ngFor="let notification of notifications; let i = index">
            <div class="card-header">
                <span class="zone-name">{{notification.ref.name}}</span>
                <span class="date">{{notification.at | date:'dd/MM/yy HH:mm'}}</span>
            </div>
            <div class="card-content">
                {{ notification.displayText }}
            </div>
            <div class="card-footer">
                <span>
                    <span class="material-icons icon">error</span>
                    <span class="alert-status">Warning</span>
                </span>
                <span class="actions-group" *ngIf="isActionsGroupVisible">
                    <button mat-button mat-icon-button onclick="this.blur()"
                            (click)="hideNotification(notification, i)"
                            matTooltip="Hide notification">
                        <mat-icon>
                            delete
                        </mat-icon>
                    </button>
                    <button mat-button mat-icon-button onclick="this.blur()"
                            (click)="armNotification(notification); hideNotification(notification, i)"
                            matTooltip="Hide notification and rearm notifications for sensor">
                        <mat-icon>
                            security
                        </mat-icon>
                    </button>
                </span>
            </div>
        </mat-card>

        <div class="empty-message" *ngIf="notifications.length === 0">There are no notifications.</div>
    </div>
</div>