import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RuleDialogComponent } from '../rule-dialog/rule-dialog.component';
import { SensorService } from 'src/app/services/sensor.service';
import { Router } from '@angular/router';
import { Appliance, Rule } from 'src/app/models/appliance';
import { ZoneService } from 'src/app/services/zone.service';
import { Zone } from 'src/app/models/zone';
import { ConfirmDialogComponent } from '../../general/confirm-dialog/confirm-dialog.component';
import { DataService } from 'src/app/services/data.service';
import { Subscription, interval } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SocketIoService } from 'src/app/services/socket-io.service';

@Component({
  selector: 'app-appliance-rule-list',
  templateUrl: './appliance-rule-list.component.html',
  styleUrls: ['./appliance-rule-list.component.less']
})
export class ApplianceRuleListComponent implements OnInit, OnDestroy {
  appliance: Appliance;
  rules: Rule[];
  zone: Zone;
  isLoading: boolean = false
  intervalObservable$: Subscription;
  ruleSubscription: Subscription;

  constructor(public dialog: MatDialog,
    private zoneService: ZoneService,
    private dataService: DataService,
    private socketService: SocketIoService) {

    this.dataService.sharedZone$.subscribe(zone => {
      this.zone = zone;
    });

    this.ruleSubscription = this.socketService.ruleMessages$.subscribe(rule => {


      try {
        console.log(rule);

        if (rule == null) {
          return;
        }

        if (rule.zoneId != this.zone._id) {
          return;
        }
        if ((typeof rule.rule) === 'string') {
          var ruleIndexToDelete =
            this.rules.findIndex((element) => element._id == rule.rule);
          if (ruleIndexToDelete > -1) {
            this.rules.splice(ruleIndexToDelete, 1);
          }
          return;
        }
        var ruleIndex = this.rules.findIndex((element) => element._id == rule._id);
        if (ruleIndex > -1) {
          this.rules[ruleIndex] = rule
        } else {
          // rule.addSensorObjectsToRule(_zone);
          this.rules.unshift(rule);
        }
      } catch (e) {
        console.error('rule socket failed', e);

      }
    })
  }

  ngOnInit(): void {
    this.getRules();
    // this.intervalObservable$ = interval(30 * 1000).subscribe(() => this.getRules());
  }

  ngOnDestroy() {
    // this.intervalObservable$.unsubscribe();
    this.ruleSubscription.unsubscribe();
  }

  getRules() {
    this.isLoading = true;
    this.zoneService.rules(this.zone._id).subscribe(result => {
      this.isLoading = false;
      this.rules = result.rules.reverse();
    })
  }

  openRuleDialog() {
    const dialogRef = this.dialog.open(RuleDialogComponent, {
      data: { appliance: this.appliance },
      panelClass: 'rule-dialog',
      maxWidth: '100vw'

    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) return;
      // add rule
      // this.rules.unshift(result);
    });
  }

  onEditRule(rule: Rule) {
    let _rule = JSON.parse(JSON.stringify(rule)) as Rule;

    const dialogRef = this.dialog.open(RuleDialogComponent, {
      data: { appliance: this.appliance, rule: _rule },
      panelClass: 'rule-dialog',
      maxWidth: '100vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) return;
      // edit rule
      // let index = this.rules.findIndex(x => x._id === rule._id)
      // if (index > -1) {
      //   this.rules[index] = result;
      // }
    });

  }

  onDeleteRule(rule: Rule) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.zoneService.deleteRule(this.zone._id, rule._id).subscribe(result => {
          this.rules.splice(this.rules.findIndex(x => x._id === rule._id), 1);
        })
      }
    })
  }

  onToggleChange(rule: Rule) {

    this.zoneService.updateRule(this.zone._id, rule).subscribe(response => { }
    )
  }

}
