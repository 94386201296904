import { Injectable, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Router } from '@angular/router';
import * as jwtDecode from 'jwt-decode';
import { ApiService } from './api.service';
import { Logger } from './logger.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

export const LS_TOKEN_KEY = 'auth-token';



export interface ILoggedInUser {
  email: string
};



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedInUser = new BehaviorSubject<ILoggedInUser>(null);

  get loggedInUser$(): Observable<ILoggedInUser> {
    return this.loggedInUser.asObservable();
  }

  public updateLoggedInUser(value?: ILoggedInUser) {
    this.loggedInUser.next(value);
  }

  constructor(public _router: Router,
    private _logger: Logger) {
    let loggedIn = <ILoggedInUser>this.getToken(true);
    this.loggedInUser.next(loggedIn);
    if (!loggedIn) {
      this.logout();
    }
  }

  private tryDecode(token): any {
    try {
      return jwtDecode(token);
    } catch (ex) {
      return null;
    }
  }

  public login(loginResponse) {
    // return this.userService.login(user)
    //   .pipe(
    //     tap((loginResponse) => {
    //       this.setToken(loginResponse.token);
    //       localStorage.setItem("user", loginResponse.email)
    //       this.loggedInUser.next(loginResponse);

    //     })
    //   );
    this.setToken(loginResponse.token);
    localStorage.setItem("user", loginResponse.email)
    this.loggedInUser.next(loginResponse);

  }

  public getToken(parse: boolean = false, deauthorize: boolean = true): string | ILoggedInUser | any {
    let token = localStorage.getItem(LS_TOKEN_KEY);
    let parsed = this.tryDecode(token);
    if (!parsed) {
      token = null;
      if (deauthorize) {
        this.logout();
      }
    }
    return parse ? parsed : token;
  }

  private setToken(token?: string) {
    if (!token) {
      localStorage.removeItem(LS_TOKEN_KEY);
    } else {
      localStorage.setItem(LS_TOKEN_KEY, token);
    }
  }
  public logout() {
    localStorage.clear();
    this.loggedInUser.next(null);
    this._router.navigate(['/login']);
  }

  public role() {
    let token = this.getToken(true);
    return token.role ?? 'r';
  }

  public canEdit() {
    return ['rw', 'sa', 'dv'].includes(this.role());
  }

  public get isSuperAdmin() {
    return this.role() == 'sa';
  }
}
