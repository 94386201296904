export class Cycle {
    name?: string;
    strainName?: string;
    id?: string;
    currentState?: CycleState;
    stateHistory?: CycleState[];
    dates?: DateRange;
}

export class CycleState {
    changedBy?: string;
    state: string = 'Start';
    dates: DateRange;
}

export class DateRange {
    start: string;
    end?: string;
}