import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  showMenu: boolean = false;
  title = 'indorz-ui-web';
  isLoggedIn: boolean;
  constructor(private authService: AuthService) {
    authService.loggedInUser$.subscribe(result => {
      this.isLoggedIn = !!result;
    })
  }
}
