import { Component, OnInit } from '@angular/core';
import { ZoneService } from 'src/app/services/zone.service';
import { Zone } from 'src/app/models/zone';
import { Cycle, CycleState } from 'src/app/models/cycle';
import { DataService } from 'src/app/services/data.service';
import { MatSelectChange } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { PairingDialogComponent } from './pairing-dialog/pairing-dialog.component';

@Component({
  selector: 'app-zone-settings',
  templateUrl: './zone-settings.component.html',
  styleUrls: ['./zone-settings.component.less']
})
export class ZoneSettingsComponent implements OnInit {
  zoneStates: string[] = [];
  hasActiveCycle: boolean = false;
  strainName: string;
  isLoading: boolean = false;
  zone: Zone;
  cycle: Cycle;
  customState: string;
  dayPartSetting: string;
  daypart = { day: { to: {} as string, from: {} as string } }

  constructor(private zoneService: ZoneService,
    private dataService: DataService,
    public dialog: MatDialog) {
    this.zoneStates = [
      'Start', 'Flowering', 'Harvest', 'Custom'
    ]

    this.dataService.sharedZone$.subscribe(zone => {
      this.zone = zone;
      this.cycle = this.zone.activeCycle || new Cycle();
      if (this.cycle.currentState) {
        if (this.zoneStates.findIndex(value => value === this.cycle.currentState.state) === -1) {
          this.customState = this.cycle.currentState.state;
          this.cycle.currentState.state = 'Custom';
        }
      }

    })

  }

  ngOnInit(): void {
    if (this.zone.dayPartSettings === undefined) {
      this.dayPartSetting = 'none';
      return;
    }

    if (this.zone.dayPartSettings.sensorType) {
      this.dayPartSetting = 'sensor';
      return;
    }

    if (this.zone.dayPartSettings.from) {
      let from = this.zone.dayPartSettings.from
      var fromDate = new Date(from)
      this.daypart.day.from = `${fromDate.getHours()}`.padStart(2, '0') + ':' + `${fromDate.getMinutes()}`.padStart(2, '0')
      this.dayPartSetting = 'custom';
    }

    if (this.zone.dayPartSettings.to) {
      let to = this.zone.dayPartSettings.to;
      var toDate = new Date(to)
      this.daypart.day.to = `${toDate.getHours()}`.padStart(2, '0') + ':' + `${toDate.getMinutes()}`.padStart(2, '0')
    }
  }

  startCycle() {
    this.isLoading = true;
    this.zoneService.postCycle(this.zone._id, { strainName: this.cycle.strainName }).subscribe(result => {
      this.cycle.id = result.id;
      this.cycle.name = result.name;
      let state = new CycleState();
      this.cycle.currentState = state;
      this.storeCycle();
      this.isLoading = false;
    })
  }

  endCycle() {
    this.isLoading = true;
    this.zoneService.updateCycle(this.zone._id, this.cycle.id, { state: null }).subscribe(result => {
      this.cycle = new Cycle();
      this.storeCycle();
      this.isLoading = false;
    })
  }

  updateState() {
    this.isLoading = true;
    let state = { state: this.cycle.currentState.state }
    if (this.cycle.currentState.state === 'Custom') {
      state = { state: this.customState }
    }
    this.zoneService.updateCycle(this.zone._id, this.cycle.id, state).subscribe(result => {
      this.storeCycle();
      this.isLoading = false;

    })
  }

  storeCycle() {
    this.zone.activeCycle = this.cycle;
    localStorage.setItem('zone', JSON.stringify(this.zone));
  }


  saveDayPart() {
    this.isLoading = true;
    if (this.dayPartSetting === 'none') {
      this.zoneService.deleteDayPart(this.zone._id).subscribe(result => {
        this.isLoading = false;
        this.zone.dayPartSettings = undefined;
      })
      return;
    } else {
      let obj;
      if (this.dayPartSetting === 'sensor') {
        obj = {
          sensorType: 'lux',
          value: 500
        }
      } else {
        let fromTime = this.daypart.day.from.split(":")
        let fromTimeObj = new Date();
        fromTimeObj.setHours(+fromTime[0], +fromTime[1], 0, 0)
        let toTime = this.daypart.day.to.split(":")
        let toTimeObj = new Date();
        toTimeObj.setHours(+toTime[0], +toTime[1], 0, 0)
        obj = {
          from: fromTimeObj.getTime(),
          to: toTimeObj.getTime()
        }
      }

      this.zoneService.setDayPart(this.zone._id, obj).subscribe(result => {
        this.isLoading = false;
        this.zone.dayPartSettings = {}
      }, error => {
        this.isLoading = false;
      })

    }



  }

  openPairingDialog() {
    const dialogRef = this.dialog.open(PairingDialogComponent, {
      data: { zoneId: this.zone._id },
      disableClose: true
    });
  }

}
