import { SensorType } from '../models/sensor';

export const SensorTypes: SensorType[] = [
    "temperature",
    "humidity",
    "hpa",
    "lux",
    "depth",
    "orp",
    "watt",
    "tds",
    "con",
    "ph",
    "rhs",
    "lqi",
    "co2",
    "rssi",
    "wdo",
    "uv",
    "ecs",
    "power",
    "battery",
    "watt",
    "rssi",
    "relay-temperature",
    "ethylene",
    "flow",
    "pressure"
]