<div class="add-rule button" (click)="openRuleDialog()" readOnly [hide]="true">Add Rule...</div>
<mat-card class="card">
    <div class="row" *ngFor="let rule of rules">
        <div class="rule-title">{{rule.name}}</div>
        <div class="actions-group">
            <button readOnly [hide]="true" mat-button mat-icon-button onclick="this.blur()" (click)="onEditRule(rule)">
                <mat-icon>edit</mat-icon>
            </button>
            <button readOnly [hide]="true" mat-button mat-icon-button onclick="this.blur()"
                    (click)="onDeleteRule(rule)">
                <mat-icon>delete</mat-icon>
            </button>
            <mat-slide-toggle readOnly color="primary" [(ngModel)]="rule.active" (change)="onToggleChange(rule)">
            </mat-slide-toggle>
        </div>
    </div>
</mat-card>
<div class="loading-wrapper-top">
    <mat-spinner diameter="30" *ngIf="isLoading"></mat-spinner>
</div>