<div class="container">
    <mat-card class="form-card">
        <div>
            <img src="/assets/images/logo.svg">
        </div>
        <form>

            <div>
                <mat-form-field class="input-field">
                    <mat-label>Email</mat-label>
                    <input placeholder="Email" type="email" name="email" [(ngModel)]="email" matInput>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="input-field">
                    <mat-label>Password</mat-label>
                    <input placeholder="Password" type="password" name="password" [(ngModel)]="password" matInput>
                </mat-form-field>
            </div>
            <button mat-flat-button color="primary" type="submit" class="login-button"
                    [disabled]="email.length === 0 || password.length === 0"
                    (click)="login()">Login</button>
            <div class="error-message" *ngIf="errorMessage">{{errorMessage}}</div>

        </form>
    </mat-card>

    <div class="loading-wrapper" *ngIf="isLoading">
        <mat-spinner diameter="30"></mat-spinner>
    </div>
</div>