import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Alert } from '../models/zone';
import { Rule } from '../models/appliance';
import { HttpParams } from '@angular/common/http';
import { Sensor } from '../models/sensor';

@Injectable({
  providedIn: 'root'
})
export class SensorService {

  constructor(private _api: ApiService) { }

  public sensor(sensorId: string) {
    return this._api.get<Sensor>(`/sensor/${sensorId}`);
  }

  public alerts(sensorId: string) {
    return this._api.get<Alert[]>(`/sensor/${sensorId}/alerts`);
  }

  public setAlert(id: string, alert: Alert) {
    return this._api.post<any>(`/sensor/${id}/alert`, alert);
  }

  public updateAlert(id: string, alert: Alert) {
    return this._api.put<any>(`/sensor/${id}/alert/${alert._id}`, alert);
  }

  public reports(request) {
    let params = new HttpParams();
    if (request.from) {
      params = params.append('from', request.from);
    }
    if (request.to) {
      params = params.append('to', request.to);
    }
    if (request.type) {
      params = params.append('type', request.type);
    }
    return this._api.get<any>(`/sensor/${request.sensorId}/report`, params);
  }

  public sensorAction(sensorId: string, value: number) {
    return this._api.post<any>(`/sensor/${sensorId}/action/call`, { value: value });
  }

  public sensorMode(sensorId: string, value: boolean) {
    return this._api.put<any>(`/sensor/${sensorId}/action`, { opAuto: value });
  }

  public sensorCalibration(sensorId: string, calibrationType: string) {
    return this._api.post<any>(`/sensor/${sensorId}/calibrate/${calibrationType}`);
  }

  public editSensorName(sensorId: string, sensorName: string) {
    return this._api.put<any>(`/sensor/${sensorId}`, { name: sensorName });
  }

  public assignSensorToZone(sensorId: string, zoneId: string) {
    return this._api.put(`/sensor/${sensorId}`, { zoneId: zoneId });
  }

  public editApplianceName(id: string, key: number, name: string) {
    return this._api.put<any>(`/sensor/${id}`, { name: name, key: key });
  }

  public deleteAppliance(id: string, key: number) {
    return this._api.delete<any>(`/sensor/${id}`, { key: key });
  }

  public csvExport(request) {
    let params = new HttpParams();
    params = params.append('sensor', request.sensorId)
    if (request.from) {
      params = params.append('from', request.from);
    }
    if (request.to) {
      params = params.append('to', request.to);
    }
    if (request.type) {
      params = params.append('type', request.type);
    }
    return this._api.get<any>(`/zone/${request.zoneId}/export`, params, 'text');
  }


}
