import { Component } from "@angular/core";
import { ApiService } from "src/app/services/api.service";

@Component({
    selector: 'backoffice',
    templateUrl: './backoffice.component.html',
    styleUrls: ['./backoffice.component.less']
})
export class BackofficeComponent {
    update: { [id: string]: any; } = {};
    create: { [id: string]: any; } = {
        company: {},
        user: {},
        gateway: {},
        zone: {},
        sensor: {}
    };
    search: { [id: string]: any } = {
        sensor: ''
    };
    items: { [id: string]: any[]; } = {};
    messages: { [id: string]: String; } = {};
    sensorKinds: String[] = [];
    actionTypes: String[] = [];
    gatewayActions: { [id: string]: String; } = {};

    constructor(private _api: ApiService) {
        _api.get<any>('/company').subscribe(resp => {
            this.items['company'] = resp.companies;
        }, err => {
            alert(err.message);
        });

        _api.get<any>('/user').subscribe(resp => {
            this.items['user'] = resp.users;
        }, err => {
            alert(err.message);
        });

        _api.get<any>('/gateway').subscribe(resp => {
            this.items['gateway'] = resp.gateways;
        }, err => {
            alert(err.message);
        });

        this.loadZones();
        this.loadSensors();
    }

    loadZones() {
        this.create['zone'] = {};
        this.update['zone'] = null;

        this._api.get<any>('/zone?slim=true').subscribe(resp => {
            this.items['zone'] = resp;
        }, err => {
            alert(err.message);
        });
    }

    loadSensors() {
        this.create['sensor'] = {};
        this.update['sensor'] = null;

        this._api.get<any>('/sensor').subscribe(resp => {
            this.items['sensor'] = resp.sensors.map(s => ({
                ...s,
                average: (s.average || []).join(',')
            }));
            this.sensorKinds = resp.sensorKinds;
            this.actionTypes = resp.actionTypes;
        }, err => {
            alert(err.message);
        });
    }

    showMessage(type, message, timeout = 2500) {
        this.messages[type] = message;
        setTimeout(() => {
            this.messages[type] = null;
        }, timeout);
    }

    createTunnel() {
        if (!this.gatewayActions.gateway) {
            return;
        }

        this._api.post(`/gateway/${this.gatewayActions.gateway}/tunnel`).subscribe((resp: any) => {
            this.showMessage('gateway-actions', `Tunnel created: ${resp.tunnel}`, 10000);
        }, err => {
            alert(err.message);
        }, () => {
            this.gatewayActions = {};
        });
    }

    updateGateway() {
        if (!this.gatewayActions.gateway) {
            return;
        }

        this._api.post(`/gateway/${this.gatewayActions.gateway}/update`, {
            version: this.gatewayActions.version,
            branch: this.gatewayActions.branch,
        }).subscribe((resp: any) => {
            this.showMessage('gateway-actions', 'Updated!');
        }, err => {
            alert(err.message);
        }, () => {
            this.gatewayActions = {};
        });
    }

    reloadGateway() {
        if (!this.gatewayActions.gateway) {
            return;
        }

        this._api.post(`/gateway/${this.gatewayActions.gateway}/reload`, {
            version: this.gatewayActions.version,
            branch: this.gatewayActions.branch,
        }).subscribe((resp: any) => {
            this.showMessage('gateway-actions', 'Reloaded!');
        }, err => {
            alert(err.message);
        }, () => {
            this.gatewayActions = {};
        });
    }

    closeAllTunnels() {
        this._api.post(`/gateway/tunnel/close`).subscribe((resp: any) => {
            this.showMessage('gateway-actions', `Closed ${resp.closed}!`);
        }, err => {
            alert(err.message);
        }, () => {
            this.gatewayActions = {};
        });
    }

    createOrUpdate(type) {
        if (this.update[type]) {
            this.updateItem(type);
        } else {
            this.createItem(type);
        }
    }

    createItem(type) {
        this._api.post<any>(`/${type}`, this.create[type]).subscribe(resp => {
            this.items[type].push(resp[type]);
            this.create[type] = {};
            this.showMessage(type, 'Created!');
        }, err => {
            alert(err);
        });
    }

    updateItem(type) {
        this._api.put<any>(`/${type}/${this.create[type]._id}`, this.create[type]).subscribe(resp => {
            this.create[type] = {};
            this.update[type] = null;
            this.showMessage(type, 'Updated!');
            let index = this.items[type].findIndex(item => item._id === resp[type]._id);
            if (index > -1) {
                this.items[type][index] = resp[type];
            }
        }, err => {
            alert(err.message);
        });
    }

    delete(type) {
        if (!window.confirm(`Are you sure you want to delete the ${type} "${this.create[type].name}"?`)) {
            return;
        }
        this._api.delete(`/${type}/${this.create[type]._id}`, this.create[type].deleteRelation).subscribe((resp: any) => {
            let index = this.items[type].findIndex(item => item._id === resp.deleted);
            if (index > -1) {
                this.items[type].splice(index, 1);
            }
            if (this.create[type].deleteRelatedSensors) {
                this.loadSensors();
            }
            if (this.create[type].deleteRelatedZones) {
                this.loadZones();
            }
            this.create[type] = {};
            this.update[type] = null;
            this.showMessage(type, 'Deleted!');
        }, err => {
            alert(err);
        });
    }

    filterZoneByGateway(what, type) {
        return (item) => {
            return item[what] === this.create[type][what];
        }
    }

    expand(id, type, prop) {
        if (this.items[type]) {
            let found = this.items[type].findIndex(item => item._id === id);
            if (found > -1) {
                return this.items[type][found][prop];
            }
        }
        return id;
    }

    itemChanged(event) {
        //console.log(this.search.sensor);
        console.log(event);
    }
}