import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SensorTypes } from 'src/app/constants/SensorTypes';
import { ReducedSensor, Sensor, SensorType } from 'src/app/models/sensor';
import { Zone } from 'src/app/models/zone';
import { DataService } from 'src/app/services/data.service';
import { SensorService } from 'src/app/services/sensor.service';
import { CalibrationDialogComponent } from './calibration-dialog/calibration-dialog.component';

const availableCalibrationTypes: SensorType[] = ['ph'];
// const calibrationOptions = {
//   'ph': [{ value: 'ph1', viewValue: 'pH 1' },
//   { value: 'ph2', viewValue: 'pH 2' },
//   { value: 'ph3', viewValue: 'pH 3' }]
// }
const calibrationOptions =
  [{ value: 'co2', viewValue: 'CO2', sensorType: 'co2' },
  { value: 'ec', viewValue: 'EC', sensorType: 'ecs' },
  { value: 'ph4', viewValue: 'pH 4', sensorType: 'ph' },
  { value: 'ph7', viewValue: 'pH 7', sensorType: 'ph' },
  { value: 'ph10', viewValue: 'pH 10', sensorType: 'ph' },
  { value: 'doa', viewValue: 'DOA', sensorType: 'ph' },
  { value: 'doz', viewValue: 'DOZ', sensorType: 'ph' },
  ];


@Component({
  selector: 'app-sensor-calibration',
  templateUrl: './sensor-calibration.component.html',
  styleUrls: ['./sensor-calibration.component.less']
})
export class SensorCalibrationComponent implements OnInit {

  private sensor: Sensor;
  sensors: ReducedSensor[];

  constructor(private dataService: DataService,
    public dialog: MatDialog) {
    dataService.sharedSensor$.subscribe(result => {
      this.sensor = result;
    });
  }

  ngOnInit(): void {
  }

  calibrate() {
    const dialogRef = this.dialog.open(CalibrationDialogComponent, {
      data: {
        // sensorType: sensorType,
        calibrationOptions: calibrationOptions,
        sensorId: this.sensor._id,
        zoneId: this.sensor.zone
      },
      disableClose: true
    });
  }

}
