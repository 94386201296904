import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackofficeComponent } from './backoffice.component';
import { BackofficeRoutingModule } from './backoffice-routing.module';
import { FormsModule } from '@angular/forms';
import { BackofficeFilterPipe } from './backoffice-filter.pipe';

@NgModule({
  declarations: [
      BackofficeComponent,
      BackofficeFilterPipe
    ],
  imports: [
    CommonModule,
    BackofficeRoutingModule,
    FormsModule
  ]
})
export class BackofficeModule { }
