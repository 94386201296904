<div class="input-group">
       <input type="text"
              oninput="
           javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              maxlength="2" placeholder="00"
              type="number"
              [(ngModel)]="time.hours"
              (ngModelChange)="change()">
       <span>:</span>
       <input type="text" maxlength="2"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);
           if(this.value > this.max) this.value = this.max"
              placeholder="00" type="number" max="59"
              [(ngModel)]="time.minutes"
              (ngModelChange)="change()">
       <span>:</span>
       <input type="number"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);
           if(this.value > this.max) this.value = this.max"
              maxlength="2" placeholder="00" type="number" max="59"
              [(ngModel)]="time.seconds"
              (ngModelChange)="change()">
       <ng-container *ngIf="hasMilliseconds">
              <span>:</span>
              <input type="number"
                     oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);
                     if(this.value > this.max) this.value = this.max"
                     maxlength="3" placeholder="000" type="number" max="999"
                     [(ngModel)]="time.milliseconds"
                     (ngModelChange)="change()">
       </ng-container>
</div>