<mat-card class="card" readOnly>
    <div class="row" *ngFor="let appliance of appliances"
        [ngClass]="{'disabled': !appliance.gwOnline || [ControlModeEnum.off, ControlModeEnum.manual].includes(appliance.controlMode)}">
        <div class="row-wrapper">
            <div class="row-container">
                <div class="appliance-title override-disabled">
                    <span *ngIf="appliance.gwOnline" class="indicator"
                        [style.background-color]="appliance.indicatorColor"></span>
                    {{appliance.name}}
                    <button mat-button mat-icon-button onclick="this.blur()" (click)="editApplianceName(appliance)">
                        <mat-icon>
                            edit
                        </mat-icon>
                    </button>
                </div>
                <div class="appliance-subtitle" *ngIf="appliance.watt || appliance.temperature || appliance.rssi">
                    ({{appliance.temperature !== undefined ? appliance.temperature : 'NA-'}}C°
                    {{appliance.watt !== undefined ? appliance.watt : 'NA-'}}W
                    {{appliance.rssi !== undefined ? appliance.rssi : 'NA-'}}dB)
                </div>
                <div class="last-ping">
                    Appliance id: {{appliance.sensorId}}
                </div>
                <div class="last-ping">
                    Last ping: {{ appliance.lastPing === undefined ? 'Not supported' : appliance.lastPing | date:
                    'dd.MM.yy
                    HH:mm'}}
                </div>
            </div>
            <div class="actions-group" *ngIf="ApplianceToggleTypes.includes(appliance.sensorKind)">
                <ng-container *ngIf="(!appliance.opAuto && appliance.type === 'toggle'); else fan">
                    <mat-slide-toggle color="primary" [(ngModel)]="appliance.valueAsBoolean"
                        (change)="onToggleChange($event, appliance)" #slideToggle>
                    </mat-slide-toggle>
                </ng-container>
                <ng-template #fan>
                    <mat-select *ngIf="!appliance.opAuto && appliance.actions.length > 0" [value]="prevValue"
                        [(ngModel)]="appliance.value" (selectionChange)="onAction($event, appliance)" class="dropdown">
                        <mat-option *ngFor="let action of appliance.actions" [value]="action.value">
                            {{action.viewValue}}
                        </mat-option>
                    </mat-select>
                </ng-template>
                <mat-select [(ngModel)]="appliance.opAuto" (selectionChange)="onSetMode($event, appliance)"
                    class="dropdown">
                    <mat-option [value]="true">AUTO</mat-option>
                    <mat-option [value]="false">MANUAL</mat-option>
                </mat-select>
                <div class="current-status">{{ viewValue(appliance.type, appliance.value, appliance.sensorKind) }}
                </div>
            </div>
        </div>
        <div class="slider-container" *ngIf="ApplianceSliderTypes.includes(appliance.sensorKind)">
            <div class="flex" *ngIf="ApplianceCrankTypes.includes(appliance.sensorKind)">
                <button mat-button mat-icon-button onclick="this.blur()" (click)="sendAction(102, appliance)">
                    <mat-icon class="rotate-90">unfold_more</mat-icon>
                </button>
                <button mat-button mat-icon-button onclick="this.blur()" (click)="sendAction(101, appliance)">
                    <mat-icon>stop</mat-icon>
                </button>
                <button mat-button mat-icon-button onclick="this.blur()" (click)="sendAction(103, appliance)">
                    <mat-icon class="rotate-90">unfold_less</mat-icon>
                </button>
            </div>
            <mat-slider thumbLabel [(ngModel)]="appliance.value" min="0" max="100" color="primary"
                (change)="onAction($event, appliance)"></mat-slider>
            <div class="value">{{appliance.value || 0}}</div>

        </div>

    </div>
    <div class="loading-wrapper" *ngIf="isLoading">
        <mat-spinner diameter="30"></mat-spinner>
    </div>
</mat-card>
<div class="page-header">
    Appliance Log
</div>
<div class="table-filters">
    <mat-form-field class="form-field">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Type something..." #input>
    </mat-form-field>
    <mat-form-field appearance="legacy" class="form-field">
        <mat-label>Filter by Catergory</mat-label>
        <mat-select multiple [(ngModel)]="categories" (selectionChange)="onMultipleChoose($event)">
            <mat-option [value]="'errors'">
                Errors
            </mat-option>
            <mat-option [value]="'report'">
                Reports
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
        <mat-form-field appearance="legacy" class="form-field">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Start date" [(ngModel)]="selectedRange.from">
                <input matEndDate placeholder="End date" [(ngModel)]="selectedRange.to">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <button mat-flat-button [disabled]="selectedRange === undefined" color="primary"
            (click)="fetchData()">Apply</button>

    </div>
</div>
<div class="table-container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 table" matSort>

        <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.width.%]="10"> Date </th>
            <td mat-cell *matCellDef="let element" [style.width.%]="20"> {{element?.createdAt | date : 'dd.MM.yy
                HH:mm:ss'}} </td>
        </ng-container>
        <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.width.%]="15"> User </th>
            <td mat-cell *matCellDef="let element" [style.width.%]="20"> {{element?.user?.name}} </td>
        </ng-container>

        <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [style.width.%]="60"> Message </th>
            <td mat-cell *matCellDef="let element" [style.width.%]="60"> {{element?.message}} </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.isError ? 'error-row' : ''"
            [@rowsAnimation]=""></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
    </table>
    <div class="loading-wrapper-top" *ngIf="loading">
        <mat-spinner diameter="30"></mat-spinner>
    </div>
</div>
<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="pageSize"></mat-paginator>