import { Directive, ElementRef, Input } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Directive({
  selector: '[readOnly]'
})
export class ReadOnlyDirective {

  @Input() hide: boolean;

  constructor(private elementRef: ElementRef,
    private authService: AuthService) {

  }

  ngOnInit() {
    if (this.authService.canEdit()) {
      return;
    }
    if (this.hide) {
      this.elementRef.nativeElement.style.display = 'none';
    }
    this.elementRef.nativeElement.style['pointer-events'] = 'none';
    this.elementRef.nativeElement.style.opacity = '0.5';
    this.elementRef.nativeElement.style.cursor = 'not-allowed';
  }

}
