import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Logger } from '../../../services/logger.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  email: string = "";
  password: string = "";
  isLoading: boolean = false;
  errorMessage: string = undefined;

  constructor(private authService: AuthService,
    private _logger: Logger, private _router: Router,
    private userService: UserService) { }

  ngOnInit() {
    this.authService.loggedInUser$.subscribe((result) => {
      if (!!result) {
        this._router.navigate(['']);
      }
    })
  }

  login() {
    this.errorMessage = undefined;
    this.isLoading = true;
    this.userService.login({ email: this.email, password: this.password }).subscribe((loginResponse) => {
      this._logger.info('Logged in in login component', loginResponse);
      this.authService.login(loginResponse);
    }, (err) => {
      this.isLoading = false;

      switch (err.status) {
        case 401:
          this._logger.info('Incorrect');
          break;
      }
      this.errorMessage = "The email or password you've entered is not correct, please try again";

    }, () => {
      this.isLoading = false;
      this._router.navigate(['']);
    });
  }
}
