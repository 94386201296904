export class Note {
    _id?: string;
    date?: Date;
    content?: string;
    attachments?: Attachment[];
}

export interface Attachment {
    _id?: string;
    label?: string;
    size?: number;
    filename?: string;
}