import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './config/httpInterceptor';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainFrameComponent } from './components/main-frame/main-frame.component';
import { SideMenuComponent } from './components/main-frame/side-menu/side-menu.component';
import { TopBarComponent } from './components/main-frame/top-bar/top-bar.component';
import { NotificationsComponent } from './components/main-frame/notifications/notifications.component';
import { SharedModule } from './modules/shared.module';
import { SettingsComponent } from './components/settings/settings.component';
import { ConfirmDialogComponent } from './components/general/confirm-dialog/confirm-dialog.component';
import { ReportsComponent } from './components/reports/reports.component';
import { DatePipe, DecimalPipe } from '@angular/common';
import { UserModule } from './components/user/user.module';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { ReportsModule } from './components/reports/reports.module';
import { ZoneModule } from './components/zone/zone.module';
import { SettingsModule } from './components/settings/settings.module';
import { SensorModule } from './components/sensor/sensor.module';
import { SensorValuePipe } from './pipes/sensor-value.pipe';
import { BackofficeModule } from './components/backoffice/backoffice.module';

import { environment } from 'src/environments/environment';
import { SocketIoService } from './services/socket-io.service';
import { EditNameDialogComponent } from './components/dialogs/edit-name-dialog/edit-name-dialog.component';


@NgModule({
  declarations: [
    AppComponent,
    NotificationsComponent,
    ConfirmDialogComponent,
    SideMenuComponent,
    TopBarComponent,
    EditNameDialogComponent,
  ],
  imports: [
    BrowserModule,
    ReportsModule,
    ZoneModule,
    UserModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DashboardModule,
    SettingsModule,
    UserModule,
    SensorModule,
    BackofficeModule,
    AppRoutingModule,
  ],
  providers: [
    SocketIoService,
    DatePipe, DecimalPipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
  ],
  entryComponents: [
    NotificationsComponent,
    EditNameDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
