import { Component, OnInit, ViewChild } from '@angular/core';
import { SensorType, Sensor, excludedSensorTypes } from 'src/app/models/sensor';
import { SensorService } from 'src/app/services/sensor.service';
import { DataService } from 'src/app/services/data.service';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexTooltip,
  ApexXAxis,
  ApexLegend,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexYAxis,
  ApexStroke
} from "ng-apexcharts";
import { SensorPipe } from 'src/app/pipes/sensor-display-name.pipe';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import * as FileSaver from 'file-saver';


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  markers: any; //ApexMarkers;
  stroke: ApexStroke; //ApexStroke;
  yaxis: ApexYAxis | ApexYAxis[];
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  fill: ApexFill;
  tooltip: ApexTooltip;
  colors: string[];
};

@Component({
  selector: 'app-sensor-reports',
  templateUrl: './sensor-reports.component.html',
  styleUrls: ['./sensor-reports.component.less']
})
export class SensorReportsComponent implements OnInit {
  sensor: Sensor;
  sensorId: string;
  sensorTypeOptions: SensorType[];
  selectedTypes: SensorType[] = [];
  selectedRange: any = {};
  isLoading: boolean = false;
  dataSource: any = [];
  splicedDataSource: any = [];
  displayedColumns: string[] = [];

  // MatPaginator Inputs
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  @ViewChild("chart") chart: ChartComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public chartOptions: Partial<ChartOptions>;

  constructor(private sensorService: SensorService,
    private dataService: DataService,
    private sensorDisplayName: SensorPipe,
    private route: ActivatedRoute,
    private router: Router) {

    this.dataService.sharedSensor$.subscribe(sensor => {
      if (sensor === null) {
        return;
      }
      this.sensor = sensor;

      this.sensorTypeOptions = this.sensor.types.filter(x => !excludedSensorTypes.includes(x));
    })

    this.chartOptions = {
      colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#4caf50', '#4ecdc4', '#f8961e'],
      series: [
      ],
      chart: {
        height: 350,
        type: "line",
        stacked: false,
        toolbar: { show: false }
      },
      dataLabels: {
        enabled: false
      },
      tooltip: {
        fixed: {
          enabled: true,
          position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60
        },
        x: {
          format: 'dd/MM/yyyy HH:mm'
        }

      },
      legend: {
        horizontalAlign: "left",
        offsetX: 40
      },
      stroke: {
        curve: "smooth",
      }
    };
  }

  ngOnInit(): void {
    this.route.parent.params.subscribe(params => {
      this.sensorId = params.sensorId;
    });
    if (history.state.type != undefined) {
      this.selectedTypes.push(history.state.type)
      this.selectedRange.from = new Date()
      this.selectedRange.to = new Date()
      this.fetchData()
    }
  }

  pageChangeEvent(event) {
    const offset = ((event.pageIndex + 1) - 1) * event.pageSize;
    this.splicedDataSource = this.dataSource.slice(offset).slice(0, event.pageSize);
  }

  fetchData() {
    this.dataSource = [];
    this.isLoading = true;
    var from;
    var to;
    //from = Date.parse(this.selectedRange.from);
    from = new Date(this.selectedRange.from) as Date;
    from.setHours(0, 0);
    from = Date.parse(from);
    to = new Date(this.selectedRange.to) as Date;
    to.setHours(23, 59);
    to = Date.parse(to);

    // from = new Date(this.selectedRange.from);
    // from = new Date(Date.UTC(from.getFullYear(), from.getMonth(), from.getDate())).getTime();
    // to = new Date(this.selectedRange.to);
    // to = new Date(Date.UTC(to.getFullYear(), to.getMonth(), to.getDate(), 23, 59)).getTime();

    let request = {
      sensorId: this.sensorId,
      type: this.selectedTypes || undefined,
      from: from,
      to: to,
    }
    this.sensorService.reports(request).subscribe(result => {
      this.isLoading = false
      this.handleResult(result.report)
      this.updateChart(result.report, this.chartOptions)
    })
  }

  handleResult(result) {
    if (result.length === 0) {
      this.dataSource = [];
      this.splicedDataSource = [];
      return;
    };
    var typeIndex = 0;
    let temp = result.reduce((acc, item) => {
      if (typeIndex === 0) {
        var lastAddedRow = acc[acc.length - 1] || {}
        if (lastAddedRow != {}) {
          lastAddedRow = {};
        }
        if (!lastAddedRow[item._id.type]) {
          lastAddedRow[item._id.type] = item.avgValue.toFixed(2)
        }
        if (!lastAddedRow['date']) {
          lastAddedRow['date'] = item._id.date
        }
        acc.push(lastAddedRow)
        typeIndex++;
      } else {
        if (typeIndex === this.selectedTypes.length) {
          typeIndex = 0;
        } else {
          acc[acc.length - 1][item._id.type] = item.avgValue.toFixed(2)
          typeIndex++;
        }

      }
      return acc;
    }, [])
    this.dataSource = temp;
    this.displayedColumns = Object.keys(this.dataSource[0]);
    this.displayedColumns.sort((a, b) => {
      if (a === 'date') return -1;
      return 0;
    })
    this.splicedDataSource = this.dataSource.slice(((0 + 1) - 1) * this.pageSize).slice(0, this.pageSize);

  }

  updateChart(result: any, chartOption: Partial<ChartOptions>) {
    if (result.length === 0) {
      let series: ApexAxisChartSeries = []
      chartOption.series = series
      return;
    };
    let values = result;
    let temp = values.filter(x => x.avgValue != null)
      .reduce((acc, item) => {
        let obj = { value: +item.avgValue?.toFixed(2), date: item._id.date }
        let index = acc.findIndex(x => x.name === item._id.type);
        if (index > -1) {
          acc[index].values.push(obj);
        } else {
          acc.push({ name: item._id.type, values: [obj] })
        }
        return acc;
      }, [])
    temp.forEach(x => x.values.reverse());
    let series: ApexAxisChartSeries = temp.map(x => {
      return {
        name: x.name,
        data: x.values.map(v => [new Date(v.date).getTime(), v.value])
      }
    })
    chartOption.series = series
    let xAxis: ApexXAxis = {
      type: 'datetime',
      tickAmount: 6,
      labels: {
        datetimeUTC: false
      }
    }
    chartOption.xaxis = xAxis;
    let yAxis = temp.map((x, index) => {
      let color = this.chartOptions.colors[index % this.chartOptions.colors.length]
      return {
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: color
        },
        labels: {
          style: {
            colors: color
          }
        },
        title: {
          text: this.sensorDisplayName.transform(x.name),
          style: {
            color: color,
            fontSize: 14
          }
        },
        tooltip: {
          enabled: true
        }
      }
    })
    chartOption.yaxis = yAxis;
  }

  exportToCSV() {
    this.isLoading = true;
    var from;
    var to;

    from = new Date(this.selectedRange.from) as Date;
    from.setHours(0, 0);
    from = Date.parse(from);
    to = new Date(this.selectedRange.to) as Date;
    to.setHours(23, 59);
    to = Date.parse(to);


    let request = {
      sensorId: this.sensorId,
      zoneId: this.sensor.zone,
      type: this.selectedTypes || undefined,
      from: from,
      to: to,
    }

    this.sensorService.csvExport(request).subscribe(result => {
      this.isLoading = false
      const blob = new Blob([result], { type: 'type/octet-stream' });
      FileSaver.saveAs(blob, 'csv-export.csv');
    })
  }

}
