<mat-card>
    <div class="row">
        <div class="title">{{zone.name | uppercase}}</div>
        <button *ngIf="showArrow" mat-button mat-icon-button onclick="this.blur()" (click)="viewZone()">
            <mat-icon>arrow_forward</mat-icon>
        </button>
    </div>
    <div class="gateway-status" *ngIf="zone.online !== undefined; else notAvailable">
        <div class="flex items-center">
            <div class="indicator" [ngClass]="zone.online ? 'online' : 'offline'"></div>
            Gateway status: {{zone.online ? 'online' : 'offline'}}
        </div>
    </div>
    <ng-template #notAvailable>
        Gateway status: not available
    </ng-template>
    <div class="gateway-id" *ngIf="zone.gateway">
        Gateway id: {{zone.gateway}}
    </div>
    <div class="day-week-count" *ngIf="zone.activeCycle">
        <span>Current state: {{zone.activeCycle.currentState.state}} | </span> <b>Week {{growCycleCount.week}} Day
            {{growCycleCount.day}}</b>
        <hr>
    </div>

    <ng-container *ngFor="let category of zone.reducedSensors">
        <div class="category-header">
            {{categories[category.kind] | uppercase}}
        </div>
        <ng-container *ngFor="let sensor of category.values">
            <div class="sensor-type-header">
                <span class="name">{{sensor.type | sensorDisplayName}}</span>
                <span class="type-format">{{sensor.type | sensorFormat}}</span>
            </div>

            <div class="values-container">
                <div class="value-container" *ngFor="let item of sensor.values"
                     (click)="onPointClick($event, item, sensor.type)">
                    <div class="value-box"
                         [ngClass]="{'error' : isOffRange(item.value && item.value[0], sensor.type)}">
                        {{item.value && item.value[0] | sensorValue:sensor.type | number:'0.0-2' }}</div>
                    <div class="sensor-name" [matTooltip]="item.sensorName">{{item.sensorName}}</div>
                </div>
            </div>
        </ng-container>

    </ng-container>

</mat-card>