import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LogComponent } from '../log/log.component';
import { ReportsComponent } from './reports.component'

const routes: Routes = [{ path: 'reports', component: ReportsComponent },
{ path: 'log', component: LogComponent }];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ReportsRoutingModule { }