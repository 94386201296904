import { Injectable } from '@angular/core';

export type MeasureUnit = 'metric' | 'imperial' | 'custom';
export type ECUnit = 'EC' | 'TDS';
export type Temperature = 'celsius' | 'fahrenheit';
export type Volume = 'liter' | 'gallon';
export type Length = 'meter' | 'feet';
export type Weight = 'gram' | 'ounce';

export interface Settings {
  ecUnit?: ECUnit,
  measureUnit?: MeasureUnit,
  temperature?: Temperature,
  volume?: Volume,
  length?: Length,
  weight?: Weight
}

export const defaultSettings = {
  ecUnit: 'EC' as ECUnit,
  measureUnit: 'metric' as MeasureUnit,
  temperature: 'celsius' as Temperature,
  volume: 'liter' as Volume,
  length: 'meter' as Length,
  weight: 'gram' as Weight
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {


  constructor() { }

  public setSetting(k: string, v: string) {
    localStorage.setItem('settings_' + k, v);
  }

  public getSetting(k: string) {
    return localStorage.getItem('settings_' + k) || defaultSettings[k];
  }
}
