<mat-card>
    <div class="card-header">
        <div class="title-date-wrapper">
            <div class="indicator-title-wrapper">
                <span class="indicator"
                      [ngClass]="{'active': isSensorActive, 
                    'not-available': sensor?.lastValues?.length === 0}">
                </span>
                <div class="title" (click)="openSensor()">
                    {{sensor.name | uppercase}}
                </div>
            </div>
            <div class="last-updated" *ngIf="sensor?.lastValues?.length > 0">
                Last updated at: {{ lastUpdated | date:'dd.MM.yy HH:mm'}}</div>
            <div class="last-updated sensor-id">
                Sensor id: {{sensor?._id}}</div>
        </div>
        <div class="buttons-group">
            <button mat-button mat-icon-button onclick="this.blur()" (click)="openSensorAlertSettings()">
                <mat-icon>
                    settings
                </mat-icon>
            </button>
            <button mat-button mat-icon-button onclick="this.blur()" (click)="openSensor()">
                <mat-icon>arrow_forward</mat-icon>
            </button>
        </div>
    </div>
    <div class="card-content">
        <ng-container *ngIf="sensor.kind != 'root'; else forkTemplate">
            <div class="row" *ngFor="let item of sensor.lastValues">
                <div class="group type">
                    <div class="icon-box">
                        <img src="../assets/images/{{item.value ? item.value.type : ''}}.svg"
                             fallback="./assets/images/not_found.svg" class="icon">
                    </div>
                    <div class="title">{{item.value ? (item.value.type | sensorDisplayName) : '' }}</div>
                </div>
                <div class="group value">
                    <div class="value">
                        {{item.value ? (item.value.value[0] | sensorValue:item.value.type | number:'0.0-2') :
                        ''}} <span class="format">{{item.value ? '('+(item.value.type | sensorFormat)+')' : '' }}</span>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #forkTemplate>
            <ng-container *ngFor="let fork of sensor.forks">
                <div class="row fork">
                    <div class="group type">
                        <div class="title">{{fork.name}}</div>
                    </div>
                </div>
                <div class="row" *ngFor="let value of fork.values">
                    <div class="group type">
                        <div class="icon-box">
                            <img src="../assets/images/{{value ? value.type : ''}}.svg"
                                 fallback="./assets/images/not_found.svg" class="icon">
                        </div>
                        <div class="title">{{value ? (value.type | sensorDisplayName) : '' }}</div>
                    </div>
                    <div class="group value" *ngIf="value.value">
                        <div class="format">{{ value.type | sensorFormat }}</div>
                        <div class="value">{{ value.value[0] | sensorValue:value.type |number:'0.0-2' }}</div>
                    </div>
                </div>
            </ng-container>
        </ng-template>
    </div>
</mat-card>