import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReducedSensor } from 'src/app/models/sensor';
import { Alert } from 'src/app/models/zone';
import { ZoneService } from 'src/app/services/zone.service';
import { SensorService } from 'src/app/services/sensor.service';


@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.less']
})
export class AlertDialogComponent implements OnInit {

  isLoading: boolean = false;

  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private zoneService: ZoneService,
    private sensorService: SensorService) { }

  ngOnInit(): void {
  }

  onConfirm() {
    this.isLoading = true;
    let service = this.data.isZone ? this.zoneService : this.sensorService;
    // if (this.data.alert._id) {
    //   service.updateAlert(this.data.id, this.data.alert).subscribe(result => {
    //     this.isLoading = false;
    //     this.dialogRef.close(this.data.alert);
    //   })
    // } else {
    //   service.setAlert(this.data.id, this.data.alert).subscribe(result => {
    //     this.isLoading = false;
    //     this.data.alert._id = result.created;
    //     this.data.alert.active = true;
    //     this.dialogRef.close(this.data.alert);
    //   })
    // }
    service.setAlert(this.data.id, this.data.alert).subscribe(result => {
      this.isLoading = false;
      this.data.alert._id = result.created;
      this.data.alert.active = true;
      this.dialogRef.close(this.data.alert);
    })
  }


}


