import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Zone } from 'src/app/models/zone';
import { MatSelectChange } from '@angular/material/select';
import { ZoneService } from 'src/app/services/zone.service';
import { Sensor } from 'src/app/models/sensor';
import { Observable, Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { interval } from 'rxjs/observable/interval';
import { SocketIoService } from 'src/app/services/socket-io.service';

@Component({
  selector: 'app-zone',
  templateUrl: './zone.component.html',
  styleUrls: ['./zone.component.less']
})
export class ZoneComponent implements OnInit, OnDestroy {
  activeLinkIndex = -1;
  navLinks: any[];
  zone: Zone;
  zones: Zone[]; // zone list for dropdown
  selectedZoneId: string;
  isLoading: boolean = false;
  zoneId: string;
  private subscription: Subscription;

  // interval: any;

  constructor(private router: Router, private zoneService: ZoneService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private socketService: SocketIoService) {
    this.navLinks = [
      {
        label: 'Overview',
        link: './overview',
        index: 0,
      },
      {
        label: 'Notifications',
        link: './notifications',
        index: 1
      },
      {
        label: 'Rules',
        link: './rules',
        index: 2
      },
      {
        label: 'Appliances',
        link: './appliances',
        index: 3
      },
      {
        label: 'Notes',
        link: './notes',
        index: 4
      },
      {
        label: 'Settings',
        link: './settings',
        index: 5
      },
    ];

    this.dataService.sharedZone$.subscribe(zone => {
      this.zone = zone;
    })

    if (localStorage.getItem('zoneList')) {
      this.zones = JSON.parse(localStorage.getItem('zoneList')) as Zone[];
    }

    this.route.params.subscribe(params => {
      this.zone = this.dataService.getZoneById(params.zoneId);
      this.zoneId = params.zoneId;
      if (this.zone === undefined) {
        this.fetchZone(params.zoneId);
      }

      if (this.zone && this.zones) {
        this.selectedZoneId = this.zones.find(x => x._id === this.zone._id)?._id;
      }
    });

    this.subscription = this.socketService.sensorValueMessages$.subscribe((data) => {
      this.updateSensorValue(data);
    });


  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }



  onZoneSelect(event: MatSelectChange) {

    let x = window.location.pathname;
    let currentTab = x.substring(x.lastIndexOf('/') + 1);
    this.router.navigate(['zone', event.value, currentTab]);
    let _zone = this.dataService.getZoneById(event.value);
    this.dataService.updateZone(_zone);
  }

  fetchZone(zoneId: string, loadSilent = false) {
    if (!loadSilent) {
      this.isLoading = true;
    }
    this.zoneService.getZone(zoneId).subscribe(result => {
      this.zone = result[0];
      this.zone.reducedSensors = Sensor.breakdownSensors(this.zone.sensors, this.zone.alerts);
      this.zone.alertSensors = Sensor.reduceSensorAlerts(this.zone.sensors, this.zone.alerts);
      this.zone.alertAppliances = Sensor.reduceApplianceAlerts(this.zone.sensors, this.zone.alerts, ['status'])
      this.zone.sensors.forEach(sensor => sensor.zone = this.zone._id);
      this.dataService.updateZone(this.zone);
      this.selectedZoneId = this.zones.find(x => x._id === this.zone._id)?._id;
      this.isLoading = false;
    })
  }

  updateSensorValue(data) {
    if (data === null) {
      return;
    }
    if (data.type === 'state') {
      return;
    }
    try {
      this.zone.reducedSensors.forEach(reducedSensor => {
        let value = reducedSensor.values.find(value => value.type === data.type);
        let sensor = value?.values.find(innerValue => innerValue.id === data.id);
        if (sensor === undefined) {
          return;
        }
        sensor.value = [data.value];
      });

      this.dataService.updateZone(this.zone);
    } catch (error) {
      console.error('error update value from socket');
    }
  }

}
