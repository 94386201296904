import { DatePipe } from "@angular/common";
import { Zone } from "./zone";
import { formatDate } from '@angular/common';


type ActionLogCategory = 'rules' | 'action' | 'security' | 'settings' | 'report';

export class ActionLog {
    category: ActionLogCategory;
    meta: any;
    zone: Zone;
    isError: boolean;
    user: any;
    createdAt: Date;
    subject: any;
    subjectType: string;
    eventType: string;

    constructor(data: any) {
        this.zone = data.zone;
        this.user = data.user;
        this.createdAt = data.createdAt;
        this.isError = data.isError;
        this.meta = data.meta;
        this.category = data.category;
        this.subject = data.subject;
        this.subjectType = data.subjectType;
        this.eventType = data.eventType;
    }
    get message(): string {

        try {
            switch (this.category) {
                case 'rules': {
                    return `
                        Rule "${this.meta.ruleName}" in Zone: "${this.zone.name}" Expected: ${this.meta.expected === 0 ? 'OFF' : 'ON'} Current: ${this.meta.current === 0 ? 'OFF' : 'ON'}
                    `
                }
                    break;
                case 'security': {
                    return `
                        User "${this.user.email}" logged in
                    `
                }
                    break;
                case 'settings': {
                    if (this.eventType === 'updated') {
                        return `
                            Set rule "${this.meta.ruleName}" to "${this.meta.to.active ? 'Active' : 'Not Active'}"
                        `
                    }

                    if (this.eventType === 'created') {
                        return `
                            Created new rule "${this.meta.ruleName}" in zone "${this.zone.name}"
                        `
                    }
                    if (this.eventType === 'deleted') {
                        return `
                            Deleted rule "${this.meta.ruleName}" in zone "${this.zone.name}"
                        `
                    }
                    if (this.subject.name === this.meta.to) {
                        return `
                            Renamed appliance "${this.subject.name}" from "${this.meta.from}" to "${this.meta.to}"
                        `
                    }
                    return `
                        Changed appliance "${this.subject.name}" mode from "${this.meta.from ? 'AUTO' : 'MANUAL'}" to "${this.meta.to ? 'AUTO' : 'MANUAL'}"
                    `
                }
                    break;
                case 'action': {
                    return `
                        Set appliance "${this.subject.name}" to ${this.meta.value === 0 ? 'OFF' : 'ON'}
                    `
                }
                    break;
                case 'report': {
                    return `
                        ${this.meta.reportType} report created for ${this.meta.sensorType.split(',').length > 1 ? 'types' : 'type'}: "${this.meta.sensorType}" for dates: ${formatDate(this.meta.from, 'dd.MM.yy', 'en')} - ${formatDate(this.meta.to, 'dd.MM.yy', 'en')} 
                    `
                }
                    break;
            }
        } catch (error) {
            return 'unknown'
        }
    }
}