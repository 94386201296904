<div class="container">
    <h2>{{data.note ? 'Edit' : 'Add'}} Note</h2>
    <mat-form-field>
        <mat-label>Note</mat-label>
        <input type="text" matInput placeholder="Write something..." [(ngModel)]="content">
        <mat-icon matSuffix>mode_edit</mat-icon>
    </mat-form-field>
    <ng-container *ngFor="let attachment of attachments">
        <div class="file mat-body-2">
            <span>{{attachment.label}} <span class="mat-body-1 file-size">({{attachment.size |
                    filesize
                    }})</span></span>
            <button mat-button mat-icon-button onclick="this.blur()" (click)="deleteAttachment(attachment)">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <mat-divider *ngIf="!attachment.loading"></mat-divider>
        <mat-progress-bar *ngIf="attachment.loading" mode="indeterminate"></mat-progress-bar>
    </ng-container>

    <mat-dialog-actions align="end">
        <button mat-button mat-icon-button onclick="this.blur()" (click)="fileInput.click()">
            <mat-icon>attachment</mat-icon>
        </button>
        <input #fileInput
               [hidden]="true"
               type="file"
               (change)="handleFileInput($event.target.files)">
        <button mat-button [mat-dialog-close]="undefined">Cancel</button>
        <button mat-flat-button color="primary" (click)="onConfirm()">Save</button>
    </mat-dialog-actions>
    <div class="loading-wrapper" *ngIf="isLoading">
        <mat-spinner diameter="30"></mat-spinner>
    </div>
</div>