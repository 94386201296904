import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

export interface IUser {
    email: string,
    password: string
};

export interface ILoginResponse {
    token: string,
    email: string;
};

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private _api: ApiService,
        private authService: AuthService,
        private _router: Router) { }

    login(user: IUser) {
        return this._api.post<ILoginResponse>('/user/login', user);
    }

    public logout() {
        localStorage.clear();
        this.authService.updateLoggedInUser(null);
        this._router.navigate(['/login']);
    }

}