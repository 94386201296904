<mat-card>
    <div class="title">
        {{'Appliances' | uppercase}}
    </div>
    <div class="appliance-row" *ngFor="let appliance of appliances"
         [ngClass]="{'disabled': !appliance.gwOnline}">
        <div>
            <div class="appliance-title">
                <span *ngIf="appliance.gwOnline"
                      class="indicator"
                      [style.background-color]="appliance.indicatorColor"></span>
                {{appliance.name}}
            </div>
            <div class="appliance-subtitle" *ngIf="appliance.watt || appliance.temperature || appliance.rssi">
                ({{appliance.temperature !== undefined ? appliance.temperature : 'NA-'}}C°
                {{appliance.watt !== undefined ? appliance.watt : 'NA-'}}W
                {{appliance.rssi !== undefined ? appliance.rssi : 'NA-'}}dB)
            </div>
            <div class="last-ping">
                Appliance id: {{appliance.sensorId}}
            </div>
            <div class="last-ping">
                Last ping: {{ appliance.lastPing === undefined ? 'Not supported' : appliance.lastPing | date: 'dd.MM.yy
                HH:mm'}}
            </div>
        </div>
        <div class="action-group">
            <span class="mode">{{appliance.opAuto ? 'AUTO' : 'MANUAL'}}</span>
            <span class="state">{{viewValue(appliance.type, appliance.value, appliance.sensorKind)}}</span>
        </div>
    </div>
</mat-card>