import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sensor } from 'src/app/models/sensor';
import { SensorService } from 'src/app/services/sensor.service';

@Component({
  selector: 'app-edit-sensor-name-dialog',
  templateUrl: './edit-sensor-name-dialog.component.html',
  styleUrls: ['./edit-sensor-name-dialog.component.less']
})
export class EditSensorNameDialogComponent implements OnInit {

  isLoading: boolean = false;
  sensor: Sensor;

  constructor(public dialogRef: MatDialogRef<EditSensorNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sensorService: SensorService) {
    this.sensor = data.sensor;
  }

  ngOnInit(): void {
  }

  onConfirm() {
    if (this.sensor.name.length === 0) {
      return;
    }
    this.isLoading = true;
    this.sensorService.editSensorName(this.sensor._id, this.sensor.name)
      .subscribe(result => {
        this.dialogRef.close(this.sensor);
      }, complete => {
        this.isLoading = false;
      });
  }

}
