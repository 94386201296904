import { Pipe, PipeTransform } from '@angular/core';
import { SensorFormats } from '../constants/SensorFormats'
import { SettingsService, Temperature, Length } from '../services/settings.service';


@Pipe({
  name: 'sensorFormat'
})
export class SensorFormatPipe implements PipeTransform {

  constructor(private settingsService: SettingsService) { }

  transform(value: string): string {
    switch (value) {
      case 'temperature':
        {
          let format = this.settingsService.getSetting('temperature') as Temperature;
          return SensorFormats.temperature[format];
        }
        break;
      case 'depth':
        {
          let format = this.settingsService.getSetting('length') as Length;
          return SensorFormats.length[format];
        }
        break;
      default:
        return SensorFormats[value];

    }
  }

}
