import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { AuthGuard } from '../../config/auth.guard';
import { MainFrameComponent } from '../main-frame/main-frame.component';
import { SettingsComponent } from '../settings/settings.component';
import { ReportsComponent } from '../reports/reports.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
