<div class="container" readOnly>

    <ng-container>
        <h2>Grow Cycle</h2>
        <ng-container *ngIf="cycle.id; else noCycle">
            <mat-card class="card">
                <div class="row">
                    <div class="title">Cycle Name</div>
                    <div class="value">{{cycle.name | replace : '_' : ' '}}</div>
                </div>
                <div class="row">
                    <div class="title">Strain Name</div>
                    <div class="value">{{cycle.strainName}}</div>
                </div>
                <div class="row no-border">
                    <div class="title">Current State</div>
                    <div class="actions-group">
                        <mat-select class="dropdown" [(ngModel)]="cycle.currentState.state">
                            <mat-option *ngFor="let state of zoneStates" [value]="state"
                                        [disabled]="state === 'Start'">
                                {{state}}
                            </mat-option>
                        </mat-select>
                        <mat-form-field class="input-field custom-state" *ngIf="cycle.currentState.state === 'Custom'">
                            <input placeholder="Custom state" type="text" name="name" matInput
                                   [(ngModel)]="customState">
                        </mat-form-field>
                    </div>

                </div>
                <div class="row">
                    <button class="align-right" mat-flat-button color="primary" [disabled]="isLoading"
                            (click)="updateState()">Apply
                        Changes</button>
                </div>
                <div class="loading-wrapper" *ngIf="isLoading">
                    <mat-spinner diameter="30"></mat-spinner>
                </div>
            </mat-card>
            <div class="row">
                <button mat-flat-button color="primary" class="align-right" [disabled]="isLoading"
                        (click)="endCycle()">End Cycle</button>
            </div>
        </ng-container>
        <ng-template #noCycle>
            <mat-card>
                <div class="row">
                    <div class="title">Strain Name</div>
                    <mat-form-field class="input-field">
                        <input placeholder="Strain Name" type="text" name="name" matInput
                               [(ngModel)]="cycle.strainName">
                    </mat-form-field>
                </div>
            </mat-card>
            <div class="row no-border">
                <button mat-flat-button color="primary" class="align-right" [disabled]="isLoading"
                        (click)="startCycle()">Start Cycle</button>
            </div>
        </ng-template>

    </ng-container>
    <ng-container>
        <h2>Day Part</h2>
        <mat-card>
            <div class="row">
                <div class="title">Determined by:</div>
                <mat-form-field>
                    <mat-select [(ngModel)]="dayPartSetting">
                        <mat-option [value]="'custom'">Custom</mat-option>
                        <mat-option [value]="'sensor'">Sensor</mat-option>
                        <mat-option [value]="'none'">None</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <ng-container *ngIf="dayPartSetting === 'custom'">
                <div class="row no-border">
                    <div class="actions-group align-right">
                        <div class="title">Day</div>
                        <mat-form-field class="input-field">
                            <mat-label>From</mat-label>
                            <input placeholder="From" type="time"
                                   matInput [(ngModel)]="daypart.day.from">
                        </mat-form-field>
                        <mat-form-field class="input-field">
                            <mat-label>To</mat-label>
                            <input placeholder="To" type="time"
                                   matInput [(ngModel)]="daypart.day.to">
                        </mat-form-field>
                    </div>
                </div>
                <!-- <div class="row no-border">
                    <div class="actions-group align-right">
                        <div class="title">Night</div>
                        <mat-form-field class="input-field">
                            <mat-label>From</mat-label>
                            <input placeholder="From" type="time"
                                   matInput>
                        </mat-form-field>
                        <mat-form-field class="input-field">
                            <mat-label>To</mat-label>
                            <input placeholder="To" type="time"
                                   matInput>
                        </mat-form-field>
                    </div>
                </div> -->
            </ng-container>
        </mat-card>
        <div class="row">
            <button mat-flat-button color="primary" class="align-right"
                    (click)="saveDayPart()">Save</button>
        </div>
    </ng-container>
    <ng-container>
        <div class="row">
            <h2>Sensor Pairing</h2>
            <button mat-flat-button color="primary"
                    (click)="openPairingDialog()">Start Pairing</button>
        </div>


    </ng-container>
</div>