import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Sensor, SensorType, IValue, Fork, ReducedSensor, ISensorValue } from 'src/app/models/sensor';
import { DataService } from 'src/app/services/data.service';
import { DecimalPipe } from '@angular/common';
import { SettingsService, ECUnit } from 'src/app/services/settings.service';
import { findIndex } from 'rxjs/operators';
import { SocketIoService } from 'src/app/services/socket-io.service';
import { Subscription } from 'rxjs';

const VALID_SENSE_TIME_MS = 20 * 60 * 1000;

@Component({
  selector: 'app-sensor-overview',
  templateUrl: './sensor-overview.component.html',
  styleUrls: ['./sensor-overview.component.less']
})
export class SensorOverviewComponent implements OnInit, OnDestroy {

  @Input() sensor: Sensor;
  @Output() onSensorOpen = new EventEmitter();
  private subscription: Subscription;


  get lastUpdated(): Date {

    if (this.sensor) {
      let datesArray = this.sensor?.lastValues?.map(x => x?.value?.date !== undefined && x?.value?.date)
      let latestDate = new Date(Math.max(...datesArray.map(e => new Date(e).getTime())));

      return latestDate;

    }
    return undefined;
  }

  get isSensorActive(): boolean {
    if (this.sensor) {
      let lastSense = this.sensor?.lastValues?.length > 0 && this.sensor?.lastValues?.[0].value?.date;
      let _isActive = new Date().getTime() - new Date(lastSense).getTime() <= VALID_SENSE_TIME_MS;

      return _isActive;
    }
    return false;
  }

  constructor(private router: Router, private dataService:
    DataService, private decimalPipe: DecimalPipe,
    private settingsService: SettingsService,
    private socketService: SocketIoService) {
    this.subscription = this.socketService.sensorValueMessages$.subscribe((data) => {

      if (data === null) { return; }
      if (data.type === 'state') { return; }
      if (this.sensor?._id === data.id) {
        let lv = this.sensor.lastValues?.find(lv => lv.value.type === data.type);
        if (lv === undefined) {
          let sensorValue: ISensorValue = {
            date: new Date(),
            value: <IValue>{ value: [data.value], type: data.type },
          }
          if (this.sensor.lastValues === undefined) {
            this.sensor.lastValues = [sensorValue];
          } else {
            this.sensor.lastValues.push(sensorValue);
          }
          this.initData();

          return;
        }
        lv.value.date = new Date();
        lv.value.value = [data.value];
        this.initData();
      }

    });
  }

  ngOnInit(): void {
    this.initData();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initData() {
    try {
      this.sensor = Sensor.calculateVPD(this.sensor);

      var ordering = {};
      var sortOrder = ['temperature', 'humidity', 'vpd', 'co2', 'hpa', 'lux', 'rssi', 'battery'];
      sortOrder.map((value, index) => ordering[value] = index);
      this.sensor?.lastValues?.sort((a, b) => {
        return (ordering[a.value?.type] - ordering[b.value?.type]);
      })

      this.sensor.lastValues?.forEach(lv => {
        let reducedValues = lv.value.value.reduce((acc, item) => {
          let transformed = this.decimalPipe.transform(item, '0.0-2');
          acc.push(transformed)
          return acc;
        }, [])
        lv.displayedValue = reducedValues.join(', ')
      })

      this.rootSensorSetup();
      this.waterSensorSetup();
    } catch (e) {
      console.error('failed init data of sensor overview', e);
    }
  }

  rootSensorSetup() {
    let rootSensor = this.sensor?.kind === 'root' ? this.sensor : undefined;
    if (rootSensor === undefined) {
      return;
    }

    this.sensor.forks = Sensor.forks(rootSensor);
  }

  waterSensorSetup() {
    if (this.sensor.kind != 'water') {
      return;
    }
    let availableSensors: SensorType[] = ['temperature', 'con', 'tds', 'ph', 'flow', 'pressure'];
    this.sensor.lastValues = this.sensor.lastValues?.filter(x => availableSensors.includes(x.value.type));

    let ordering = {};
    availableSensors.map((value, index) => ordering[value] = index);
    this.sensor.lastValues?.sort((a, b) => {
      return (ordering[a.value.type] - ordering[b.value.type]);
    })

    let ecIndex = this.sensor.lastValues?.findIndex(x => x.value.type === 'con');
    let tdsIndex = this.sensor.lastValues?.findIndex(x => x.value.type === 'tds');
    let setting: ECUnit = this.settingsService.getSetting('ecUnit');
    let toRemoveIndex = setting === 'EC' ? tdsIndex : ecIndex
    if (toRemoveIndex === -1) {
      return;
    }
    this.sensor.lastValues?.splice(toRemoveIndex, 1);
  }

  openSensorAlertSettings() {
    this.dataService.updateSensor(null);
    this.router.navigate(['sensor', this.sensor._id, 'notifications']);

  }

  openSensor() {
    this.onSensorOpen.emit();
    this.dataService.updateSensor(null);
    this.router.navigate(['sensor', this.sensor._id, 'reports']);
  }

}
