import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../../zone/alert-dialog/alert-dialog.component';
import { Router } from '@angular/router';
import { Sensor, SensorTypes } from 'src/app/models/sensor';
import { SensorWithLoadingState } from '../../zone/alert-settings/alert-settings.component';
import { SensorService } from 'src/app/services/sensor.service';
import { Alert, Zone } from 'src/app/models/zone';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-sensor-alert-setting',
  templateUrl: './sensor-alert-setting.component.html',
  styleUrls: ['./sensor-alert-setting.component.less']
})
export class SensorAlertSettingComponent implements OnInit {

  sensors: SensorWithLoadingState[];
  sensor: Sensor;
  zone: Zone;

  get dayNightAvailable() {
    return this.zone?.dayPartSettings !== undefined;
  }

  constructor(public dialog: MatDialog,
    private router: Router,
    private sensorService: SensorService,
    private dataService: DataService) {
    this.dataService.sharedSensor$.subscribe(sensor => {
      if (sensor === undefined) {
        return;
      }
      this.sensor = sensor;
      this.sensors = SensorTypes[this.sensor.kind].map(x => {
        return {
          type: x
        }
      })
      this.sensor.alerts?.forEach(alert => {
        let alertSensor = this.sensors.find(x => x.type === alert.sensorType);
        if (alertSensor) {
          alertSensor.alert = alert;
        }
      })
      this.sensors.forEach(x => {
        if (x.alert === undefined) {
          x.alert = { active: false, sensorType: x.type }
        }
      })
    })
    this.dataService.sharedZone$.subscribe(zone => {
      this.zone = zone;
    })

  }

  ngOnInit(): void {
  }

  getAlerts() {
    this.sensorService.alerts(this.sensor._id).subscribe(result => {
    })
  }

  openAlertDialog(sensor: SensorWithLoadingState) {
    let alert: Alert = {
      sensorType: sensor.type,
      day: sensor.alert?.day || { min: 0, max: 0 },
      night: sensor.alert?.night || { min: 0, max: 0 },
      _id: sensor.alert?._id || undefined,
      active: sensor.alert?.active || false
    }
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: { alert: alert, id: this.sensor._id, dayNightAvailable: this.dayNightAvailable },
    });

    dialogRef.afterClosed().subscribe(alert => {
      if (alert === undefined) return;
      sensor.alert = alert;
    });
  }

  onNotificationChange(sensor: SensorWithLoadingState) {
    sensor.isLoading = true;
    // if (sensor.alert._id) {
    //   this.sensorService.updateAlert(this.sensor._id, sensor.alert).subscribe(result => {
    //     sensor.isLoading = false;
    //   })
    // } else {
    //   this.sensorService.setAlert(this.sensor._id, sensor.alert).subscribe(result => {
    //     sensor.alert._id = result.created;
    //     sensor.isLoading = false;
    //   })
    // }
    this.sensorService.setAlert(this.sensor._id, sensor.alert).subscribe(result => {
      sensor.alert._id = result.created;
      sensor.isLoading = false;
    })

  }

}
