import { Component, OnInit, Inject } from '@angular/core';
import { Zone } from 'src/app/models/zone';
import { ApplianceTypes, Sensor, SensorTypes } from 'src/app/models/sensor';
import { Rule, Condition, ApplianceAction, Appliance, ApplianceActionInterval, ApplianceTimeRange } from 'src/app/models/appliance';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ZoneService } from 'src/app/services/zone.service';
import { DataService } from 'src/app/services/data.service';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-rule-dialog',
  templateUrl: './rule-dialog.component.html',
  styleUrls: ['./rule-dialog.component.less']
})

export class RuleDialogComponent implements OnInit {
  rule: Rule = new Rule();
  zone: Zone;
  conditionTypeOptions: any = [
    { title: 'Parameter Type', value: 'parameter' },
    { title: 'Time Range', value: 'time-range' },
    { title: 'Specific Time', value: 'time-single' }
  ]
  sensorTypes: string[];
  availableActions: ApplianceAction[];
  sensors: Sensor[];
  appliances: Appliance[];
  isLoading: boolean = false;


  ruleForm = new FormGroup({
    name: new FormControl(this.rule.name, [Validators.required]),
    interval: new FormControl(1, [Validators.min(1)])
  })

  get dayNightAvailable() {
    return this.zone?.dayPartSettings !== undefined;
  }

  constructor(public dialogRef: MatDialogRef<RuleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private zoneService: ZoneService, private dataService: DataService) {

    this.dataService.sharedZone$.pipe(take(1)).subscribe(zone => {
      this.zone = zone;
      this.sensors = this.zone.sensors;
      this.appliances = Appliance.reduceAppliances(this.zone.sensors, this.zone.online);
      this.sensors = this.sensors.filter(x => !ApplianceTypes.includes(x.kind));

      if (data.rule != undefined) {
        this.rule = data.rule;

        this.rule.conditions.forEach(condition => {
          let timeRange = new ApplianceTimeRange();

          if (condition.times?.from) {
            timeRange.from = condition.times.from
            var fromDate = new Date(timeRange.from)
            timeRange.fromView = `${fromDate.getHours()}`.padStart(2, '0') + ':' + `${fromDate.getMinutes()}`.padStart(2, '0')
          }
          if (condition.times?.to) {
            timeRange.to = condition.times.to;
            var toDate = new Date(timeRange.to)
            timeRange.toView = `${toDate.getHours()}`.padStart(2, '0') + ':' + `${toDate.getMinutes()}`.padStart(2, '0')
          }

          if (condition.times?.at) {
            timeRange.at = condition.times.at;
            var atDate = new Date(timeRange.at)
            timeRange.atView = `${atDate.getHours()}`.padStart(2, '0') + ':' + `${atDate.getMinutes()}`.padStart(2, '0')
          }

          condition.times = timeRange;

          if (condition.min || condition.max) {
            condition.type = 'parameter';
          } else {
            condition.type = condition.times?.at ? 'time-single' : 'time-range';
          }
          if (condition.sensor) {
            let selectedSensor = this.sensors.filter(x => x._id === condition.sensor);
            if (selectedSensor.length > 0) {
              condition.sensorTypes = SensorTypes[selectedSensor[0].kind];
            }
          }
        })
        this.rule.actions.forEach(action => {

          if (action.sensor) {
            console.log('he');

            let selectedAppliance = this.appliances.filter(x => x.sensorId === action.sensor);
            action.appliance = selectedAppliance[0] || undefined;

          }
        })
      }
    })

  }

  ngOnInit(): void {
  }

  addCondition() {
    this.rule.conditions.push(new Condition());
  }

  addAction() {
    this.rule.actions.push(new ApplianceAction());
  }

  onConditionTypeChange(condition: Condition) {
    condition.max = condition.min = undefined;
    condition.times = new ApplianceTimeRange();
    condition.sensor = undefined;
    condition.sensorType = undefined;
  }

  onIntervalToggle(event: MatSlideToggleChange, action: ApplianceAction) {
    if (event.checked) {
      action.interval = new ApplianceActionInterval();
    } else {
      action.interval = undefined;
    }
  }

  onUnlimitedToggle(event: MatSlideToggleChange, action: ApplianceAction) {

    action.interval.iterations = event.checked ? 0 : 1;
  }

  onTimeSet(condition: Condition, key: string) {
    let time = condition.times[key + 'View'].split(":")
    let dateObj = new Date();
    dateObj.setHours(time[0], time[1], 0, 0)
    condition.times[key] = dateObj;
  }

  onSelectedSensor(condition: Condition) {
    let selectedSensor = this.sensors.filter(x => x._id === condition.sensor);
    if (selectedSensor.length > 0) {
      // condition.sensorTypes = selectedSensor[0].types
      condition.sensorTypes = SensorTypes[selectedSensor[0].kind];
    }
  }

  onConfirm() {
    if (!this.ruleForm.valid) {
      return;
    }

    this.isLoading = true;
    let mRule = {
      active: this.rule.active,
      _id: this.rule._id,
      name: this.rule.name,
      dayPart: this.rule.dayPart,
      conditions: this.rule.conditions.map(x => {
        var obj = {};
        if (x.min !== undefined) obj['min'] = x.min;
        if (x.max !== undefined) obj['max'] = x.max;
        if (x.sensor !== undefined) obj['sensor'] = x.sensor;
        if (x.sensorType !== undefined) obj['sensorType'] = x.sensorType;
        if (Object.keys(x.times).length > 0) {
          obj['times'] = {
            from: x.times?.from,
            to: x.times?.to,
            at: x.times?.at
          }
        }
        // return {
        //   min: x.min,
        //   max: x.max,
        //   sensor: x.sensor,
        //   sensorType: x.sensorType,
        //   times: {
        //     from: x.times?.from,
        //     to: x.times?.to,
        //     at: x.times?.at
        //   }
        // }
        console.log('conditions obj', obj);

        return obj;
      }),
      actions: this.rule.actions.map(x => {
        return {
          sensor: x.appliance?.sensorId,
          key: x.appliance?.key,
          value: x.value,
          interval: x.interval
        }
      })
    }
    if (this.rule._id) {
      this.zoneService.updateRule(this.zone._id, mRule).subscribe(result => {
        this.isLoading = false;
        if (result.updated === undefined) return;
        this.dialogRef.close(mRule);
      }, complete => {
        this.isLoading = false;
      })
    } else {
      mRule['active'] = true;
      this.zoneService.setRule(this.zone._id, mRule).subscribe(result => {
        this.isLoading = false;
        if (result.created === undefined) return;
        mRule._id = result.created;
        mRule.active = true;
        this.dialogRef.close(mRule);
        // this.rule._id = result.created;
        // this.rule.active = true;
        // this.dialogRef.close(this.rule);
      }, complete => {
        this.isLoading = false;
      })
    }

  }

  removeCondition(index: number) {
    this.rule.conditions.splice(index, 1);
  }

  removeAction(index: number) {
    this.rule.actions.splice(index, 1);
  }

}


