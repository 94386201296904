<div class="zone-overview" *ngIf="zone.reducedSensors.length">
    <app-zone-overview [zone]="zone" (onSensorOpen)="updateCurrentZone(zone)"></app-zone-overview>
</div>
<ng-container *ngFor="let category of categories">
    <ng-container *ngIf="(zone.sensors | filter: {kind : category.value}).length > 0">
        <div class="category-title">{{category.viewValue | uppercase}}</div>
        <div class="sensor-container">
            <app-sensor-overview class="sensor"
                                 *ngFor="let sensor of zone.sensors | filter: {kind : category.value}"
                                 [sensor]="sensor"
                                 (onSensorOpen)="updateCurrentZone(zone)"></app-sensor-overview>


        </div>
    </ng-container>
</ng-container>

<div class="appliances-container" *ngIf="appliances.length">
    <app-appliances-overview [appliances]="appliances"></app-appliances-overview>
</div>