import { ApplianceToggleTypes, ApplianceTypes, Sensor, SensorKind } from './sensor';


export enum ControlModeEnum {
    off = 0,
    auto = 1,
    manual = 2
}

export class Appliance {
    name: string;
    type: ApplianceType;
    value: number;
    opAuto: boolean;
    key: number;
    steps: ApplianceSteps;
    actions: ApplianceAction[];
    sensorId?: string;
    lastPing?: Date;
    status?: boolean;
    sensorKind?: SensorKind;
    temperature?: number;
    watt?: number;
    rssi?: number;
    gwOnline?: boolean;
    controlMode: ControlModeEnum;

    get valueAsBoolean(): boolean {
        return this.value === 1;
    }

    set valueAsBoolean(value: boolean) {
        this.value = value ? 1 : 0;
    }

    public get indicatorColor(): string {

        if (this.status) {
            return this.status ? 'limegreen' : 'tomato';
        }
        return 'gray'
    }

    public static reduceAppliances(sensors: Sensor[], gwOnline?: boolean) {
        let temp: Appliance[] = sensors.filter(x => ApplianceTypes.includes(x.kind)).reduce((acc, item) => {
            let appliance = new Appliance();
            appliance.name = item.name;
            appliance.sensorId = item._id;
            appliance.opAuto = item.action?.opAuto;
            appliance.steps = item.action?.steps;
            appliance.type = item.action?.type
            appliance.sensorKind = item.kind;

            let reducedLastValues = item.lastValues?.reduce((acc, item) => {
                acc.push(item?.value);
                return acc;
            }, []);

            let status = reducedLastValues?.find(x => x?.type === 'status');
            if (status !== undefined) {
                appliance.status = status.value[0] === 1;
            }
            let state = reducedLastValues?.find(x => x?.type === 'state');
            if (state !== undefined) {
                appliance.value = state.value?.[0];
                appliance.lastPing = state?.date;
            }

            let controlMode = reducedLastValues?.find(x => x?.type === 'control-mode');
            if (controlMode !== undefined && appliance.sensorKind === 'plc-relay') {
                appliance.controlMode = controlMode.value[0];
            }

            let temperature = reducedLastValues?.find(x => x?.type === 'relay-temperature');
            if (temperature !== undefined) {
                appliance.temperature = temperature.value?.[0];
            }

            let watt = reducedLastValues?.find(x => x?.type === 'watt');
            if (watt !== undefined) {
                appliance.watt = watt.value?.[0];
            }

            let rssi = reducedLastValues?.find(x => x?.type === 'rssi');
            if (rssi !== undefined) {
                appliance.rssi = rssi.value?.[0];
            }

            appliance.gwOnline = gwOnline;

            acc = [...acc, appliance];
            return acc;
        }, []);
        temp.forEach(appliance => {
            appliance.actions = Appliance.initActions(appliance);
        })

        return temp;
    }

    public static initActions(appliance: Appliance) {
        let actions: ApplianceAction[] = [];
        switch (appliance.type) {
            case 'toggle':
                {
                    actions = [
                        new ApplianceAction(0, 'OFF'),
                        new ApplianceAction(1, 'ON')]

                }
                break;
            case 'fan':
                {
                    actions.push({ value: 0, viewValue: 'OFF' })
                    for (var i = 1; i <= appliance.steps.to; i = i + appliance.steps.skip) {
                        actions.push(new ApplianceAction(i, `${i}`))
                    }
                }
                break;
        }
        return actions;
    }

    public static viewValue(type: ApplianceType, value: number, kind?: SensorKind) {
        if (ApplianceToggleTypes.includes(kind)) {
            return value === 1 ? 'ON' : 'OFF'
        }
        return value || 0;
    }

}

type ApplianceType = 'toggle' | 'fan' | 'shelly-dimmer';
type DayPart = 'day' | 'night';
export type ConditionType = 'parameter' | 'time-range' | 'time-single';

export interface ApplianceSteps {
    from?: number;
    to?: number;
    skip?: number;
}

export class ApplianceActionInterval {
    on: number;
    break: number;
    iterations: number = 0;
}

export class ApplianceAction {
    value?: number;
    viewValue?: string;
    interval?: ApplianceActionInterval;
    appliance?: Appliance;
    sensor?: string; // sensor id
    key?: number;
    actions?: ApplianceAction[] = [];


    constructor(value?: number, viewValue?: string) {
        this.value = value;
        this.viewValue = viewValue;
        //this.interval = new ApplianceActionInterval();
    }
}

export class ApplianceTimeRange {
    fromView?: string;
    from?: number;
    toView?: string;
    to?: number;
    atView?: string;
    at?: number;
}



export class Rule {
    _id?: string;
    name: string;
    dayPart: DayPart = 'day';
    conditions?: Condition[] = [new Condition()];
    actions?: ApplianceAction[] = [new ApplianceAction()];
    active?: boolean;
}

export class Condition {
    sensorType: string;
    times?: ApplianceTimeRange = new ApplianceTimeRange();
    min?: number;
    max?: number;
    type: ConditionType;
    sensor?: string // sensor id
    sensorTypes?: string[];
}




