import { Component, OnInit, Input } from '@angular/core';
import { Appliance } from 'src/app/models/appliance';

@Component({
  selector: 'app-appliances-overview',
  templateUrl: './appliances-overview.component.html',
  styleUrls: ['./appliances-overview.component.less']
})
export class AppliancesOverviewComponent implements OnInit {
  @Input() appliances: Appliance[] = [];

  viewValue = Appliance.viewValue;
  constructor() { }

  ngOnInit(): void {
  }

}
