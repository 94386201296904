<mat-card class="card">
    <div class="row" *ngFor="let sensor of sensors">
        <div class="sensor-title">{{sensor.type | sensorDisplayName}}</div>
        <div class="actions-group">
            <div class="value-group">
                <div class="value-row day" *ngIf="sensor.type != 'power'">
                    <img *ngIf="dayNightAvailable" src="../assets/images/day.svg" class="icon">
                    <span class="value">
                        {{sensor.alert.day && sensor.alert.day.min | sensorValue:sensor.type}}-{{sensor.alert.day &&
                        sensor.alert.day.max | sensorValue:sensor.type}}
                    </span>
                    <span class="format">{{sensor.type | sensorFormat}}</span>
                </div>
                <ng-container *ngIf="dayNightAvailable">
                    <div class="value-row night" *ngIf="sensor.type != 'power'">
                        <img src="../assets/images/night.svg" class="icon">
                        <span class="value">{{sensor.alert.night && sensor.alert.night.min |
                            sensorValue:sensor.type}}-{{sensor.alert.night && sensor.alert.night.max |
                            sensorValue:sensor.type }}
                        </span>
                        <span class="format">{{sensor.type | sensorFormat}}</span>
                    </div>
                </ng-container>
            </div>
            <mat-slide-toggle readOnly class="toggle" [color]="'primary'" [(ngModel)]="sensor.alert.active"
                (change)="onNotificationChange(sensor)"
                [disabled]="sensor.type != 'status' && sensor.type != 'power' && sensor.alert._id === undefined">
            </mat-slide-toggle>
            <button readOnly [hide]="true" mat-button mat-icon-button onclick="this.blur()"
                (click)="openAlertDialog(sensor)" *ngIf="!['power', 'status'].includes(sensor.type)">
                <mat-icon>edit</mat-icon>
            </button>
        </div>
        <div class="loading-wrapper" *ngIf="sensor.isLoading">
            <mat-spinner diameter="30"></mat-spinner>
        </div>
    </div>

</mat-card>