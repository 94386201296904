import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: unknown[], args: unknown): unknown[] {
    return value.filter(x => x[Object.keys(args)[0]] === Object.values(args)[0]);
  }

}
