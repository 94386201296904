import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsComponent } from './reports.component';
import { ReportsRoutingModule } from './reports-routing.module';
import { SharedModule } from 'src/app/modules/shared.module';
import { LogComponent } from '../log/log.component';




@NgModule({
    declarations: [ReportsComponent, LogComponent],
    imports: [
        CommonModule, ReportsRoutingModule, SharedModule
    ]
})
export class ReportsModule { }
