<div class="page-container">
    <h1>Backoffice</h1>
    <div class="box">
        <div class="title">Gateway actions</div>
        <div class="message" *ngIf="messages['gateway-actions']">{{ messages['gateway-actions'] }}</div>
        <div class="form">
            <div class="field">
                <select [(ngModel)]="gatewayActions['gateway']">
                    <option value="" *ngIf="!gatewayActionsSearch.value">----</option>
                    <option *ngFor="let i of items['gateway'] | bofilter: { k: 'name', v: gatewayActionsSearch.value }" [ngValue]="i._id">{{ i.name }} v{{ i.version }} ({{
                        i.hardwareId }})</option>
                </select>
                Search: <input ngModel #gatewayActionsSearch>
            </div>
            <div class="field">
                <label>Version</label>
                <input type="text" [(ngModel)]="gatewayActions['version']">
            </div>
            <div class="field">
                <select [(ngModel)]="gatewayActions['branch']">
                    <option value="dev" selected="selected">dev</option>
                    <option value="master">master</option>
                </select>
            </div>
            <div class="field">
                <button (click)="createTunnel()" [disabled]="!gatewayActions['gateway']">Create tunnel</button>
                <button (click)="updateGateway()" [disabled]="!gatewayActions['gateway'] || !gatewayActions['version']">Update</button>
                <button (click)="reloadGateway()" [disabled]="!gatewayActions['gateway']">Reload</button>
                <button (click)="closeAllTunnels()">Close all tunnels</button>
            </div>
        </div>
    </div>
    <div class="box">
        <div class="title">Company</div>
        <div class="message" *ngIf="messages['company']">{{ messages['company'] }}</div>
        <div class="form">
            <div class="field">
                <input type="checkbox" [(ngModel)]="update['company']" (change)="create['company'] = {}; companySearch.value = null">Update
            </div>
            <div class="field">
                <select [(ngModel)]="create['company']" [disabled]="!update['company']">
                    <option value="">----</option>
                    <option *ngFor="let i of items['company'] | bofilter: { k: 'name', v: companySearch.value }" [ngValue]="i">{{ i.name }}</option>
                </select>
                Search: <input ngModel #companySearch [disabled]="!update['company']" (ngModelChange)="create['company']={}">
            </div>
            <div class="field">
                <label>Name</label>
                <input type="text" [(ngModel)]="create['company'].name">
            </div>
            <div class="field">
                <button (click)="createOrUpdate('company')">{{ update['company'] ? 'Update' : 'Create' }}</button>
                <div *ngIf="update['company']">
                    <button class="delete" (click)="delete('company')">Delete</button>
                    <input type="checkbox" [(ngModel)]="create['company'].deleteRelatedGateways">Delete related gateways
                    <input type="checkbox" [(ngModel)]="create['company'].deleteRelatedZones">Delete related zones
                    <input type="checkbox" [(ngModel)]="create['company'].deleteRelatedSensors">Delete related sensors
                </div>
            </div>
        </div>
    </div>
    <div class="box">
        <div class="title">User</div>
        <div class="message" *ngIf="messages['user']">{{ messages['user'] }}</div>
        <div class="form">
            <div class="field">
                <input type="checkbox" [(ngModel)]="update['user']" (change)="create['user'] = {}; userSearch.value = null; userSearchCompany.value = null">Update
            </div>
            <div class="field">
                <select [(ngModel)]="create['user']" [disabled]="!update['user']">
                    <option value="">----</option>
                    <option *ngFor="let i of items['user'] | bofilter: { k: 'name', v: userSearch.value }" [ngValue]="i">{{ i.name }} ({{ i.email }})</option>
                </select>
                Search: <input ngModel #userSearch [disabled]="!update['user']" (ngModelChange)="create['user']={}">
            </div>
            <div class="field">
                <label>Name</label>
                <input type="text" [(ngModel)]="create['user'].name">
            </div>
            <div class="field">
                <label>Email</label>
                <input type="text" [(ngModel)]="create['user'].email">
            </div>
            <div class="field">
                <input type="checkbox" [(ngModel)]="create['user'].changePassword" [disabled]="!update['user']">Change
                password
            </div>
            <div class="field">
                <label>Password</label>
                <input type="text" [(ngModel)]="create['user'].password"
                    [disabled]="update['user'] && !create['user'].changePassword">
            </div>
            <div class="field">
                <label>Phone</label>
                <input type="text" [(ngModel)]="create['user'].phone">
            </div>
            <div class="field">
                <label>Role</label>
                <select [(ngModel)]="create['user'].role">
                    <option value="r">Read Only</option>
                    <option value="rw">Read / Write</option>
                    <option value="sa">Super Admin</option>
                </select>
            </div>
            <div class="field">
                <label>Company</label>
                <select [(ngModel)]="create['user'].company">
                    <option *ngFor="let i of items['company'] | bofilter: { k: 'name', v: userSearchCompany.value }" [ngValue]="i._id">{{ i.name }}</option>
                </select>
                Search: <input ngModel #userSearchCompany (ngModelChange)="create['user'].company=null">
            </div>
            <div class="field">
                <button (click)="createOrUpdate('user')">{{ update['user'] ? 'Update' : 'Create' }}</button>
                <button class="delete" (click)="delete('user')" *ngIf="update['user']">Delete</button>
            </div>
        </div>
    </div>
    <div class="box">
        <div class="title">Gateway</div>
        <div class="message" *ngIf="messages['gateway']">{{ messages['gateway'] }}</div>
        <div class="form">
            <div class="field">
                <input type="checkbox" [(ngModel)]="update['gateway']" (change)="create['gateway'] = {}; gatewaySearch.value = null; gatewaySearchCompany.value = null">Update
            </div>
            <div class="field">
                <select [(ngModel)]="create['gateway']" [disabled]="!update['gateway']">
                    <option value="">----</option>
                    <option *ngFor="let i of items['gateway'] | bofilter: { k: 'name', v: gatewaySearch.value }" [ngValue]="i">{{ i.name }} ({{ i.hardwareId }})</option>
                </select>
                Search: <input ngModel #gatewaySearch [disabled]="!update['gateway']" (ngModelChange)="create['gateway']={}">
            </div>
            <div class="field">
                <label>Name</label>
                <input type="text" [(ngModel)]="create['gateway'].name">
            </div>
            <div class="field">
                <label>Hardware ID</label>
                <input type="text" [(ngModel)]="create['gateway'].hardwareId">
            </div>
            <div class="field">
                <label>Company</label>
                <select [(ngModel)]="create['gateway'].company">
                    <option *ngFor="let i of items['company'] | bofilter: { k: 'name', v: gatewaySearchCompany.value }" [ngValue]="i._id">{{ i.name }}</option>
                </select>
                Search: <input ngModel #gatewaySearchCompany (ngModelChange)="create['gateway'].company=null">
            </div>
            <div class="field">
                <button (click)="createOrUpdate('gateway')">{{ update['gateway'] ? 'Update' : 'Create' }}</button>
                <div *ngIf="update['gateway']">
                    <button class="delete" (click)="delete('gateway')">Delete</button>
                    <input type="checkbox" [(ngModel)]="create['gateway'].deleteRelatedZones">Delete related zones
                    <input type="checkbox" [(ngModel)]="create['gateway'].deleteRelatedSensors">Delete related sensors
                </div>
            </div>
        </div>
    </div>
    <div class="box">
        <div class="title">Zone</div>
        <div class="message" *ngIf="messages['zone']">{{ messages['zone'] }}</div>
        <div class="form">
            <div class="field">
                <input type="checkbox" [(ngModel)]="update['zone']" (change)="create['zone'] = {}; zoneSearch.value = null; zoneSearchGateway.value = null">Update
            </div>
            <div class="field">
                <select [(ngModel)]="create['zone']" [disabled]="!update['zone']">
                    <option value="">----</option>
                    <option *ngFor="let i of items['zone'] | bofilter: { k: 'name', v: zoneSearch.value }" [ngValue]="i">{{ i.name }}</option>
                </select>
                Search: <input ngModel #zoneSearch [disabled]="!update['zone']" (ngModelChange)="create['zone']={}">
            </div>
            <div class="field">
                <label>Name</label>
                <input type="text" [(ngModel)]="create['zone'].name">
            </div>
            <div class="field">
                <label>Gateway</label>
                <select [(ngModel)]="create['zone'].gateway">
                    <option *ngFor="let i of items['gateway'] | bofilter: { k: 'name', v: zoneSearchGateway.value }" [ngValue]="i._id">{{ i.name }} ({{ i.hardwareId }})
                    </option>
                </select>
                Search: <input ngModel #zoneSearchGateway (ngModelChange)="create['zone'].gateway=null">
            </div>
            <div class="field">
                <button (click)="createOrUpdate('zone')">{{ update['zone'] ? 'Update' : 'Create' }}</button>
                <div *ngIf="update['zone']">
                    <button class="delete" (click)="delete('zone')">Delete</button>
                    <input type="checkbox" [(ngModel)]="create['zone'].deleteRelatedSensors">Delete related sensors
                </div>
            </div>
        </div>
    </div>
    <div class="box">
        <div class="title">Sensor</div>
        <div class="message" *ngIf="messages['sensor']">{{ messages['sensor'] }}</div>
        <div class="form">
            <div class="field">
                <input type="checkbox" [(ngModel)]="update['sensor']" (change)="create['sensor'] = {}; sensorSearch.value = null; sensorSearchZone.value = null">Update
            </div>
            <div class="field">
                <select [(ngModel)]="create['sensor']" [disabled]="!update['sensor']" (ngModelChange)="itemChanged($event)">
                    <option value="">----</option>
                    <option *ngFor="let i of items['sensor'] | bofilter : { k: 'name', v: sensorSearch.value }" [ngValue]="i">
                        {{ i.name }} ({{ expand(i.zone, 'zone', 'name') }})
                    </option>
                </select>
                Search: <input ngModel #sensorSearch [disabled]="!update['sensor']" (ngModelChange)="create['sensor']={}">
            </div>
            <div class="field">
                <label>Name</label>
                <input type="text" [(ngModel)]="create['sensor'].name">
            </div>
            <div class="field">
                <label>Tag</label>
                <input type="text" [(ngModel)]="create['sensor'].tag">
            </div>
            <div class="field">
                <label>Hardware ID</label>
                <input type="text" [(ngModel)]="create['sensor'].hardwareId">
            </div>
            <div class="field">
                <label>Kind</label>
                <select [(ngModel)]="create['sensor'].kind">
                    <option *ngFor="let sk of sensorKinds" [ngValue]="sk">{{ sk }}</option>
                </select>
            </div>
            <div class="field">
                <label>Handler</label>
                <input type="text" [(ngModel)]="create['sensor'].handler">
            </div>
            <div class="field" *ngIf="create['sensor'].kind === 'average'">
                <label>Sensors to average</label>
                <input type="text" [(ngModel)]="create['sensor'].average">
            </div>
            <div class="field">
                <input type="checkbox" [checked]="create['sensor'].action"
                    (change)="create['sensor'].action = $event.target.checked ? {} : null"> Appliance
            </div>
            <div class="field" *ngIf="create['sensor'].action">
                <label>Action type</label>
                <select [(ngModel)]="create['sensor'].action.type">
                    <option *ngFor="let at of actionTypes" [ngValue]="at">{{ at }}</option>
                </select>
            </div>
            <div class="field" *ngIf="create['sensor'].action">
                <label>Action key (relay)</label>
                <input type="number" [(ngModel)]="create['sensor'].action.key"
                    [disabled]="!create['sensor'].action.type">
            </div>
            <div class="field" *ngIf="create['sensor'].action">
                <label>Auto mode (for rules)</label>
                <input type="checkbox" [(ngModel)]="create['sensor'].action.opAuto"
                    [disabled]="!create['sensor'].action.type">
            </div>
            <div class="field">
                <label>Zone</label>
                <select [(ngModel)]="create['sensor'].zone">
                    <option *ngFor="let i of items['zone'] | bofilter: { k: 'name', v: sensorSearchZone.value }" [ngValue]="i._id">{{ i.name }} ({{ i.gatewayName }})</option>
                </select>
                Search: <input ngModel #sensorSearchZone (ngModelChange)="create['sensor'].zone=null">
            </div>
            <div class="field">
                <button (click)="createOrUpdate('sensor')">{{ update['sensor'] ? 'Update' : 'Create' }}</button>
                <button class="delete" (click)="delete('sensor')" *ngIf="update['sensor']">Delete</button>
            </div>
        </div>
    </div>
</div>