<div class="container" [formGroup]="ruleForm">
    <div class="header">
        <div class="name-group">
            <mat-form-field class="input-field">
                <mat-label>Rule Name</mat-label>
                <input placeholder="Rule Name"
                       type="text"
                       formControlName="name"
                       [(ngModel)]="rule.name"
                       matInput>
                <mat-error>
                    Rule name is <strong>required</strong>
                </mat-error>
                <span class="material-icons" matSuffix>edit</span>

            </mat-form-field>
        </div>
        <div class="action-group" *ngIf="dayNightAvailable">
            <mat-radio-group [(ngModel)]="rule.dayPart"
                             [ngModelOptions]="{standalone: true}">
                <mat-radio-button [value]="'day'">Day</mat-radio-button>
                <mat-radio-button [value]="'night'">Night</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="content">
        <div class="section-title">Conditions</div>
        <ng-container *ngFor="let condition of rule.conditions; let i = index">
            <div class="condition section frame">
                <div class="row">
                    <div class="columns">
                        <div class="col-15">
                        </div>
                        <div class="col-45">
                            <mat-label>Type</mat-label>
                            <mat-select class="dropdown frame"
                                        [(ngModel)]="condition.type"
                                        (ngModelChange)="onConditionTypeChange(condition)"
                                        [ngModelOptions]="{standalone: true}">
                                <mat-option *ngFor="let type of conditionTypeOptions" [value]="type.value">
                                    {{ type.title }}
                                </mat-option>
                            </mat-select>
                        </div>
                        <div class="col-40 delete-icon">
                            <button (click)="removeCondition(i)"
                                    [hidden]="i === 0"
                                    class="delete-button">
                                <span class="material-icons button">delete</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="columns">
                        <div class="col-15">
                        </div>
                        <div class="col-45" *ngIf="condition.type === 'parameter'">
                            <mat-label>Choose Sensor</mat-label>
                            <mat-select class="dropdown frame" [(ngModel)]="condition.sensor"
                                        (ngModelChange)="onSelectedSensor(condition)"
                                        [ngModelOptions]="{standalone: true}">
                                <mat-option *ngFor="let sensor of sensors" [value]="sensor._id">
                                    {{ sensor.name }}
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="condition.type === 'parameter' && condition.sensorTypes">
                    <div class="row">
                        <div class="columns">
                            <div class="col-15">
                            </div>
                            <div class="col-45">
                                <mat-label>Choose Parameter</mat-label>
                                <mat-select class="dropdown frame"
                                            [(ngModel)]="condition.sensorType"
                                            [ngModelOptions]="{standalone: true}">
                                    <mat-option *ngFor="let type of condition.sensorTypes" [value]="type">
                                        {{type | sensorDisplayName}}
                                    </mat-option>
                                </mat-select>
                            </div>
                            <div class="col-40">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="columns">
                            <div class="col-15">
                            </div>
                            <div class="col-45">
                                <div class="value-range">
                                    <mat-form-field class="input-field">
                                        <mat-label>Min</mat-label>
                                        <input placeholder="Min" type="number"
                                               [(ngModel)]="condition.min"
                                               [ngModelOptions]="{standalone: true}"
                                               matInput>
                                    </mat-form-field>
                                    <mat-form-field class="input-field">
                                        <mat-label>Max</mat-label>
                                        <input placeholder="Max" type="number"
                                               [(ngModel)]="condition.max"
                                               [ngModelOptions]="{standalone: true}"
                                               matInput>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-40">
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="row" *ngIf="condition.type === 'time-range'">
                    <div class="columns">
                        <div class="col-15">
                            <div class="label"><span class="material-icons">schedule</span></div>
                        </div>
                        <div class="col-45">
                            <div class="time-range">
                                <mat-form-field class="input-field">
                                    <mat-label>From</mat-label>
                                    <input placeholder="From" type="time"
                                           [(ngModel)]="condition.times.fromView"
                                           (ngModelChange)="onTimeSet(condition, 'from')"
                                           [ngModelOptions]="{standalone: true}"
                                           matInput>
                                </mat-form-field>
                                <mat-form-field class="input-field">
                                    <mat-label>To</mat-label>
                                    <input placeholder="To" type="time"
                                           [(ngModel)]="condition.times.toView"
                                           (ngModelChange)="onTimeSet(condition, 'to')"
                                           [ngModelOptions]="{standalone: true}"
                                           matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-40">
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="condition.type === 'time-single'">
                    <div class="columns">
                        <div class="col-15">
                            <div class="label"><span class="material-icons">schedule</span></div>
                        </div>
                        <div class="col-45">
                            <div class="time-range">
                                <mat-form-field class="input-field">
                                    <mat-label>At</mat-label>
                                    <input placeholder="At" type="time"
                                           [(ngModel)]="condition.times.atView"
                                           (ngModelChange)="onTimeSet(condition, 'at')"
                                           [ngModelOptions]="{standalone: true}"
                                           matInput>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-40">
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="add-more section button" (click)="addCondition()">+ Add another condition</div>
        <mat-divider></mat-divider>
        <div class="section-title">Actions</div>
        <ng-container *ngFor="let action of rule.actions; let i = index">
            <div class="action section frame">
                <div class="row">
                    <div class="columns">
                        <div class="col-15">
                        </div>
                        <div class="col-45">
                            <mat-label>
                                Appliance
                            </mat-label>
                            <mat-select class="dropdown frame"
                                        [(ngModel)]="action.appliance"
                                        [ngModelOptions]="{standalone: true}">
                                <mat-option *ngFor="let appliance of appliances"
                                            [value]="appliance">
                                    {{ appliance.name }}
                                </mat-option>
                            </mat-select>
                        </div>
                        <div class="col-40 delete-icon">
                            <button (click)="removeAction(i)"
                                    [hidden]="i === 0"
                                    class="delete-button">
                                <span class="material-icons button">delete</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row"
                     *ngIf="action.appliance && action.appliance.actions.length > 0 && action.interval === undefined">
                    <div class="columns">
                        <div class="col-15">
                        </div>
                        <div class="col-45">
                            <mat-label>Action</mat-label>
                            <mat-select class="dropdown frame"
                                        [(ngModel)]="action.value"
                                        [ngModelOptions]="{standalone: true}">
                                <mat-option *ngFor="let applianceAction of action.appliance.actions"
                                            [value]="applianceAction.value">
                                    {{ applianceAction.viewValue }}
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="columns">
                        <div class="col-15">
                        </div>
                        <div class="col-45">
                            <mat-label>Interval</mat-label>

                        </div>
                        <div class="col-15">
                            <mat-slide-toggle [checked]="action.interval"
                                              class="toggle"
                                              [color]="'primary'"
                                              (change)="onIntervalToggle($event, action)">
                            </mat-slide-toggle>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="action.interval">
                    <div class="row">
                        <div class="columns">
                            <div class="col-15">
                            </div>
                            <div class="col-45">
                                <div class="interval-group">
                                    <div class="label">"on" for</div>
                                    <custom-time-input-field
                                                             [data]="action.interval.on"
                                                             [hasMilliseconds]="action.appliance.sensorKind === 'waterstation'"
                                                             (onChange)="action.interval.on = $event"
                                                             class="custom-field">
                                    </custom-time-input-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="columns">
                            <div class="col-15">
                            </div>
                            <div class="col-45">
                                <div class="interval-group">
                                    <div class="label">Every</div>
                                    <custom-time-input-field
                                                             [data]="action.interval.break"
                                                             [hasMilliseconds]="action.appliance.sensorKind === 'waterstation'"
                                                             (onChange)="action.interval.break = $event"
                                                             class="custom-field">
                                    </custom-time-input-field>
                                </div>
                            </div>
                            <div class="col-40">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="columns">
                            <div class="col-15">
                            </div>
                            <div class="col-45">
                                <div class="interval-group">
                                    <div class="label">No. of iterations</div>

                                </div>
                            </div>
                            <div class="col-40">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="columns">
                            <div class="col-15">
                            </div>
                            <div class="col-45">
                                <div class="interval-group">
                                    <div class="label">Unlimited</div>
                                    <mat-slide-toggle [checked]="action.interval.iterations === 0"
                                                      class="toggle"
                                                      [color]="'primary'"
                                                      (change)="onUnlimitedToggle($event, action)"
                                                      #unlimitedToggle>
                                    </mat-slide-toggle>
                                </div>
                            </div>
                            <div class="col-40">
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="unlimitedToggle.checked === false">
                        <div class="columns">
                            <div class="col-15">
                            </div>
                            <div class="col-45">
                                <div class="value-range">
                                    <mat-form-field class="iteration-input">
                                        <mat-label>Iterations</mat-label>
                                        <input placeholder="Min" type="number"
                                               [(ngModel)]="action.interval.iterations"
                                               matInput
                                               min="1"
                                               formControlName="interval">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-40">
                            </div>
                        </div>
                    </div>
                </ng-container>

            </div>
        </ng-container>
        <div class="add-more section button" (click)="addAction()">+ Add another action</div>
        <!-- <div class="story section frame columns">
            <div class="label left col-15">Story</div>
        </div> -->
    </div>
    <div class="footer">
        <mat-dialog-actions align="end">
            <button mat-button [mat-dialog-close]="undefined">Cancel</button>
            <button mat-flat-button color="primary" (click)="onConfirm()">Save</button>
        </mat-dialog-actions>
        <div class="loading-wrapper" *ngIf="isLoading">
            <mat-spinner diameter="30"></mat-spinner>
        </div>
    </div>
</div>