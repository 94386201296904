import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[fallback]'
})
export class ImgFallbackDirective {

  @Input() fallback: string;

  constructor(private eRef: ElementRef) { }

  @HostListener('error')
  loadFallbackOnError() {
    const element: HTMLImageElement = <HTMLImageElement>this.eRef.nativeElement;
    element.src = this.fallback;
  }
}
