import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Logger } from './logger.service';
import { MD5 } from './md5.service';
import { AuthService } from './auth.service';

const API_PREFIX = environment.apiEndpoint;

interface CacheItem {
  created: Date,
  ttl: number,
  data: any
};

class ApiCacheService {
  private _items: { [key: string]: CacheItem } = {};

  public getItem<T>(key: string): T {
    if (this._items[key]) {
      let item = this._items[key];
      if (item.created.getTime() + item.ttl < new Date().getTime()) {
        delete this._items[key];
        return null;
      }
      return <T>item.data;
    }
    return null;
  }

  public setItem<T>(key: string, value: T, ttl: number = 10000): void {
    this._items[key] = {
      created: new Date(),
      data: value,
      ttl: ttl
    };
  }
}

@Injectable({ providedIn: 'root' })
export class ApiService {
  private _log: Logger;
  private _cache: ApiCacheService = new ApiCacheService();

  constructor(private http: HttpClient,
    logger: Logger,
    private md5: MD5,
    private authService: AuthService) {
    this._log = logger.getSession('ApiService');
  }

  // GET request
  public get<T>(path: string, query: any = {}, responseType?): Observable<T> {
    return this.performRequest<T>(HttpMethod.Get, path, query, responseType);
  }

  // POST request
  public post<T>(path: string, data: any = null): Observable<T> {
    return this.performRequest<T>(HttpMethod.Post, path, data);
  }

  // POST request
  public put<T>(path: string, data: any = null): Observable<T> {
    return this.performRequest<T>(HttpMethod.Put, path, data);
  }

  // POST request
  public delete<T>(path: string, data: any = null): Observable<T> {
    return this.performRequest<T>(HttpMethod.Delete, path, data);
  }

  // Perform the actual request, internal only
  private performRequest<T>(method: HttpMethod, path: string, data: any, responseType?: any): Observable<T> {
    let url = API_PREFIX + path;
    let request: Observable<T>;

    // let hash = this.md5.calc(url + JSON.stringify(data || {}));
    // let cached = this._cache.getItem<T>(hash);
    // if (cached) {
    //   return of(cached);
    // }

    // Build the request object
    switch (method) {
      case HttpMethod.Post:
        request = this.http.post<T>(url, data);
        break;
      case HttpMethod.Put:
        request = this.http.put<T>(url, data);
        break;
      case HttpMethod.Delete:
        request = this.http.delete<T>(url, { params: data });
        break;
      case HttpMethod.Get:
      default:
        request = this.http.get<T>(url, { params: data, responseType: responseType });
        break;
    }

    // Perform the request, pipe the result to handle the HTTP / API errors, then send the result back
    return request.pipe(
      map(resp => {
        // if (method === HttpMethod.Get) {
        //   this._cache.setItem(hash, resp);
        // }

        return resp;
      }),
      catchError(resp => {
        if (!resp.error) {
          resp.error = {
            code: 0,
            message: 'Unknown'
          };
        }
        if (resp.status === 401) {
          this.authService.logout();
        }
        this._log.error(`Error while performing request.\n${HttpMethod[method].toUpperCase()} ${path} [${resp.status}] ${resp.error.message}${resp.error.code !== resp.status ? ` (${resp.error.code})` : ``}`);
        return throwError({ message: resp.error.message, code: resp.error.code, status: resp.status });
      })
    );
  }
}

// Available HTTP methods
enum HttpMethod {
  Get, Post, Put, Delete
}