import { Pipe, PipeTransform } from '@angular/core';
import { SensorNames } from '../constants/SensorNames'

@Pipe({
  name: 'sensorDisplayName'
})
export class SensorPipe implements PipeTransform {

  transform(value: string): string {
    return SensorNames[value];
  }

}
