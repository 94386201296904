<div readOnly [hide]="true" class="add-rule button" (click)="openAddEditNoteDialog()">Add Note...</div>
<mat-card class="card" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
    <ng-container *ngFor="let note of notes">
        <div class="row">
            <div class="appliance-title">
                {{note.content}}
            </div>
            <div class="date">{{note.date | date:'dd.MM.yy HH:mm'}}</div>
            <div class="actions-group" readOnly [hide]="true">
                <button mat-button mat-icon-button onclick="this.blur()"
                        (click)="openAddEditNoteDialog(note)">
                    <mat-icon>
                        edit
                    </mat-icon>
                </button>
                <button mat-button mat-icon-button onclick="this.blur()"
                        (click)="openConfirmDialog(note)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>

        </div>
        <div class="thumbnail-container" *ngIf="note.attachments.length > 0">
            <div class="thumbnail-with-label" *ngFor="let attachment of note.attachments">
                <img class="thumbnail"
                     (click)="previewAttachment(note, attachment)"
                     [src]="getAttachmentThumbnail(note, attachment) | authImage | async">
                <div class="thumbnail-label" matTooltip="{{attachment.label}}">
                    {{ attachment.label | truncate:[8] }}
                </div>
            </div>
        </div>

        <mat-divider></mat-divider>
    </ng-container>
</mat-card>
<div class="loading-wrapper-top">
    <mat-spinner diameter="30" *ngIf="isLoading"></mat-spinner>
</div>

<ng-template
             cdkConnectedOverlay
             [cdkConnectedOverlayOrigin]="trigger"
             cdkConnectedOverlayPanelClass="pos-absolute"
             [cdkConnectedOverlayOpen]="isAttachmentOverlayOpen">
    <div class="image-preview">
        <button mat-button mat-icon-button onclick="this.blur()"
                (click)="isAttachmentOverlayOpen = false">
            <mat-icon>close</mat-icon>
        </button>
        <img [src]="attachmentUrl | authImage | async" alt="" (load)="onImageLoad()">
        <mat-progress-bar mode="indeterminate" *ngIf="isLoadingAttachmentImage"></mat-progress-bar>
    </div>
</ng-template>