<div class="page-container">
    <div class="page-header">
        Log
    </div>
    <div class="container">
        <div style="display: flex; justify-content: space-between;">
            <mat-form-field class="form-field">
                <mat-label>Search</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Type something..." #input>
            </mat-form-field>
            <mat-form-field appearance="legacy" class="form-field">
                <mat-label>Filter by Catergory</mat-label>
                <mat-select multiple [(ngModel)]="categories" (selectionChange)="onMultipleChoose($event)">
                    <mat-option [value]="'errors'">
                        Errors
                    </mat-option>
                    <mat-option [value]="'rules'">
                        Rules
                    </mat-option>
                    <mat-option [value]="'action'">
                        Sensor Actions
                    </mat-option>
                    <mat-option [value]="'settings'">
                        Settings
                    </mat-option>
                    <mat-option [value]="'security'">
                        Security
                    </mat-option>
                    <mat-option [value]="'report'">
                        Reports
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div style="display: flex; align-items: center;">
                <mat-form-field appearance="legacy" [style.margin-right.px]="8">
                    <mat-label>Enter a date range</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="Start date" [(ngModel)]="selectedRange.from">
                        <input matEndDate placeholder="End date" [(ngModel)]="selectedRange.to">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                <button mat-flat-button [disabled]="selectedRange === undefined" color="primary"
                        (click)="fetchData()">Apply</button>

            </div>
        </div>
        <div class="table-container">
            <table mat-table [dataSource]="dataSource"
                   class="mat-elevation-z8 table"
                   matSort>

                <ng-container matColumnDef="createdAt">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.width.%]="10"> Date </th>
                    <td mat-cell *matCellDef="let element" [style.width.%]="20"> {{element?.createdAt | date : 'dd.MM.yy
                        HH:mm:ss'}} </td>
                </ng-container>
                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.width.%]="15"> User </th>
                    <td mat-cell *matCellDef="let element" [style.width.%]="20"> {{element?.user?.name ?
                        element?.user?.name : 'System'}} </td>
                </ng-container>

                <ng-container matColumnDef="message">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header [style.width.%]="60"> Message </th>
                    <td mat-cell *matCellDef="let element" [style.width.%]="60"> {{element?.message}} </td>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    [ngClass]="row.isError ? 'error-row' : ''"
                    [@rowsAnimation]=""></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                </tr>
            </table>
            <div class="loading-wrapper-transparent" *ngIf="loading">
                <mat-spinner diameter="30"></mat-spinner>
            </div>
        </div>
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="pageSize"></mat-paginator>


    </div>
</div>