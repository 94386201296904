import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Appliance, ControlModeEnum } from 'src/app/models/appliance';
import { Zone } from 'src/app/models/zone';
import { ActivatedRoute, Router } from '@angular/router';
import { SensorService } from 'src/app/services/sensor.service';
import { MatSelectChange } from '@angular/material/select';
import { DataService } from 'src/app/services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { EditNameDialogComponent } from '../../dialogs/edit-name-dialog/edit-name-dialog.component';
import { ConfirmDialogComponent } from '../../general/confirm-dialog/confirm-dialog.component';
import { Observable, Subject, Subscription } from 'rxjs';
import { pairwise } from 'rxjs/operators';
import { SocketIoService } from 'src/app/services/socket-io.service';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ZoneService } from 'src/app/services/zone.service';
import { take } from 'rxjs/operators';
import { ActionLog } from 'src/app/models/actionLog';
import { DatePipe } from '@angular/common';
import { rowsAnimation } from '../../log/template.animation';
import { ApplianceCrankTypes, ApplianceSliderTypes, ApplianceToggleTypes } from 'src/app/models/sensor';
import { MatSliderChange } from '@angular/material/slider';


export interface ApplianceWithOldValue extends Appliance {
  oldValue?;
  opAutoOldValue?;
}

@Component({
  selector: 'app-appliances-list',
  templateUrl: './appliances-list.component.html',
  styleUrls: ['./appliances-list.component.less'],
  animations: [rowsAnimation]
})
export class AppliancesListComponent implements OnInit, OnDestroy {
  ApplianceToggleTypes = ApplianceToggleTypes;
  ApplianceSliderTypes = ApplianceSliderTypes;
  ApplianceCrankTypes = ApplianceCrankTypes;
  appliances: ApplianceWithOldValue[] = [];
  viewValue = Appliance.viewValue;
  isLoading: boolean = false;
  isPendingToggleResponse: boolean = false;
  dataSource: MatTableDataSource<ActionLog>;
  displayedColumns: string[] = ['createdAt', 'user', 'message'];
  loading: boolean;
  pageSize = 10;
  selectedRange: any = {};
  zoneId: string;
  zone: Zone;
  timeout: any;
  categories: any;
  ControlModeEnum = ControlModeEnum;
  private subscription: Subscription;
  private logSubscription: Subscription;
  private applianceSubscription: Subscription;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('slideToggle') slideToggle: MatSlideToggle;
  @ViewChild('input') searchInput: ElementRef;


  constructor(
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
    private sensorService: SensorService,
    private dataService: DataService,
    public dialog: MatDialog,
    private socketService: SocketIoService,
    private zoneService: ZoneService) {
    this.dataService.sharedZone$.pipe(take(1)).subscribe(zone => {
      if (zone === undefined) {
        return;
      }
      this.zoneId = zone._id;
      this.appliances = zone.appliances;
      if (this.appliances === undefined) {
        this.appliances = Appliance.reduceAppliances(zone.sensors, zone.online);
      }
      this.appliances.map(appliance => {
        appliance.opAutoOldValue = appliance.opAuto;
        appliance.oldValue = appliance.value;
      });

      this.applianceSubscription = this.socketService.sensorUpdatedMessages$.subscribe(sensor => {
        if (sensor == null) {
          return;
        }
        console.log(sensor);

        try {
          var appliance = Appliance.reduceAppliances([sensor])[0];
          var applianceIndex = this
            .appliances
            .findIndex((element) => element.sensorId == appliance.sensorId);
          if (applianceIndex == -1) {
            return;
          }
          var currentApplianceAsJson = this.appliances[applianceIndex];

          for (const [key, value] of Object.entries(currentApplianceAsJson)) {
            if (appliance[key] != null) {
              currentApplianceAsJson[key] = appliance[key];
            }
          }

          this.appliances[applianceIndex] = currentApplianceAsJson;
        } catch (e) {

        }
      });


    })
    this.subscription = this.socketService.sensorValueMessages$.subscribe((data) => {
      if (data === null) {
        return;
      }
      if (data.type === 'state') {

        let _appliance = this.appliances.find(x => x.sensorId === data.id);
        if (_appliance === undefined) {
          return;
        }
        this.isPendingToggleResponse = false;
        clearTimeout(this.timeout);
        this.isLoading = this.isPendingToggleResponse;
        _appliance.value = data.value;
        _appliance.lastPing = new Date();
      }
    })



    this.route.params.subscribe(params => {
      console.log(params);
      // this.zoneId = params.zoneId;
    })
  }

  ngOnInit(): void {

    this.fetchData();
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.logSubscription?.unsubscribe();
    this.applianceSubscription?.unsubscribe();
  }

  prevValue: any

  onToggleChange(event: MatSlideToggleChange, appliance: ApplianceWithOldValue) {
    clearTimeout(this.timeout);
    const dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        appliance.oldValue = event.checked;
        this.isPendingToggleResponse = true;
        this.isLoading = this.isPendingToggleResponse;
        this.sensorService.sensorAction(appliance.sensorId, event.checked ? 1 : 0)
          .subscribe(result => {
            // this.isLoading = false;
            this.timeout = setTimeout(() => {
              this.isLoading = false;
              if (appliance.valueAsBoolean != event.checked) {
                appliance.valueAsBoolean = appliance.oldValue;
              }
            }, 3000);
          }, () => {
            // this.isLoading = false;
          }, () => {
            // this.isLoading = false;
          })
      } else {
        appliance.value = appliance.oldValue;
      }
    })
  }


  onAction(event: MatSelectChange | MatSliderChange, appliance: ApplianceWithOldValue) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        appliance.oldValue = event.value;
        this.isLoading = true;
        this.sensorService.sensorAction(appliance.sensorId, event.value).subscribe(result => {
        }, () => {
          this.isLoading = false;
        }, () => {
          this.isLoading = false;
        })
      } else {
        appliance.value = appliance.oldValue;
      }
    })
  }

  onSetMode(event: MatSelectChange, appliance: Appliance) {
    this.isLoading = true;
    this.sensorService.sensorMode(appliance.sensorId, event.value).subscribe(result => {
    }, () => {
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    })
  }

  sendAction(value: number, appliance: Appliance) {
    this.sensorService.sensorAction(appliance.sensorId, value).subscribe(result => {
    }, () => {
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    })
  }



  editApplianceName(appliance: Appliance) {
    const dialogRef = this.dialog.open(EditNameDialogComponent, {
      disableClose: true,
      data: {
        title: 'Appliance',
        name: appliance.name,
        function: (name) => this.sensorService.editApplianceName(appliance.sensorId, appliance.key, name)
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) return;
      setTimeout(() => appliance.name = result.name);
    });
  }

  deleteAppliance(appliance: Appliance) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);

      if (result) {
        console.log('he');

        this.sensorService.deleteAppliance(appliance.sensorId, appliance.key).subscribe(result => {
          this.appliances.splice(this.appliances.findIndex(x => x.sensorId === appliance.sensorId && x.key === appliance.key), 1);
        })
      }
    })
  }

  fetchData() {
    this.loading = true;
    var request = {};
    if (this.selectedRange) {
      var from;
      var to;
      from = new Date(this.selectedRange.from).getTime();
      to = new Date(this.selectedRange.to);
      to = new Date(to.getFullYear(), to.getMonth(), to.getDate(), 23, 59).getTime();
      request['from'] = from;
      request['to'] = to;
    }

    this.zoneService.log(request, this.zoneId).subscribe(response => {
      this.loading = false;
      this.dataSource = new MatTableDataSource(response.reverse());
      this.dataSource.filterPredicate = ((data, filter) =>
        this.datePipe.transform(data.createdAt, 'dd.MM.yy HH:mm:ss').includes(filter) ||
        data.user?.name.toLowerCase().includes(filter) ||
        data.message?.toLowerCase().includes(filter) ||
        this.categories?.includes(data.category) ||
        this.categories?.includes('errors') && data.isError
      );
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;

    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onMultipleChoose(event: MatSelectChange) {
    const searchInput = (this.searchInput.nativeElement as HTMLInputElement).value;
    if (searchInput.length > 0) {
      this.dataSource.filter = searchInput;
      return;
    }
    if (searchInput.length === 0 && event.value.length === 0) {
      this.dataSource.filter = '';
      return
    }
    if (searchInput.length === 0) {
      this.dataSource.filter = event.value;
      return
    }
  }


}
