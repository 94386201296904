import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ZoneService } from 'src/app/services/zone.service';
import { Attachment, Note } from 'src/app/models/note';

export interface AttachmentWithLoading extends Attachment {
  loading?: boolean;
  attachedToNote?: boolean;
}

@Component({
  selector: 'app-add-note-dialog',
  templateUrl: './add-note-dialog.component.html',
  styleUrls: ['./add-note-dialog.component.less']
})


export class AddNoteDialogComponent implements OnInit {
  isLoading: boolean = false;
  content: string;
  attachments: AttachmentWithLoading[] = [];


  constructor(public dialogRef: MatDialogRef<AddNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private zoneService: ZoneService) {
    if (data.note) {
      this.content = data.note.content;
      data.note.attachments.forEach(attachment => {
        attachment.loading = false;
        attachment.attachedToNote = true;
        this.attachments.push(attachment);
      })
    }
  }

  ngOnInit(): void {
  }

  handleFileInput(files: FileList) {
    if (files.length === 0) {
      return;
    }
    let file = files.item(0);
    this.attachments.push({ label: file.name, size: file.size, loading: true });
    this.zoneService.uploadAttachment(this.data.zoneId, file).subscribe(result => {
      this.attachments[this.attachments.length - 1]['loading'] = false;
      this.attachments[this.attachments.length - 1]['filename'] = result['filename'];
      this.attachments[this.attachments.length - 1]['_id'] = result['_id'];

    });
  }

  deleteAttachment(attachment: AttachmentWithLoading) {
    if (attachment.loading) {
      return;
    }
    let attachmentId = attachment.attachedToNote ? attachment.filename : attachment._id;
    let noteId = attachment.attachedToNote ? this.data.note._id : undefined;
    this.zoneService.deleteAttachment(this.data.zoneId, noteId, attachmentId).subscribe(result => {
      if (result['deleted'] === true) {
        let deleteIndex = this.attachments.findIndex(x => x._id === attachment._id)
        this.attachments.splice(deleteIndex, 1);
        if (this.data.note && this.data.note.attachments.length > 0) {
          this.data.note.attachments.splice(deleteIndex, 1);
        }
      }
    });
  }

  onConfirm() {
    this.isLoading = true;
    if (this.data.note) {
      this.data.note.content = this.content;
      let note = {
        content: this.content,
        attachments: this.attachments.filter(x => !x.attachedToNote).map(x => x._id)
      }
      this.zoneService.updateNote(this.data.zoneId, note, this.data.note._id).subscribe(result => {
        if (result.updated === undefined) return;
        this.data.note.attachments = this.attachments.map(x => {
          return {
            _id: x._id,
            label: x.label,
            size: x.size,
            filename: x.filename
          }
        });

        this.dialogRef.close();
      }, complete => {
        this.isLoading = false;
      })
    } else {
      let note = {
        content: this.content,
        attachments: this.attachments.map(x => x._id)
      };
      this.zoneService.addNote(this.data.zoneId, note).subscribe(result => {
        this.data.note = {
          content: this.content,
          date: result.created.date,
          _id: result.created._id
        } as Note;
        this.data.note.attachments = this.attachments.map(x => {
          return {
            _id: x._id,
            label: x.label,
            size: x.size,
            filename: x.filename
          }
        });
        this.isLoading = false;
        this.dialogRef.close(this.data.note);
      }, complete => {
        this.isLoading = false;
      })
    }

  }

}
