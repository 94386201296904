import { Component, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { Zone } from 'src/app/models/zone';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexTooltip,
  ApexXAxis,
  ApexLegend,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexYAxis,
  ApexStroke
} from "ng-apexcharts";
import { SensorType } from 'src/app/models/sensor';
import { SensorTypes } from 'src/app/constants/SensorTypes';
import { ZoneService } from 'src/app/services/zone.service';

import { DatePipe } from '@angular/common';
import { SensorPipe } from 'src/app/pipes/sensor-display-name.pipe';
import { DomSanitizer } from '@angular/platform-browser';
import * as FileSaver from 'file-saver';


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  markers: any; //ApexMarkers;
  stroke: ApexStroke; //ApexStroke;
  yaxis: ApexYAxis | ApexYAxis[];
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  fill: ApexFill;
  tooltip: ApexTooltip;
  colors: string[];
};

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.less']
})
export class ReportsComponent implements OnInit {
  zoneList: Zone[];
  selectedZone: Zone = {};
  selectedComparedZone: Zone = {};
  selectedTypes: SensorType[];
  selectedComparedTypes: SensorType[];
  selectedRange: any = {};
  selectedComparedRange: any = {};
  dateRangeOption: string = 'none';
  comparedDateRangeOption: string = 'none';
  shouldCompare: boolean = false;
  sensorTypeOptions: SensorType[];
  stateHistoryOptions: any;
  comparedStateHistoryOptions: any;
  selectedState: any;
  selectedComparedState: any;
  isLoading: boolean = false;

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public comparedChartOptions: Partial<ChartOptions>;
  constructor(private sanitizer: DomSanitizer, private zoneService: ZoneService, private datePipe: DatePipe, private sensorDisplayName: SensorPipe) {
    if (localStorage.getItem('zoneList')) {
      this.zoneList = JSON.parse(localStorage.getItem('zoneList'));
    }
    this.chartOptions = {
      colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#4caf50', '#4ecdc4', '#f8961e'],
      series: [
      ],
      chart: {
        height: 350,
        type: "line",
        stacked: false,
        toolbar: { show: false }
      },
      dataLabels: {
        enabled: false
      },
      title: {
        text: this.selectedZone.name || '',
        align: "left",
        offsetX: 110
      },
      tooltip: {
        fixed: {
          enabled: true,
          position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60
        },
        x: {
          format: 'dd/MM/yyyy HH:mm'
        }

      },
      legend: {
        horizontalAlign: "left",
        offsetX: 40
      },
      stroke: {
        curve: "smooth",
      }
    };
    this.comparedChartOptions = { ...this.chartOptions };
    this.sensorTypeOptions = SensorTypes;

  }

  ngOnInit(): void {
  }

  onDateRangeChange(event, compared?: string) {
    if (event.value === 'none') {
      if (compared === 'compared') {
        this.selectedComparedRange = {};
      } else {
        this.selectedRange = {};

      }
    }

    if (event.value === 'cycle-state') {
      let zoneId = compared === 'compared' ? this.selectedComparedZone._id : this.selectedZone._id
      this.zoneService.getZone(zoneId).subscribe(result => {
        let zone = result[0] as Zone
        if (compared === 'compared') {
          this.comparedStateHistoryOptions = zone.activeCycle?.stateHistory;
        } else {
          this.stateHistoryOptions = zone.activeCycle?.stateHistory;
        }
      })
    }

    if (event.value === 'cycle') {
      let zoneId = compared === 'compared' ? this.selectedComparedZone._id : this.selectedZone._id
      this.zoneService.getCycles(zoneId).subscribe(result => {
        let cycles = result.cycles.map(x => {
          return {
            state: x.name,
            dates: x.dates
          }
        })
        if (compared === 'compared') {
          this.comparedStateHistoryOptions = cycles;
        } else {
          this.stateHistoryOptions = cycles;
        }
      })
    }
  }

  fetchReport() {
    this.fetchComparedReport();
    this.isLoading = true;
    var from;
    var to;
    if (this.dateRangeOption != 'none') {
      if (this.dateRangeOption === 'custom-range') {
        // from = new Date(this.selectedRange.from);
        // from = new Date(Date.UTC(from.getFullYear(), from.getMonth(), from.getDate())).getTime();
        from = new Date(this.selectedRange.from).getTime();

        to = new Date(this.selectedRange.to);
        // to = new Date(Date.UTC(to.getFullYear(), to.getMonth(), to.getDate(), 23, 59)).getTime();
        to = new Date(to.getFullYear(), to.getMonth(), to.getDate(), 23, 59).getTime();

      } else {
        from = Date.parse(this.selectedState.dates.start)
        // to = Date.parse(this.selectedState.dates.end || new Date().toISOString())
        to = Date.parse(this.selectedState.dates.end || new Date())

      }
    }

    let request = {
      zoneId: this.selectedZone._id,
      type: this.selectedTypes || undefined,
      from: from,
      to: to,
    }
    this.zoneService.reports(request).subscribe(result => {
      this.isLoading = false
      this.updateChart(result.report, this.chartOptions)
    })
  }

  fetchComparedReport() {
    if (!this.shouldCompare) return;
    this.isLoading = true;
    var from;
    var to;
    if (this.comparedDateRangeOption != 'none') {
      if (this.comparedDateRangeOption === 'custom-range') {
        // from = new Date(this.selectedComparedRange.from);
        //from = new Date(Date.UTC(from.getFullYear(), from.getMonth(), from.getDate())).getTime();
        from = new Date(this.selectedComparedRange.from).getTime();
        to = new Date(this.selectedComparedRange.to);
        // to = new Date(Date.UTC(to.getFullYear(), to.getMonth(), to.getDate(), 23, 59)).getTime();
        to = new Date(to.getFullYear(), to.getMonth(), to.getDate(), 23, 59).getTime();

      } else {
        from = Date.parse(this.selectedComparedState.dates.start)
        //to = Date.parse(this.selectedComparedState.dates.end || new Date().toISOString())
        to = Date.parse(this.selectedComparedState.dates.end || new Date().toISOString())
      }
    }

    let request = {
      zoneId: this.selectedComparedZone._id,
      type: this.selectedComparedTypes || undefined,
      from: from,
      to: to,
    }
    this.zoneService.reports(request).subscribe(result => {
      this.isLoading = false;
      this.updateChart(result.report, this.comparedChartOptions);
    })
  }

  updateChart(result: any, chartOption: Partial<ChartOptions>) {
    if (result.length === 0) return;
    let values = result;
    let temp = values.filter(x => x.avgValue != null)
      .reduce((acc, item) => {
        let obj = { value: +item.avgValue?.toFixed(2), date: item._id.date }
        let index = acc.findIndex(x => x.name === item._id.type);
        if (index > -1) {
          acc[index].values.push(obj);
        } else {
          acc.push({ name: item._id.type, values: [obj] })
        }
        return acc;
      }, [])
    temp.forEach(x => x.values.reverse());
    let series: ApexAxisChartSeries = temp.map(x => {
      return {
        name: x.name,
        //type: "area",
        data: x.values.map(v => [new Date(v.date).getTime(), v.value])
        //data: x.values.map(v => v.value)
      }
    })
    chartOption.series = series
    let xAxis: ApexXAxis = {
      // categories: temp[0].values.map(x => this.datePipe.transform(x.date, 'dd-MM-yyyy HH:mm')),
      type: 'datetime',
      // min: new Date(new Date(temp[0].values[0].date).getTime() - new Date().getTimezoneOffset() * 60000).getTime(),
      // min: Date.parse(temp[0].values[0].date),
      //min: new Date(temp[0].values[0].date).getTime() - new Date().getTimezoneOffset() * 60000,
      tickAmount: 6,
      // min: new Date(temp[0].values[0].date).getTime()
      labels: {
        datetimeUTC: false
      }
    }
    chartOption.xaxis = xAxis;
    let yAxis = temp.map((x, index) => {
      let color = this.chartOptions.colors[index % this.chartOptions.colors.length]
      return {
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: color
        },
        labels: {
          style: {
            colors: color
          }
        },
        title: {
          text: this.sensorDisplayName.transform(x.name),
          style: {
            color: color,
            fontSize: 14
          }
        },
        tooltip: {
          enabled: true
        }
      }
    })
    chartOption.yaxis = yAxis;
  }

  exportToCSV() {
    this.isLoading = true;
    var from;
    var to;
    if (this.dateRangeOption != 'none') {
      if (this.dateRangeOption === 'custom-range') {
        // from = new Date(this.selectedRange.from);
        // from = new Date(Date.UTC(from.getFullYear(), from.getMonth(), from.getDate())).getTime();
        from = new Date(this.selectedRange.from).getTime();

        to = new Date(this.selectedRange.to);
        // to = new Date(Date.UTC(to.getFullYear(), to.getMonth(), to.getDate(), 23, 59)).getTime();
        to = new Date(to.getFullYear(), to.getMonth(), to.getDate(), 23, 59).getTime();

      } else {
        from = Date.parse(this.selectedState.dates.start)
        // to = Date.parse(this.selectedState.dates.end || new Date().toISOString())
        to = Date.parse(this.selectedState.dates.end || new Date())

      }
    }

    let request = {
      zoneId: this.selectedZone._id,
      type: this.selectedTypes || undefined,
      from: from,
      to: to,
    }
    this.zoneService.csvExport(request).subscribe(result => {
      this.isLoading = false
      const blob = new Blob([result], { type: 'type/octet-stream' });
      FileSaver.saveAs(blob, 'csv-export.csv');
    })
  }


}
