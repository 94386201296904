<div class="container" [ngClass]="{'show-menu': isShowMenu}">
    <div class="header">
        <img src="/assets/images/brand-header.svg" class="button" routerLink="/" (click)="closeMenu.emit()">
        <span class="material-icons close button"
              (click)="closeMenu.emit()">close</span>
    </div>
    <div class="link-group">
        <div class="link" routerLink="/dashboard" (click)="closeMenu.emit()">dashboard</div>
        <div class="link" routerLink="/reports" (click)="closeMenu.emit()">reports</div>
        <div class="link" routerLink="/log" (click)="closeMenu.emit()">log</div>
        <div class="link" routerLink="/settings" (click)="closeMenu.emit()">preferences</div>
        <div
             *ngIf="authService.isSuperAdmin"
             class="link" routerLink="/backoffice" (click)="closeMenu.emit()">backoffice</div>

    </div>
    <div class="version">
        {{currentApplicationVersion}}
    </div>
</div>