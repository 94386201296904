import { Component, OnDestroy, OnInit } from '@angular/core';
import { ZoneService } from '../../services/zone.service';
import { Zone } from '../../models/zone';
import { Sensor, SensorTypes } from '../../models/sensor';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service'
import { SocketIoService } from 'src/app/services/socket-io.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit, OnDestroy {

    zones: Zone[] = [];
    private subscription: Subscription;

    constructor(private zoneService: ZoneService,
        private router: Router,
        private dataService: DataService,
        private socketService: SocketIoService) {
        this.subscription = this.socketService.sensorValueMessages$.subscribe((data) => {
            this.updateSensorValue(data);
        })
    }

    ngOnInit(): void {
        this.getZones();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    getZones(): void {
        this.zoneService.zones().subscribe((response: Zone[]) => {

            this.zones = response;
            let listOfZones = this.zones.map(x => {
                return { _id: x._id, name: x.name }
            })

            localStorage.setItem('zoneList', JSON.stringify(listOfZones));

            this.zones.forEach(zone => {
                zone.reducedSensors = Sensor.breakdownSensors(zone.sensors, zone.alerts);
                zone.alertSensors = Sensor.reduceSensorAlerts(zone.sensors, zone.alerts);
                zone.alertAppliances = Sensor.reduceApplianceAlerts(zone.sensors, zone.alerts, ['status']);

                zone.sensors.forEach(sensor => sensor.zone = zone._id);
            })

            // this.dataService.zones = this.zones;
        }, (err) => {
            switch (err.status) {
                case 401:
                    //   this._logger.info('Incorrect');
                    break;
            }
        }, () => {
            //   this._logger.info('Completed')
        });
    }


    updateSensorValue(data) {
        try {
            this.zones.forEach(zone => {
                zone.reducedSensors?.forEach(reducedSensor => {
                    let value = reducedSensor.values?.find(value => value.type === data.type);
                    let sensor = value?.values?.find(innerValue => innerValue.id === data.id);
                    if (sensor === undefined) {
                        return;
                    }
                    sensor.value = [data.value];
                });
                zone.sensors?.forEach(sensor => {
                    let _sensor = sensor?.lastValues?.find(lv => lv.id === data.id);
                    if (_sensor === undefined) {
                        return;
                    }
                    if (_sensor?.value?.type === data.type) {
                        _sensor.value.value = [data.value];
                    }
                })

            });

            this.dataService.zones = this.zones;
        } catch (error) {
            console.error('error update value from socket', error);
        }
    }


}
