import { Injectable } from '@angular/core';
import { Zone } from '../models/zone';
import { ZoneService } from './zone.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Sensor } from '../models/sensor';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public zones?: Zone[];

  private sharedZone = new BehaviorSubject<Zone>(null);

  get sharedZone$(): Observable<Zone> {
    return this.sharedZone.asObservable();
  }

  public updateZone(zone: Zone) {
    this.sharedZone.next(zone);
  }

  private sharedSensor = new BehaviorSubject<Sensor>(null);

  get sharedSensor$(): Observable<Sensor> {
    return this.sharedSensor.asObservable();
  }

  public updateSensor(sensor: Sensor) {
    this.sharedSensor.next(sensor);
  }


  constructor(private zoneService: ZoneService) { }

  public getZoneById(zoneId: string): Zone {
    return this.zones?.find(x => x._id === zoneId);
  }

}
