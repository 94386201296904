import { query } from '@angular/animations';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
const io = require('socket.io-client');
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SocketIoService {

  socket;
  private sensorValueMessages = new BehaviorSubject<any>(null);
  get sensorValueMessages$(): Observable<any> {
    return this.sensorValueMessages.asObservable();
  }

  private sensorUpdatedMessages = new BehaviorSubject<any>(null);
  get sensorUpdatedMessages$(): Observable<any> {
    return this.sensorUpdatedMessages.asObservable();
  }

  private ruleMessages = new BehaviorSubject<any>(null);
  get ruleMessages$(): Observable<any> {
    return this.ruleMessages.asObservable();
  }

  private logValueMessages = new BehaviorSubject<any>(null);
  get logValueMessages$(): Observable<any> {
    return this.logValueMessages.asObservable();
  }

  private notifications = new BehaviorSubject<any>(null);
  get notifications$(): Observable<any> {
    return this.notifications.asObservable();
  }

  didJoin = false;

  constructor(private authService: AuthService) {
    this.setupSocketConnection(() => {
      this.joinZoneUpdates();
      if (this.didJoin) {
        return
      }
      this.didJoin = true;
      this.listenSensorValue();
      this.listenLogValue();
      this.listenNotification();
      this.listenSensorUpdated();
      this.listenRuleUpdates();
    })
  }

  setupSocketConnection(callback) {
    const token = this.authService.getToken(false, false);
    this.socket = io(environment.apiEndpoint,
      { query: { token: token } });
    this.socket.on('connect', () => {
      console.log('socket connected');
      callback();
    });
    this.socket.on('disconnect', () => {
      console.log('socket disconnected');
    })
  }

  closeSocketConnection() {
    this.socket?.disconnect();
  }

  private joinZoneUpdates() {
    this.socket.emit('join-zone-updates');
  }

  joinGatewayUpdates(zoneId: string) {
    this.socket.emit('join-gateway-updates', zoneId);
  }

  private listenSensorValue() {
    this.socket.on('sensor-value', (data: any) => {
      this.sensorValueMessages.next(data);
    });
  }

  private listenLogValue() {
    this.socket.on('action-log', (data: any) => {

      this.logValueMessages.next(data);
    })
  }


  private listenNotification() {
    this.socket.on('new-notification', (data: any) => {

      this.notifications.next(data);
    })
  }

  private listenSensorUpdated() {
    this.socket.on('sensor-updated', (data: any) => {

      this.sensorUpdatedMessages.next(data);
    });
  }

  private listenRuleUpdates() {

    this.socket.on('rule-created', (data: any) => {
      if (data == null) {
        return;
      }
      console.log('rule-created', data);
      this.ruleMessages.next(data);
    });

    this.socket.on('rule-updated', (data: any) => {
      if (data == null) {
        return;
      }
      console.log('rule-updated', data);
      this.ruleMessages.next(data);
    });

    this.socket.on('rule-deleted', (data: any) => {
      if (data == null) {
        return;
      }
      console.log('rule-deleted', data);
      this.ruleMessages.next(data);
    });
  }

  listenPairingDone(callback) {
    this.socket.on('pair-done', (data: any) => {
      callback(data);
    });
  }

  listenCalibrateStatus(callback) {
    this.socket.on('pair-done', (data: any) => {
      callback(data);
    });
  }


}
