import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Sensor } from 'src/app/models/sensor';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { SensorService } from 'src/app/services/sensor.service';
import { EditSensorNameDialogComponent } from '../edit-sensor-name-dialog/edit-sensor-name-dialog.component';

@Component({
  selector: 'app-sensor',
  templateUrl: './sensor.component.html',
  styleUrls: ['./sensor.component.less']
})
export class SensorComponent implements OnInit {
  sensor: Sensor;
  activeLinkIndex = -1;
  navLinks: any[];
  constructor(private router: Router,
    private sensorService: SensorService,
    private dataService: DataService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private authService: AuthService) {
    this.navLinks = [
      {
        label: 'Reports',
        link: './reports',
        index: 0
      }, {
        label: 'Notifications',
        link: './notifications',
        index: 1
      },
      {
        label: 'Settings',
        link: './settings',
        index: 2
      }
    ];

    this.dataService.sharedSensor$.subscribe(sensor => {

      if (sensor === undefined) {
        return;
      }
      this.sensor = sensor;
      if (!authService.canEdit()) {
        this.navLinks.splice(2, 1);
        return
      }
      if (this.sensor?.kind == 'water' || this.sensor?.kind == 'sky') {
        this.navLinks.push({
          label: 'Calibration',
          link: './calibration',
          index: 3
        })
      }
    });

    this.route.params.subscribe(params => {
      if (this.sensor === null) {
        this.fetchSensor(params.sensorId);
      }
    });


  }

  ngOnInit(): void {
  }

  fetchSensor(sensorId) {
    this.sensorService.sensor(sensorId).subscribe(result => {
      this.sensor = result;
      this.sensor._id = sensorId;
      this.dataService.updateSensor(result)

    })
  }

  editSensorName() {
    const dialogRef = this.dialog.open(EditSensorNameDialogComponent, {
      disableClose: true,
      data: { sensor: new Sensor(this.sensor.name, this.sensor._id) }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) return;
      this.sensor.name = result.name;
    });
  }

}
