import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { Zone, Alert } from 'src/app/models/zone';
import { Router } from '@angular/router';
import { ReducedSensor } from 'src/app/models/sensor';
import { ZoneService } from 'src/app/services/zone.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DataService } from 'src/app/services/data.service';
import { AuthService } from 'src/app/services/auth.service';



export class SensorWithLoadingState extends ReducedSensor {
  isLoading?: boolean = false;
}

@Component({
  selector: 'app-alert-settings',
  templateUrl: './alert-settings.component.html',
  styleUrls: ['./alert-settings.component.less']
})
export class AlertSettingsComponent implements OnInit {

  sensors: SensorWithLoadingState[];

  zone: Zone;

  get dayNightAvailable() {
    return this.zone?.dayPartSettings !== undefined;
  }

  constructor(public dialog: MatDialog, private router: Router,
    private zoneService: ZoneService, private dataService: DataService) {
    this.dataService.sharedZone$.subscribe(zone => {
      if (zone === undefined) {
        return;
      }
      this.zone = zone;

      this.sensors = [...this.zone.alertSensors as SensorWithLoadingState[], ...this.zone.alertAppliances as SensorWithLoadingState[]];
      this.sensors.forEach(x => {
        if (x.alert === undefined) {
          x.alert = { active: false }
        }
      })
    })
  }

  ngOnInit(): void {
  }

  openAlertDialog(sensor: ReducedSensor) {
    let alert: Alert = {
      sensorType: sensor.type,
      day: sensor.alert?.day || { min: 0, max: 0 },
      night: sensor.alert?.night || { min: 0, max: 0 },
      _id: sensor.alert?._id || undefined,
      active: sensor.alert?.active || false,
    }
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        alert: alert, id:
          this.zone._id, isZone: true,
        dayNightAvailable: this.dayNightAvailable
      },
    });

    dialogRef.afterClosed().subscribe(alert => {
      if (alert === undefined) return;
      sensor.alert = alert;

    });
  }

  onNotificationChange(sensor: SensorWithLoadingState) {
    sensor.isLoading = true;
    if (sensor.type === 'power') {
      if (sensor.alert._id === undefined) {
        sensor.alert = {
          sensorType: sensor.type,
          active: true,
          day: { min: 0, max: 2 }
        }
        this.zoneService.setAlert(this.zone._id, sensor.alert).subscribe(result => {
          sensor.isLoading = false;
          sensor.alert._id = result.created;
          sensor.alert.active = true;
        });
      } else {
        sensor.alert.day = { min: 0, max: 2 };
        this.zoneService.updateAlert(this.zone._id, sensor.alert).subscribe(result => {
          sensor.isLoading = false;
        });
      }
      return;
    }

    if (sensor.type === 'status') {
      if (sensor.alert._id === undefined) {
        sensor.alert = {
          sensorType: sensor.type,
          active: true,
          day: { min: 0, max: 0 }
        }
        this.zoneService.setAlert(this.zone._id, sensor.alert).subscribe(result => {
          sensor.isLoading = false;
          sensor.alert._id = result.created;
          sensor.alert.active = true;
        });
      } else {
        sensor.alert.day = { min: 0, max: 0 };
        this.zoneService.updateAlert(this.zone._id, sensor.alert).subscribe(result => {
          sensor.isLoading = false;
        });
      }
      return;
    }

    this.zoneService.updateAlert(this.zone._id, sensor.alert).subscribe(result => {
      sensor.isLoading = false;
    });

  }

}
