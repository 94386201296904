import { Component, OnInit } from '@angular/core';
import { AddNoteDialogComponent } from '../add-note-dialog/add-note-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ZoneService } from 'src/app/services/zone.service';
import { Zone } from 'src/app/models/zone';
import { Router } from '@angular/router';
import { Attachment, Note } from 'src/app/models/note';
import { ConfirmDialogComponent } from '../../general/confirm-dialog/confirm-dialog.component';
import { DataService } from 'src/app/services/data.service';
import { interval, Subscription } from 'rxjs';
import { ConnectedPosition, FlexibleConnectedPositionStrategy } from '@angular/cdk/overlay';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.less']
})
export class NotesComponent implements OnInit {

  notes: Note[] = [];
  zone: Zone;
  isLoading: boolean = false;
  isAttachmentOverlayOpen = false;
  attachmentUrl: string;
  intervalObservable$: Subscription;
  isLoadingAttachmentImage: boolean = false;


  constructor(public dialog: MatDialog, private zoneService: ZoneService, private router: Router, private dataService: DataService) {
    this.dataService.sharedZone$.subscribe(zone => {
      this.zone = zone;
    })

  }

  ngOnInit(): void {
    this.fetchNotes();
    this.intervalObservable$ = interval(30 * 1000).subscribe(() => this.fetchNotes());
  }

  ngOnDestroy() {
    this.intervalObservable$.unsubscribe();
  }

  fetchNotes() {
    this.isLoading = true;
    this.zoneService.notes(this.zone._id).subscribe(notes => {
      this.isLoading = false;
      this.notes = notes.notes as Note[];
      this.notes.reverse();
    })
  }

  openAddEditNoteDialog(note?: Note) {
    const dialogRef = this.dialog.open(AddNoteDialogComponent, {
      disableClose: true,
      data: { zoneId: this.zone._id, note: note }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === undefined) return;
      this.notes.unshift(result);
    });
  }

  openConfirmDialog(note: Note) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.zoneService.deleteNote(this.zone._id, note._id).subscribe(result => {
          this.notes.splice(this.notes.findIndex(x => x._id === note._id), 1);
        })
      }
    })
  }

  previewAttachment(note: Note, attachment: Attachment) {
    this.isAttachmentOverlayOpen = true;
    this.attachmentUrl = `/zone/${this.zone._id}/note/${note._id}/attachment/${attachment.filename}`;
    this.isLoadingAttachmentImage = true;
  }

  onImageLoad() {
    this.isLoadingAttachmentImage = false;
  }

  getAttachmentThumbnail(note: Note, attachment: Attachment) {
    return `/zone/${this.zone._id}/note/${note._id}/attachment/${attachment.filename}?t=1`;

  }
}
