import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SensorRoutingModule } from './sensor-routing.module';
import { SensorListComponent } from './sensor-list/sensor-list.component';
import { SensorAlertSettingComponent } from './sensor-alert-setting/sensor-alert-setting.component';
import { SensorComponent } from './sensor/sensor.component';
import { SensorSettingsComponent } from './sensor-settings/sensor-settings.component';
import { SensorReportsComponent } from './sensor-reports/sensor-reports.component';
import { SharedModule } from '../../modules/shared.module';
import { SensorCalibrationComponent } from './sensor-calibration/sensor-calibration.component';
import { CalibrationDialogComponent } from './sensor-calibration/calibration-dialog/calibration-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditSensorNameDialogComponent } from './edit-sensor-name-dialog/edit-sensor-name-dialog.component';

@NgModule({
  declarations: [
    SensorListComponent,
    SensorAlertSettingComponent,
    SensorComponent,
    SensorSettingsComponent,
    SensorReportsComponent,
    SensorCalibrationComponent,
    CalibrationDialogComponent,
    EditSensorNameDialogComponent
  ],
  imports: [
    CommonModule,
    SensorRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
  entryComponents: [
    CalibrationDialogComponent,
    EditSensorNameDialogComponent
  ],
})
export class SensorModule { }
