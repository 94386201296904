import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-name-dialog',
  templateUrl: './edit-name-dialog.component.html',
  styleUrls: ['./edit-name-dialog.component.less']
})
export class EditNameDialogComponent implements OnInit {

  isLoading: boolean = false;

  constructor(public dialogRef: MatDialogRef<EditNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
  }

  onConfirm() {
    if (this.data.name?.length === 0) {
      return;
    }
    this.isLoading = true;

    this.data.function(this.data.name).subscribe(
      result => {
        this.dialogRef.close(this.data);
      }, complete => {
        this.isLoading = false;
      }
    )
  }

}
