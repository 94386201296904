import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { ZoneOverviewComponent } from './zone-overview/zone-overview.component';
import { OverviewBarComponent } from './zone-overview/overview-bar/overview-bar.component';
import { ZoneComponent } from './zone/zone.component';
import { AppliancesOverviewComponent } from './appliances-overview/appliances-overview.component';
import { AppliancesListComponent } from './appliances-list/appliances-list.component'
import { OverviewComponent } from './overview/overview.component';
import { ZoneRoutingModule } from './zone-routing.module';
import { ApplianceRuleListComponent } from './appliance-rule-list/appliance-rule-list.component';
import { RuleDialogComponent } from './rule-dialog/rule-dialog.component';
import { AlertSettingsComponent } from './alert-settings/alert-settings.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { NotesComponent } from './notes/notes.component';
import { AddNoteDialogComponent } from './add-note-dialog/add-note-dialog.component';
import { SharedModule } from 'src/app/modules/shared.module';
import { ZoneSettingsComponent } from './zone-settings/zone-settings.component';
import { SensorOverviewComponent } from './sensor-overview/sensor-overview.component';
import { CustomTimeInputField } from './rule-dialog/custom-time-input-field.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PairingDialogComponent } from './zone-settings/pairing-dialog/pairing-dialog.component';

@NgModule({
  declarations: [
    ZoneOverviewComponent,
    OverviewBarComponent,
    ZoneComponent,
    AppliancesOverviewComponent,
    AppliancesListComponent,
    OverviewComponent,
    ApplianceRuleListComponent,
    RuleDialogComponent,
    AlertSettingsComponent,
    AlertDialogComponent,
    NotesComponent,
    AddNoteDialogComponent,
    ZoneSettingsComponent,
    SensorOverviewComponent,
    CustomTimeInputField,
    PairingDialogComponent
  ],
  imports: [
    CommonModule,
    ZoneRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
  entryComponents: [
    RuleDialogComponent,
    AlertDialogComponent,
    AddNoteDialogComponent,
    PairingDialogComponent
  ],
  exports: [ZoneOverviewComponent]
})
export class ZoneModule { }
