import { Component, OnDestroy, OnInit } from '@angular/core';
import { Sensor, excludedSensorTypes, IValue, ApplianceTypes } from 'src/app/models/sensor';
import { Router, ActivatedRoute } from '@angular/router';
import { Zone } from 'src/app/models/zone';
import { Appliance } from 'src/app/models/appliance';
import { DataService } from 'src/app/services/data.service';
import { SocketIoService } from 'src/app/services/socket-io.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';


export interface Category {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.less']
})
export class OverviewComponent implements OnInit, OnDestroy {
  zone: Zone;
  appliances: Appliance[] = [];
  sensors: Sensor[] = [];
  categories: Category[] = [];
  subscription: Subscription;
  constructor(private dataService: DataService,
    private socketService: SocketIoService) {

    this.dataService.sharedZone$.pipe(take(1)).subscribe(zone => {
      if (zone === undefined) {
        return;
      }
      this.zone = zone;
      this.sensors = zone.sensors.filter(x => !ApplianceTypes.includes(x.kind));
      this.sensors.forEach(x => x.kind = x.kind == 's1' ? 'sky' : x.kind);
      this.sensors.forEach(x => {
        x.lastValues = x.lastValues?.filter(v =>
          !excludedSensorTypes.includes(v.value?.type)
        )
      })
      this.appliances = Appliance.reduceAppliances(this.zone.sensors, this.zone.online);
      this.zone.appliances = this.appliances;
    })

    this.categories = [
      { value: 'sky', viewValue: '#Enviroment' },
      { value: 'root', viewValue: '#RootZone' },
      { value: 'water', viewValue: '#Irrigation' },
      { value: '', viewValue: '#General' }];

    this.subscription = this.socketService.sensorValueMessages$.subscribe((data) => {

      if (data === null) {
        return;
      }
      if (data.type === 'state') {
        let _appliance = this.appliances.find(x => x.sensorId === data.id);
        if (_appliance === undefined) {
          return;
        }
        _appliance.value = data.value;
        _appliance.lastPing = new Date();
      }

      if (data.type === 'status') {
        let _appliance = this.appliances.find(x => x.sensorId === data.id);
        if (_appliance === undefined) {
          return;
        }
        _appliance.lastPing = new Date();
        _appliance.status = data.value === 1;
      }
    })
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  updateCurrentZone(zone: Zone) {
    this.dataService.updateZone(zone);
  }

}
