import { Component, OnInit } from '@angular/core';
import * as ct from 'countries-and-timezones';
import { SettingsService, defaultSettings, Settings, MeasureUnit, Temperature, Volume, Length, Weight } from '../../services/settings.service';
import { MatRadioChange } from '@angular/material/radio';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.less']
})
export class SettingsComponent implements OnInit {
  timezoneOptions: any;
  settings: Settings = defaultSettings;

  constructor(public settingsService: SettingsService) {
    let countries = ct.getAllTimezones();
    this.timezoneOptions = Object.values(countries).map(({ utcOffsetStr, name, utcOffset, country }) => {
      return { label: `${name.replace("_", " ")} (GMT ${utcOffsetStr})`, value: utcOffset / 60 }
    })

    Object.keys(this.settings).forEach(k => {
      this.settings[k] = this.settingsService.getSetting(k)
    })
  }

  ngOnInit(): void {

  }

  settingChanged(k: string, event: MatRadioChange) {
    this.settingsService.setSetting(k, event.value)
  }

  onMeasureUnitChange(event: MatRadioChange) {
    if (event.value as MeasureUnit === 'metric') {
      this.settings.temperature = 'celsius'
      this.settings.volume = 'liter'
      this.settings.length = 'meter'
      this.settings.weight = 'gram'
    } else if (event.value as MeasureUnit === 'imperial') {
      this.settings.temperature = 'fahrenheit'
      this.settings.volume = 'gallon'
      this.settings.length = 'feet'
      this.settings.weight = 'ounce'
    }

    Object.keys(this.settings).forEach(o => {
      this.settingsService.setSetting(o, this.settings[o]);
    })

  }

}