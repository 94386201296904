<div class="page-container">
    <div class="page-header" *ngIf="sensor">
        <span>{{sensor.name}}</span>
        <button readOnly [hide]="true" mat-button mat-icon-button onclick="this.blur()" (click)="editSensorName()">
            <mat-icon>
                edit
            </mat-icon>
        </button>
    </div>
    <nav mat-tab-nav-bar>
        <a mat-tab-link
           *ngFor="let link of navLinks"
           [routerLink]="link.link"
           routerLinkActive #rla="routerLinkActive"
           [active]="rla.isActive">
            {{link.label}}
        </a>
    </nav>
    <div class="container" *ngIf="sensor">
        <router-outlet></router-outlet>
    </div>
</div>