import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.less']
})
export class SideMenuComponent implements OnInit {
  @Input() isShowMenu: boolean = false;
  @Output() closeMenu = new EventEmitter();
  currentApplicationVersion = environment.appVersion;

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
  }

}
