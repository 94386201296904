<mat-card class="card">
    <div class="row">
        <div class="title">Assign Sensor To Zone</div>
        <mat-form-field appearance="none">
            <mat-select [(value)]="selectedZoneId"
                        (selectionChange)="onZoneSelect($event)">
                <mat-option [value]="_zone._id"
                            *ngFor="let _zone of zones">
                    {{ _zone.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</mat-card>