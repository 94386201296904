<div class="container">
    <mat-card>
        <div class="row">
            <mat-form-field appearance="legacy">
                <mat-label>Choose Parameter</mat-label>
                <mat-select multiple [(ngModel)]="selectedTypes">
                    <mat-option *ngFor="let sensor of sensorTypeOptions" [value]="sensor">
                        {{sensor | sensorDisplayName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="legacy">
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input [rangePicker]="picker" [disabled]="selectedTypes.length === 0">
                    <input matStartDate placeholder="Start date" [(ngModel)]="selectedRange.from">
                    <input matEndDate placeholder="End date" [(ngModel)]="selectedRange.to">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
        <div class="apply-btn">
            <button mat-flat-button [disabled]="selectedTypes.length === 0" color="primary" class="align-right"
                    style="margin-right: 8px;"
                    (click)="exportToCSV()">Export to CSV
                <mat-icon matSuffix>
                    file_download
                </mat-icon>
            </button>
            <button mat-flat-button [disabled]="selectedTypes.length === 0" color="primary" class="align-right"
                    (click)="fetchData()">Apply</button>

        </div>
    </mat-card>


    <div class="charts-container">
        <apx-chart
                   [colors]="chartOptions.colors"
                   [series]="chartOptions.series"
                   [chart]="chartOptions.chart"
                   [xaxis]="chartOptions.xaxis"
                   [markers]="chartOptions.markers"
                   [stroke]="chartOptions.stroke"
                   [yaxis]="chartOptions.yaxis"
                   [dataLabels]="chartOptions.dataLabels"
                   [title]="chartOptions.title"
                   [fill]="chartOptions.fill"
                   [tooltip]="chartOptions.tooltip"
                   [legend]="chartOptions.legend"></apx-chart>

    </div>



    <mat-table [dataSource]="splicedDataSource" class="mat-elevation-z8" *ngIf="dataSource.length > 0">

        <ng-container [matColumnDef]="item" *ngFor="let item of displayedColumns">
            <ng-container *ngIf="item === 'date'; else regular">
                <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
            </ng-container>
            <ng-template #regular>
                <mat-header-cell *matHeaderCellDef>{{item | sensorDisplayName}}</mat-header-cell>
            </ng-template>
            <ng-container *ngIf="item === 'date'; else value">
                <mat-cell *matCellDef="let element"> {{element[item] | date:'dd-MM-yyyy HH:mm'}}</mat-cell>
            </ng-container>
            <ng-template #value>
                <mat-cell *matCellDef="let element">{{element[item]}} {{item | sensorFormat}}</mat-cell>
            </ng-template>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator [length]="dataSource.length"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   (page)="pageChangeEvent($event)">
    </mat-paginator>

</div>

<div class="loading-wrapper" *ngIf="isLoading">
    <mat-spinner diameter="30"></mat-spinner>
</div>