import { Pipe, PipeTransform } from '@angular/core';
import { SensorType } from '../models/sensor';
import { SettingsService, Temperature } from '../services/settings.service';

@Pipe({
  name: 'sensorValue'
})
export class SensorValuePipe implements PipeTransform {
  constructor(private settingsService: SettingsService) { }

  transform(value: number, sensorType: SensorType): number {
    switch (sensorType) {
      case 'temperature':
        {
          let format = this.settingsService.getSetting('temperature') as Temperature;
          if (format === 'fahrenheit') {
            return (value * 9 / 5) + 32
          }
        }
        break;
      case 'depth':
        {
          let format = this.settingsService.getSetting('length');
          if (format === 'feet') {
            if (value != undefined) {
              return value * 3.281;
            }
          }
        }
        break;
      case 'con':
        {
          return value / 1000
        }
        break;
    }
    return value;
  }

}
