import { Alert } from './zone';
import { SENSOR_TYPE_SORT_ORDER } from './constants';
import { Appliance } from './appliance';

export type SensorType = "temperature" | "humidity" | "hpa" | "lux" | "depth" | "orp" | "watt" | "tds" | "con" | "ph" | "rhs" | "lqi" | "co2" | "rssi" | "wdo" | "uv" | "vpd" | "ecs" | "power" | "state" | "battery" | "relay-temperature" | "ethylene" | "flow" | "pressure" | "control-mode" | "status";

export const excludedSensorTypes: SensorType[] = ["uv", "orp"];

export type SensorKind = 'sky' | 'root' | 'water' | 'shelly' | 'pdu' | 's1' | 'waterstation' | 'fertilizerpump' | 'shelly-dimmer' | 'shelly-crank' | 'broadlinkir' | 'schneider-relay' | 'schneider-crank' | 'plc-relay' | 'plc-crank';

export const ApplianceTypes: SensorKind[] = ['shelly', 'pdu', 'waterstation', 'fertilizerpump', 'shelly-dimmer', 'shelly-crank', 'broadlinkir', 'schneider-crank', 'schneider-relay', 'plc-crank', 'plc-relay'];

export const ApplianceCrankTypes: SensorKind[] = ['shelly-crank', 'schneider-crank', 'plc-crank'];
export const ApplianceSliderTypes: SensorKind[] = ['shelly-dimmer', 'shelly-crank', 'schneider-crank', 'plc-crank'];

export const ApplianceToggleTypes: SensorKind[] = ['shelly', 'pdu', 'waterstation', 'fertilizerpump', 'schneider-relay', 'plc-relay'];
export const SensorTypes = {
    'sky': <SensorType[]>[
        'temperature', 'humidity', 'co2', 'hpa', 'uv', 'lux', 'rssi'
    ],
    'root': <SensorType[]>[
        'temperature', 'rhs', 'ecs'
    ],
    'water': <SensorType[]>[
        'temperature', 'ph', 'tds', 'con', 'depth', 'wdo', 'orp', 'flow', 'pressure'
    ],
    'pdu': <SensorType[]>[
        'watt', 'state', 'status'
    ],
    'shelly': <SensorType[]>[
        'watt', 'state', 'status'
    ],
    'schneider-relay': <SensorType[]>[
        'state', 'status', 'control-mode'
    ],
    'waterstation': <SensorType[]>[
        'state', 'status'
    ],
    'fertilizerpump': <SensorType[]>[
        'state', 'status'
    ],
    'shelly-dimmer': <SensorType[]>[
        'state'
    ],
    'shelly-crank': <SensorType[]>[
        'state', 'status', 'control-mode'
    ],
    'schneider-crank': <SensorType[]>[
        'state'
    ],
    'plc-crank': <SensorType[]>[
        'state', 'status'
    ],
    'plc-relay': <SensorType[]>[
        'state', 'status', 'control-mode'
    ],
    's1': <SensorType[]>[
        'temperature', 'humidity', 'rssi', 'battery'
    ],
    'broadlinkir': <SensorType[]>[
        'state'
    ],
    'daikinac': <SensorType[]>[
        'state', 'status'
    ],
}

export class Sensor {
    _id?: string;
    name?: string;
    types?: SensorType[];
    lastValues?: ISensorValue[];
    action: Appliance;
    kind: SensorKind;
    forks?: Fork[];
    zone?: string;
    alerts?: Alert[];

    constructor(name: string, _id: string) {
        this.name = name;
        this._id = _id;
    }

    public static breakdownSensors(sensors: Sensor[], alerts: Alert[]) {
        let temp = sensors.filter(x => !ApplianceTypes.includes(x.kind)).reduce((acc, item) => {
            item.lastValues?.forEach(x => {
                x.sensorName = item.name;
                x.id = item._id;
                x.kind = item.kind
            })
            acc = [...acc, ...item.lastValues || []];
            return acc;
        }, []).filter(x => !excludedSensorTypes.includes(x.value?.type)).reduce((acc, item: ISensorValue) => {
            let value = { value: item.value?.value, id: item.id, sensorName: item.sensorName }
            let type = item.value?.type
            let kind = item.kind === 's1' ? 'sky' : item.kind;
            let indexOfKind = acc.findIndex(x => x.kind === kind);
            if (indexOfKind > -1) {
                let indexOfType = acc[indexOfKind].values.findIndex(x => x.type === type);
                if (indexOfType > -1) {
                    acc[indexOfKind].values[indexOfType].values.push(value);
                } else {
                    acc[indexOfKind].values.push({ type: type, values: [value] })
                }
            } else {
                acc.push({ kind: kind, values: [{ type: type, values: [value] }] })
            }
            return acc;
        }, []);

        alerts?.forEach(alert => {
            let index = temp.findIndex(x => x.type === alert.sensorType)
            if (index > -1) {
                temp[index].alert = alert;
            }
        })

        var ordering = {};
        var sortOrder: SensorType[] = ['temperature', 'humidity', 'vpd', 'ecs', 'ph', 'flow', 'pressure', 'wdo', 'depth', 'tds', 'co2', 'hpa', 'lux', 'rssi', 'battery'];
        sortOrder.map((value, index) => ordering[value] = index);


        temp.forEach(x => {
            x.values.sort((a, b) => {
                return (ordering[a?.type] - ordering[b?.type]);
            })
        })

        return temp;
    }

    public static reduceSensorAlerts(sensors: Sensor[], alerts: Alert[]) {
        let temp: ReducedSensor[] = sensors.filter(x => !ApplianceTypes.includes(x.kind))
            .reduce((acc, item) => {
                SensorTypes[item.kind]?.forEach(type => {
                    if (acc.findIndex(x => x.type === type) === -1) {
                        acc.push({ type: type })
                    }
                })
                return acc;
            }, []).filter(x => !excludedSensorTypes.includes(x.type));

        alerts?.forEach(alert => {
            let index = temp.findIndex(x => x.type === alert.sensorType)
            if (index > -1) {
                temp[index].alert = alert;
            }
        })

        var ordering = {};
        var sortOrder: SensorType[] = ['temperature', 'humidity', 'vpd', 'ecs', 'ph', 'flow', 'pressure', 'wdo', 'depth', 'tds', 'co2', 'hpa', 'lux', 'rssi', 'battery'];
        sortOrder.map((value, index) => ordering[value] = index);

        temp.sort((a, b) => {
            return (ordering[a?.type] - ordering[b?.type])
        })

        return temp;
    }

    public static reduceApplianceAlerts(sensors: Sensor[], alerts: Alert[], types: SensorType[]) {
        let temp: ReducedSensor[] = sensors.filter(x => ApplianceTypes.includes(x.kind))
            .reduce((acc, item) => {
                SensorTypes[item.kind]?.filter(type => types.includes(type)).forEach(type => {
                    if (acc.findIndex(x => x.type === type) === -1) {
                        acc.push({ type: type })
                    }
                })
                return acc;
            }, []).filter(x => !excludedSensorTypes.includes(x.type));

        alerts?.forEach(alert => {
            let index = temp.findIndex(x => x.type === alert.sensorType)
            if (index > -1) {
                temp[index].alert = alert;
            }
        })

        var ordering = {};
        var sortOrder: SensorType[] = ['temperature', 'humidity', 'vpd', 'ecs', 'ph', 'flow', 'pressure', 'wdo', 'depth', 'tds', 'co2', 'hpa', 'lux', 'rssi', 'battery'];
        sortOrder.map((value, index) => ordering[value] = index);

        temp.sort((a, b) => {
            return (ordering[a?.type] - ordering[b?.type])
        })

        return temp;
    }

    public static sensorTypes(reducedSensors: ReducedSensor[]) {
        return reducedSensors.map(x => x.type)
    }

    public static calculateVPD(sensor: Sensor) {
        try {
            let temperature = sensor.lastValues?.find(x => x.value.type === 'temperature')?.value?.value[0];
            let svp = 610.78 * Math.pow(2.71828, (temperature / (temperature + 238.3) * 17.2694));
            let humidity = sensor.lastValues?.find(x => x.value.type === 'humidity')?.value?.value[0];
            let vpd = svp * (1 - humidity / 100);
            let vpdValue: ISensorValue = { value: { type: 'vpd', value: [vpd / 1000] } }
            if (!Number.isNaN(vpd)) {
                let vpdIndex = sensor.lastValues.findIndex(x => x.value.type === 'vpd');
                if (vpdIndex > -1) {
                    sensor.lastValues[vpdIndex] = vpdValue
                } else {
                    sensor.lastValues.push(vpdValue);
                }
            }
            return sensor;
        } catch (error) {
            console.error('fail vpd calculate', error);

            return sensor;
        }
    }

    public static forks(rootSensor: Sensor) {
        let forks: Fork[] = [];
        let forkLength = rootSensor?.lastValues?.[0]?.value?.value?.length;
        for (var i = 0; i < forkLength; i++) {
            let values: IValue[] = [];
            rootSensor.lastValues.forEach(v => {
                values.push({ type: v.value.type, value: [v.value.value[i]] })
            })
            let fork = { name: `Fork ${i}`, values: values }
            forks.push(fork);
        }
        return forks;
    }
}

export class ReducedSensor {
    type?: string;
    values?: ISensorValue[];
    alert?: Alert;
    kind?: SensorKind;
}

export interface ISensorValue {
    date?: Date,
    value: IValue,
    sensorName?: string,
    id?: string
    displayedValue?: string,
    kind?: SensorKind,
    type?: SensorType
}

export interface IValue {
    date?: Date,
    type: SensorType,
    value: number[]
}

export interface Fork {
    name?: string;
    values?: IValue[];
}
