<div class="page-container">
    <div class="page-header">Dashboard</div>
    <div class="zones-container">
        <app-zone-overview class="zone"
                           *ngFor="let zone of zones"
                           [zone]="zone"
                           [showArrow]="true"></app-zone-overview>

    </div>
    <div class="loading-wrapper-top">
        <mat-spinner diameter="30" *ngIf="zones.length === 0"></mat-spinner>
    </div>
</div>