import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ZoneService } from 'src/app/services/zone.service';
import { Zone, Alert } from 'src/app/models/zone';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { Category } from '../overview/overview.component';
import { Sensor, ReducedSensor, ISensorValue, SensorType } from 'src/app/models/sensor';
import * as moment from 'moment';

@Component({
  selector: 'app-zone-overview',
  templateUrl: './zone-overview.component.html',
  styleUrls: ['./zone-overview.component.less']
})
export class ZoneOverviewComponent implements OnInit {

  @Input() zone: Zone;
  @Input() showArrow: boolean = false;
  @Output() onSensorOpen = new EventEmitter;
  categories: any;
  growCycleCount = {
    week: {} as number,
    day: {} as number
  }

  constructor(private router: Router, private dataService: DataService) {
    this.categories = {
      'sky': '#Enviorment',
      'root': '#Rootzone',
      'water': '#irrigation'
    }
  }

  ngOnInit(): void {
    if (this.zone.activeCycle) {
      let dt1 = new Date(this.zone.activeCycle.currentState.dates.start)
      var diff = (new Date().getTime() - dt1.getTime()) / 1000;
      let weekDiff = Math.abs(Math.round(diff / (60 * 60 * 24 * 7)));
      let dayDiff = Math.abs(Math.round(diff / (60 * 60 * 24)));
      this.growCycleCount.week = weekDiff + 1;
      this.growCycleCount.day = dayDiff + 1;
    }


  }

  viewZone() {
    this.dataService.updateZone(this.zone);
    this.router.navigate(['zone', this.zone._id, 'overview']);
  }

  isOffRange(value: number, type: SensorType) {
    let alert: Alert = this.zone.alerts?.find(x => x.sensorType === type)

    return alert === undefined ? false :
      value > alert.day?.max || value < alert.day?.min
  }

  onPointClick(mouseEvent: MouseEvent, value: ISensorValue, type: SensorType) {
    // let sensor_: Sensor = { _id: value.id}
    this.onSensorOpen.emit();
    this.dataService.updateSensor(null);
    this.router.navigate(['sensor', value.id, 'reports'], { state: { type: type } });
  }



}
