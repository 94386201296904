<div class="bar-header">
    <div class="icon-with-title">
        <div class="icon-box">
            <img src="./assets/images/{{sensor.type}}.svg" class="icon"
                 fallback="./assets/images/not_found.svg">
        </div>
        <span class="title">{{sensor.type | sensorDisplayName}}</span>
    </div>
    <span class="sensor-format">{{sensor.type | sensorFormat}}</span>
</div>
<div class="bar-container">
    <div class="bar"></div>
    <div class="point"
         *ngFor="let item of sensor.values"
         [style.left.%]="((item.value - minValue) / range) * 100"
         [ngClass]="{'error': isOffRange(item.value)}"
         (click)="onPointClick($event, item)"
         [matTooltip]="item.sensorName + ' - ' + (item.value | number:'1.0-2') + (sensor.type | sensorFormat)">
    </div>

    <span class="value" *ngFor="let item of sensor.values"
          [style.left.%]="((item.value - minValue) / range) * 100">{{item.value | number:'1.0-2'}}</span>

    <div class="axis-labels">
        <span>{{minValue | number:'1.0-2'}}</span>
        <span>{{maxValue | number:'1.0-2'}}</span>
    </div>
</div>