import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

// Available log levels
export enum LogLevel {
    Debug,
    Info,
    Warning,
    Error,
    None
}

/* Logger service
This logger support multi-session for more readable console messages.
*/
@Injectable({ providedIn: 'root' })
export class Logger {

    // Take level from environment configuration
    public static Level: LogLevel = LogLevel[environment.logLevel];

    private _parentLogger: Logger;
    private _session: string;

    constructor() { }

    // Pad small numbers with zeros
    private pad(number: number): string {
        return number.toString().padStart(2, '0');
    }

    // Get current date, formatted
    private getDate() {
        let date = new Date();
        return `${this.pad(date.getDate())}/${this.pad(date.getMonth() + 1)}/${this.pad(date.getFullYear())} ${this.pad(date.getHours())}:${this.pad(date.getMinutes())}:${this.pad(date.getSeconds())}.${date.getMilliseconds()}`;
    }

    // The actual message logging function
    private logMessage(level: LogLevel, message: any, session: string[] = [], ...args: any) {
        // Requested level too low, skip this message
        if (level < Logger.Level) {
            return;
        }

        // Parent logger exists, send current session name and call parent method
        if (this._parentLogger) {
            this._parentLogger.logMessage(level, message, [this._session, ...session], ...args);
            return;
        }

        // Get the required console method
        let logFunc = console.info;
        switch (level) {
            case LogLevel.Debug:
                logFunc = console.debug;
                break;
            case LogLevel.Info:
                logFunc = console.info;
                break;
            case LogLevel.Warning:
                logFunc = console.warn;
                break;
            case LogLevel.Error:
                logFunc = console.error;
                break;
        }

        // Build the log message and write to console
        let sessionString = session.length > 0 ? `[${session.join('.')}]` : '';
        let logFuncArgs = [ `${this.getDate()} ${LogLevel[level]} ${sessionString}` ];
        if (typeof message === 'string') {
            logFuncArgs[0] += ` ${message}`;
        } else {
            logFuncArgs.push(message);
        }
        logFuncArgs.push(...args);

        logFunc.apply(window, logFuncArgs);
    }

    // Create a new session (sub-module)
    getSession(name: string) {
        let logger = new Logger();
        logger._parentLogger = this;
        logger._session = name;
        return logger;
    }

    debug(message: string, ...args: any) {
        this.logMessage(LogLevel.Debug, message, [], ...args);
    }

    info(message: string, ...args: any) {
        this.logMessage(LogLevel.Info, message, [], ...args);
    }

    warn(message: string, ...args: any) {
        this.logMessage(LogLevel.Warning, message, [], ...args);
    }

    error(message: string, ...args: any) {
        this.logMessage(LogLevel.Error, message, [], ...args);
    }
}