<h2>Sensor Calibration</h2>
<form [formGroup]="firstFormGroup">
    <ng-template matStepLabel>Choose Calibration Type</ng-template>
    <mat-form-field>
        <mat-label>Choose Calibration Type</mat-label>
        <mat-select [(ngModel)]="selectedCalibrationOption" formControlName="firstCtrl" required>
            <mat-option *ngFor="let option of calibrationOptions" [value]="option">{{option.viewValue}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div>
        <button mat-flat-button color="primary"
                (click)="startCalibration()"
                [disabled]="selectedCalibrationOption === undefined">Start
            Calibration</button>
    </div>
    <ng-container *ngIf="calibrationStarted">
        <div class="progress-bar">
            <mat-progress-bar [mode]="'indeterminate'" [value]="remainingTimePercentage"></mat-progress-bar>
            <!-- <div>
                Estimated remaining time: {{ remainingTime | date:'mm:ss' }}
            </div> -->
        </div>
        <div>
            {{ calibrationValue.length == 0 ?
            "Calibration Started, Please wait..." :
            "Calibration Value: "+calibrationValue }}
        </div>
    </ng-container>
</form>


<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>