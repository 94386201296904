export const SensorNames = {
    temperature: "Temperature",
    humidity: "Humidity",
    hpa: "Atm",
    lux: "Light",
    depth: "Depth",
    orp: "Orp",
    watt: "WATT",
    tds: "TDS",
    con: "EC",
    ph: "pH",
    rhs: "Root RH",
    lqi: "LQI",
    co2: "CO2",
    rssi: "RSSI",
    wdo: "Water DO",
    uv: "UV",
    vpd: "VPD",
    ecs: "Root EC",
    power: "Power",
    battery: "Battery",
    ethylene: "Ethylene",
    flow: "Water Flow",
    pressure: "Water Pressure",
    status: "Status"
}