import { Component, OnInit, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { NotificationsComponent } from '../notifications/notifications.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.less']
})
export class TopBarComponent implements OnInit {
  @Output() showMenu = new EventEmitter();
  isProfileOverlayShowing: boolean = false;
  user: string;

  @HostListener('document:click', ['$event'])
  handleWindowClick(event: MouseEvent) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      if (this.isProfileOverlayShowing) {
        this.isProfileOverlayShowing = false;
      }
    }
  }

  constructor(private authService: AuthService,
    public dialog: MatDialog,
    private userService: UserService,
    private eRef: ElementRef
  ) {
    this.user = localStorage.getItem('user');
  }

  ngOnInit(): void {
  }

  logout() {
    this.userService.logout();
  }

  openNotificationsDialog() {
    const dialogRef = this.dialog.open(NotificationsComponent,
      {
        height: '100vh',
        maxHeight: '100vh',
        panelClass: 'notifications',
        hasBackdrop: true
      });
  }

}
