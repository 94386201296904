<h2>Sensor Pairing</h2>
<mat-vertical-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="firstFormGroup" [editable]="!pairingStarted">
        <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel>Choose Sensor Kind</ng-template>
            <mat-form-field>
                <mat-label>Choose Sensor Kind</mat-label>
                <mat-select [(ngModel)]="sensorKind" formControlName="firstCtrl" required>
                    <mat-option [value]="'sky'">Enviorment</mat-option>
                    <mat-option [value]="'root'">Rootzone</mat-option>
                    <mat-option [value]="'water'">Irrigation</mat-option>
                </mat-select>
            </mat-form-field>
            <div>
                <button mat-flat-button color="primary" matStepperNext>Next</button>
            </div>
        </form>
    </mat-step>
    <mat-step [completed]="pairingEnded" [editable]="false">
        <ng-template matStepLabel>Connect sensor to gateway</ng-template>
        <p>
            Please locate your gateway and physically connect your sensor to it.
            Once you are ready, press the start button in order to start
            pairing process.
        </p>
        <div>
            <button mat-button matStepperPrevious>Back</button>
            <button mat-flat-button color="primary"
                    (click)="startPairing()"
                    [disabled]="pairingStarted">Start Pairing</button>
        </div>
        <ng-container *ngIf="pairingStarted">
            <div class="progress-bar">
                <mat-progress-bar [mode]="progressBarMode" [value]="remainingTimePercentage"></mat-progress-bar>
                <div>
                    Estimated remaining time: {{ remainingTime | date:'mm:ss' }}
                </div>
            </div>
        </ng-container>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Done</ng-template>
        <p *ngIf="pairingSucceeded; else pairingFailed">
            Sensor {{sensor.name}} Successfully paired. <span class="link" (click)="openSensor()">View sensor</span></p>
        <ng-template #pairingFailed>
            <p>Sensor Pairing Failed, try again.</p>
        </ng-template>
        <div>
            <button mat-button mat-flat-button color="primary" (click)="resetStepper()">Start Over</button>
        </div>
    </mat-step>
</mat-vertical-stepper>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>