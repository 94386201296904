import { STEP_STATE } from '@angular/cdk/stepper';
import { Inject, OnDestroy } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { MatStepper } from '@angular/material/stepper';
import { Sensor, SensorKind, SensorType } from 'src/app/models/sensor';
import { SensorService } from 'src/app/services/sensor.service';
import { SocketIoService } from 'src/app/services/socket-io.service';

@Component({
  selector: 'app-calibration-dialog',
  templateUrl: './calibration-dialog.component.html',
  styleUrls: ['./calibration-dialog.component.less']
})
export class CalibrationDialogComponent implements OnInit, OnDestroy {
  // sensorType: SensorType;
  calibrationStarted: boolean = false;
  calibrationEnded: boolean = false;
  calibrationSucceeded: boolean = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  remainingTime: number = 2 * 60 * 1000;
  countdownInterval: any;
  pullingInterval: any;
  progressBarMode: ProgressBarMode = 'determinate';
  calibrationOptions: any;
  selectedCalibrationOption: any;
  zoneId: string;
  sensorId: string;
  calibrationValue: string = "";

  constructor(private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CalibrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private socketService: SocketIoService,
    private sensorService: SensorService) {
    // this.sensorType = data.sensorType;
    this.zoneId = data.zoneId;
    this.sensorId = data.sensorId;
    this.calibrationOptions = data.calibrationOptions;
  }

  @ViewChild('stepper') stepper: MatStepper;

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
  }

  ngOnDestroy() {
    this.socketService.closeSocketConnection();
  }

  startCalibration() {
    if (this.selectedCalibrationOption === undefined) {
      return;
    }

    this.calibrationStarted = true;

    this.sensorService.sensorCalibration(this.sensorId, this.selectedCalibrationOption.value).subscribe(result => {
      this.pullingInterval = setInterval(() => {
        this.pullSensorData();
      }, 10 * 1000);
    })


  }

  pullSensorData() {
    this.sensorService.sensor(this.sensorId).subscribe(result => {
      let sensor = result as Sensor;
      let resultValue = sensor.lastValues.find(x => x.type == this.selectedCalibrationOption.sensorType);
      if (resultValue) {
        try {
          this.calibrationValue = resultValue.value[0];
        } catch (e) {
          console.log(e);
        }
      }

    })
  }

  get remainingTimePercentage() {
    let result = 100 - (this.remainingTime / (2 * 60 * 1000)) * 100
    return result;
  };

  resetStepper() {
    this.calibrationStarted = false;
    this.calibrationEnded = false;
    clearInterval(this.countdownInterval);
    this.remainingTime = 2 * 60 * 1000;
    this.progressBarMode = 'determinate';
    this.stepper.reset();
  }

  closeDialog() {
    clearInterval(this.pullingInterval);
    this.dialogRef.close();
  }

  startListeningCalibrationSocket(callback) {
    this.socketService.joinGatewayUpdates(this.zoneId);
    this.socketService.listenCalibrateStatus((result) => {
      callback(result);
    });

  }



}
