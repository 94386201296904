import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { finalize } from 'rxjs/operators';

export interface ISensor {
  id: string,
  name: string
};

@Component({
  selector: 'app-sensor-list',
  templateUrl: './sensor-list.component.html',
  styleUrls: ['./sensor-list.component.css']
})
export class SensorListComponent implements OnInit {

  loading: boolean = true;
  sensors: ISensor[];

  constructor(private _activatedRoute: ActivatedRoute, private _api: ApiService) { }

  ngOnInit() {
    this._activatedRoute.queryParams.subscribe(queryParams => {
      this._api.get<ISensor[]>('/sensors').pipe(
        finalize(() => this.loading = false)
      ).subscribe(sensors => this.sensors = sensors);
    });
  }
}
