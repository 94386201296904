import { Component, OnInit, Input } from '@angular/core';
import { Sensor, ReducedSensor, ISensorValue, IValue } from 'src/app/models/sensor';
import { IRange } from 'src/app/models/zone';
import { Router } from '@angular/router';
import { Appliance } from 'src/app/models/appliance';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-overview-bar',
  templateUrl: './overview-bar.component.html',
  styleUrls: ['./overview-bar.component.less']
})
export class OverviewBarComponent implements OnInit {

  @Input() sensor: any;
  minValue: number;
  maxValue: number;
  range: number;

  constructor(private router: Router) {

  }

  ngOnInit(): void {
    let values = this.sensor.values.map(x => x.value)
    this.minValue = Math.min(...values) - 2
    // this.minValue = this.sensor.alert?.day.min || undefined;
    this.maxValue = Math.max(...values) + 2

    // this.maxValue = this.sensor.alert?.day.max || undefined;

    this.range = this.maxValue - this.minValue;
  }

  isOffRange(value: number) {
    return this.sensor.alert === undefined ? false : value > this.sensor.alert.day?.max || value < this.sensor.alert.day?.min
  }

  onPointClick(mouseEvent: MouseEvent, sensor: ISensorValue) {
    this.router.navigate(['sensor', sensor.id, 'reports']);
  }

}
