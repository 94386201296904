import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Zone, Alert } from 'src/app/models/zone';
import { Router } from '@angular/router';
import { ZoneService } from 'src/app/services/zone.service';
import { SensorPipe } from 'src/app/pipes/sensor-display-name.pipe';
import { not } from '@angular/compiler/src/output/output_ast';
import { SensorFormatPipe } from 'src/app/pipes/sensor-format.pipe';
import { Subscription, interval } from 'rxjs';
import { SocketIoService } from 'src/app/services/socket-io.service';

export class Notification {
  at: Date;
  _id: string;
  sensorType: string;
  value: number[] | number;
  ref: Zone;
  refType: string;
  displayText?: string;

}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.less']
})
export class NotificationsComponent implements OnInit, OnDestroy {

  notifications: Notification[] = [];
  isActionsGroupVisible: boolean = false;
  private subscription: Subscription;

  constructor(public dialogRef: MatDialogRef<NotificationsComponent>,
    private zoneService: ZoneService,
    private sensorDisplayName: SensorPipe,
    private sensorFormat: SensorFormatPipe,
    private socket: SocketIoService) {

    this.subscription = socket.notifications$.subscribe(data => {
      if (data === null) { return }
      let notification = data;
      notification.displayText = this.notificationText(notification);
      this.notifications?.unshift(notification);
    })

  }

  ngOnInit(): void {
    this.fetchNotifications();
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  fetchNotifications() {
    this.zoneService.notifications().subscribe(result => {
      this.notifications = result.reverse();
      this.notifications.forEach(x => x.displayText =
        this.notificationText(x));
    }
    )
  }

  hideNotification(notification: Notification, index: number) {
    this.zoneService.hideNotification(notification._id).subscribe(result => {
      this.notifications.splice(index, 1);
    });
  }

  armNotification(notification: Notification) {
    this.zoneService.armNotification(notification._id).subscribe(result => console.log(result));
  }

  notificationText(notification: Notification): string {
    return `${notification.refType} "${notification.ref.name}" alert: ${this.sensorDisplayName.transform(notification.sensorType)} @ ${notification.value[0]}`;

  }

}
