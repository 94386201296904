import { Inject, OnDestroy } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { Sensor, SensorKind } from 'src/app/models/sensor';
import { DataService } from 'src/app/services/data.service';
import { SocketIoService } from 'src/app/services/socket-io.service';
import { ZoneService } from 'src/app/services/zone.service';


const WAITING_TIME = 1 * 60 * 1000;

@Component({
  selector: 'app-pairing-dialog',
  templateUrl: './pairing-dialog.component.html',
  styleUrls: ['./pairing-dialog.component.less']
})
export class PairingDialogComponent implements OnInit, OnDestroy {
  zoneId: string;
  sensorKind: SensorKind;
  pairingStarted: boolean = false;
  pairingEnded: boolean = false;
  pairingSucceeded: boolean = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  remainingTime: number = WAITING_TIME;
  countdownInterval: any;
  progressBarMode: ProgressBarMode = 'determinate';
  sensor: Sensor;



  constructor(private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PairingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private socketService: SocketIoService,
    private zoneService: ZoneService,
    private dataService: DataService,
    private router: Router) {
    this.zoneId = data.zoneId;
  }

  @ViewChild('stepper') stepper: MatStepper;

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
  }

  ngOnDestroy() {
    this.socketService.closeSocketConnection();
  }

  startPairing() {
    if (this.sensorKind === undefined) {
      return;
    }

    this.pairingStarted = true;

    this.countdownInterval = setInterval(() => {
      if (this.remainingTime - 1 * 1000 === 0) {
        clearInterval(this.countdownInterval);
        this.progressBarMode = 'indeterminate';
        setTimeout(() => {
          this.pairingEnded = true;
          this.pairingSucceeded = false;
        }, 3000);
      }
      this.remainingTime -= 1 * 1000;
    }, 1 * 1000);

    this.zoneService.sensorPair(this.zoneId, this.sensorKind).subscribe(result => {
      this.socketService.setupSocketConnection(() => {
        this.startListeningPairingSocket((result) => {
          this.sensor = new Sensor(result.sensor.name, result.sensor.id);
          this.pairingEnded = true;
          this.pairingSucceeded = true;
          this.socketService.closeSocketConnection();
          setTimeout(() => this.stepper.next());
        });
      });

    });

    // setTimeout(() => {
    //   this.stepper.next();
    // });
  }

  get remainingTimePercentage() {
    let result = 100 - (this.remainingTime / (WAITING_TIME)) * 100
    return result;
  };

  resetStepper() {
    this.pairingStarted = false;
    this.pairingEnded = false;
    clearInterval(this.countdownInterval);
    this.remainingTime = WAITING_TIME;
    this.progressBarMode = 'determinate';
    this.stepper.reset();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  startListeningPairingSocket(callback) {
    this.socketService.joinGatewayUpdates(this.zoneId);
    this.socketService.listenPairingDone((result) => {
      callback(result);
    });

  }

  openSensor() {
    this.dialogRef.close();
    this.router.navigate(['sensor', this.sensor._id, 'reports']);
  }





}
