<div class="page-container">
    <mat-form-field appearance="none">
        <mat-select class="page-header header-select" [(value)]="selectedZoneId"
                    (selectionChange)="onZoneSelect($event)">
            <mat-option [value]="_zone._id"
                        *ngFor="let _zone of zones">
                {{ _zone.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <nav mat-tab-nav-bar>
        <a mat-tab-link
           *ngFor="let link of navLinks"
           [routerLink]="link.link"
           routerLinkActive #rla="routerLinkActive"
           [active]="rla.isActive">
            {{link.label}}
        </a>
    </nav>
    <div class="container">
        <router-outlet *ngIf="!isLoading"></router-outlet>
        <div class="loading-wrapper-top" *ngIf="isLoading">
            <mat-spinner diameter="30"></mat-spinner>
        </div>
    </div>
</div>