<div class="container">
    <h2>Configuration</h2>
    <div class="row">
        <div class="label" *ngIf="data?.dayNightAvailable">Day</div>
        <mat-form-field class="input-field">
            <mat-label>Min</mat-label>
            <input placeholder="From" type="number" name="name" [(ngModel)]="data.alert.day.min" matInput>
        </mat-form-field>
        <mat-form-field class="input-field">
            <mat-label>Max</mat-label>
            <input placeholder="To" type="number" name="name" [(ngModel)]="data.alert.day.max" matInput>
        </mat-form-field>
    </div>
    <div class="row" *ngIf="data?.dayNightAvailable">
        <div class="label">Night</div>
        <mat-form-field class="input-field">
            <mat-label>Min</mat-label>
            <input placeholder="From" type="number" name="name" [(ngModel)]="data.alert.night.min" matInput>
        </mat-form-field>
        <mat-form-field class="input-field">
            <mat-label>Max</mat-label>
            <input placeholder="To" type="number" name="name" [(ngModel)]="data.alert.night.max" matInput>
        </mat-form-field>
    </div>
    <mat-dialog-actions align="end">
        <button mat-button [mat-dialog-close]="undefined">Cancel</button>
        <button mat-flat-button color="primary" (click)="onConfirm()">Confirm</button>
    </mat-dialog-actions>
    <div class="loading-wrapper" *ngIf="isLoading">
        <mat-spinner diameter="30"></mat-spinner>
    </div>
</div>