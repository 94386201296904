<span class="material-icons menu button" (click)="showMenu.emit()">menu</span>
<img src="/assets/images/brand-header.svg" class="brand" routerLink="/">
<div class="right-actions">
    <span class="material-icons alert-wrapper button"
          (click)="openNotificationsDialog()">notifications</span>
    <div class="user-avatar" (click)="isProfileOverlayShowing = !isProfileOverlayShowing">
        {{user | slice:0:1 | uppercase}}</div>
</div>
<div class="user-floating-menu" *ngIf="isProfileOverlayShowing">
    <mat-card class="card">
        <div class="user-avatar large">{{user | slice:0:1 | uppercase}}</div>
        <div class="profile-name">{{user}}</div>
        <div class="user-mail">{{user}}</div>
        <div class="change-password menu-button">{{'Change Password' | uppercase}}</div>
        <div class="sign-out menu-button" (click)="logout()">{{'Sign out' | uppercase}}</div>
    </mat-card>
</div>