import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { Action } from 'rxjs/internal/scheduler/Action';
import { SocketIoService } from 'src/app/services/socket-io.service';
import { ZoneService } from 'src/app/services/zone.service';
import { ActionLog } from '../../models/actionLog';
import { rowsAnimation } from './template.animation';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.less'],
  animations: [rowsAnimation]
})
export class LogComponent implements OnInit, OnDestroy {
  selectedRange: any = {};
  dataSource: MatTableDataSource<ActionLog>;
  displayedColumns: string[] = ['createdAt', 'user', 'message'];
  pageSize = 10;
  categories: any;
  loading: boolean;
  private logSubscription: Subscription;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') searchInput: ElementRef;

  constructor(private zoneService: ZoneService, private socketService: SocketIoService,
    private datePipe: DatePipe) {
    this.logSubscription = this.socketService.logValueMessages$.subscribe(data => {
      if (data !== null) {
        if (this.dataSource === undefined) {
          return;
        }
        this.dataSource.data.unshift(new ActionLog(data));
        this.dataSource.data = this.dataSource.data;
      }
    })
  }

  ngOnInit(): void {
    this.fetchData();
  }

  ngOnDestroy() {
    this.logSubscription.unsubscribe();

  }

  fetchData() {
    this.loading = true;
    var request;
    if (this.selectedRange) {
      var from;
      var to;
      from = new Date(this.selectedRange.from).getTime();
      to = new Date(this.selectedRange.to);
      to = new Date(to.getFullYear(), to.getMonth(), to.getDate(), 23, 59).getTime();
      request = {
        from: from,
        to: to,

      }
    }

    this.zoneService.log(request).subscribe(response => {
      this.loading = false;
      this.handleResult(response);
    })
  }

  handleResult(result) {
    if (result.length === 0) {
      this.dataSource = new MatTableDataSource();
      return;
    };
    this.dataSource = new MatTableDataSource(result.reverse());
    this.dataSource.filterPredicate = ((data, filter) =>
      this.datePipe.transform(data.createdAt, 'dd.MM.yy HH:mm:ss').includes(filter) ||
      data.user?.name.toLowerCase().includes(filter) ||
      data.message?.toLowerCase().includes(filter) ||
      this.categories?.includes(data.category) ||
      this.categories?.includes('errors') && data.isError
    );
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();


    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  onMultipleChoose(event: MatSelectChange) {
    const searchInput = (this.searchInput.nativeElement as HTMLInputElement).value;
    if (searchInput.length > 0) {
      this.dataSource.filter = searchInput;
      return;
    }
    if (searchInput.length === 0 && event.value.length === 0) {
      this.dataSource.filter = '';
      return
    }
    if (searchInput.length === 0) {
      this.dataSource.filter = event.value;

      if (event.value.includes('report')) {

      }

      return
    }

  }



}
