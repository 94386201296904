import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'bofilter',
    pure: true
})
export class BackofficeFilterPipe implements PipeTransform {
    transform(items: any[], search): any {
        if (!search || !search.v) {
            return items;
        }

        return items.filter(item => item[search.k].toLowerCase().indexOf(search.v.toLowerCase()) !== -1);
    }
}