import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SensorListComponent } from './sensor-list/sensor-list.component';
import { SensorAlertSettingComponent } from './sensor-alert-setting/sensor-alert-setting.component';
import { MainFrameComponent } from '../main-frame/main-frame.component';
import { SensorSettingsComponent } from './sensor-settings/sensor-settings.component';
import { SensorComponent } from './sensor/sensor.component';
import { SensorReportsComponent } from './sensor-reports/sensor-reports.component';
import { SensorCalibrationComponent } from './sensor-calibration/sensor-calibration.component';

const routes: Routes = [
  {
    path: 'sensor/:sensorId',
    component: SensorComponent,
    children: [
      {
        path: 'reports',
        component: SensorReportsComponent
      },
      {
        path: 'notifications',
        component: SensorAlertSettingComponent
      },
      {
        path: 'settings',
        component: SensorSettingsComponent
      },
      {
        path: 'calibration',
        component: SensorCalibrationComponent
      },
      {
        path: '**',
        redirectTo: 'reports'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SensorRoutingModule { }
