import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainFrameComponent } from '../main-frame/main-frame.component';
import { AuthGuard } from '../../config/auth.guard';
import { ZoneComponent } from '../zone/zone/zone.component';
import { OverviewComponent } from './overview/overview.component';
import { AppliancesListComponent } from './appliances-list/appliances-list.component';
import { ApplianceRuleListComponent } from './appliance-rule-list/appliance-rule-list.component';
import { AlertSettingsComponent } from './alert-settings/alert-settings.component';
import { NotesComponent } from './notes/notes.component';
import { ZoneSettingsComponent } from './zone-settings/zone-settings.component';

const routes: Routes = [
    {
        path: 'zone/:zoneId',
        component: ZoneComponent,
        children: [
            {
                path: 'overview',
                component: OverviewComponent,
            },
            {
                path: 'appliances',
                component: AppliancesListComponent,
            },
            {
                path: 'rules',
                component: ApplianceRuleListComponent,
            },
            {
                path: 'notifications',
                component: AlertSettingsComponent,
            },
            {
                path: 'notes',
                component: NotesComponent,
            },
            {
                path: 'settings',
                component: ZoneSettingsComponent,
            },
            {
                path: '**',
                redirectTo: 'overview'
            }


        ]
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ZoneRoutingModule { }
