<div class="page-container">
    <div class="page-header">
        Reports
    </div>
    <div class="container">
        <mat-card>
            <div class="chart-properties">
                <mat-form-field appearance="legacy">
                    <mat-label>Choose Zone</mat-label>
                    <mat-select [(ngModel)]="selectedZone">
                        <mat-option *ngFor="let zone of zoneList" [value]="zone">
                            {{zone.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="legacy">
                    <mat-label>Choose Parameter</mat-label>
                    <mat-select multiple [(ngModel)]="selectedTypes" [disabled]="selectedZone._id === undefined">
                        <mat-option *ngFor="let sensor of sensorTypeOptions" [value]="sensor">
                            {{sensor | sensorDisplayName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="legacy">
                    <mat-label>Date Range</mat-label>
                    <mat-select [(ngModel)]="dateRangeOption" (selectionChange)="onDateRangeChange($event)"
                                [disabled]="selectedZone._id === undefined">
                        <mat-option [value]="'none'">None</mat-option>
                        <mat-option [value]="'cycle-state'">Cycle State</mat-option>
                        <mat-option [value]="'cycle'">Cycle</mat-option>
                        <mat-option [value]="'custom-range'">Custom</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="legacy" *ngIf="dateRangeOption === 'custom-range'">
                    <mat-label>Enter a date range</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="Start date" [(ngModel)]="selectedRange.from">
                        <input matEndDate placeholder="End date" [(ngModel)]="selectedRange.to">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                <mat-form-field appearance="legacy"
                                *ngIf="dateRangeOption === 'cycle-state' || dateRangeOption === 'cycle'">
                    <mat-label>Choose an option</mat-label>
                    <mat-select [(ngModel)]="selectedState">
                        <mat-option *ngFor="let item of stateHistoryOptions" [value]="item">{{item.state}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <div>
                    <mat-label class="compare">Compare</mat-label>
                </div>
                <mat-slide-toggle (change)="shouldCompare = $event.checked" [color]="'primary'"></mat-slide-toggle>
            </div>
            <div class="chart-properties" *ngIf="shouldCompare">
                <mat-form-field appearance="legacy">
                    <mat-label>Choose Zone</mat-label>
                    <mat-select [(ngModel)]="selectedComparedZone">
                        <mat-option *ngFor="let zone of zoneList" [value]="zone">
                            {{zone.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="legacy">
                    <mat-label>Choose Parameter</mat-label>
                    <mat-select multiple [(ngModel)]="selectedComparedTypes"
                                [disabled]="selectedComparedZone._id === undefined">
                        <mat-option *ngFor="let sensor of sensorTypeOptions" [value]="sensor">
                            {{sensor | sensorDisplayName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="legacy">
                    <mat-label>Date Range</mat-label>
                    <mat-select [(ngModel)]="comparedDateRangeOption"
                                (selectionChange)="onDateRangeChange($event, 'compared')"
                                [disabled]="selectedComparedZone._id === undefined">
                        <mat-option [value]="'none'">None</mat-option>
                        <mat-option [value]="'cycle-state'">Cycle State</mat-option>
                        <mat-option [value]="'cycle'">Cycle</mat-option>
                        <mat-option [value]="'custom-range'">Custom</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="legacy" *ngIf="comparedDateRangeOption === 'custom-range'">
                    <mat-label>Enter a date range</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="Start date" [(ngModel)]="selectedComparedRange.from">
                        <input matEndDate placeholder="End date" [(ngModel)]="selectedComparedRange.to">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                <mat-form-field appearance="legacy"
                                *ngIf="comparedDateRangeOption === 'cycle-state' || comparedDateRangeOption === 'cycle'">
                    <mat-label>Choose an option</mat-label>
                    <mat-select [(ngModel)]="selectedComparedState">
                        <mat-option *ngFor="let item of comparedStateHistoryOptions" [value]="item">{{item.state}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="apply-btn">
                <button mat-flat-button [disabled]="selectedZone._id === undefined" color="primary" class="align-right"
                        style="margin-right: 8px;"
                        (click)="exportToCSV()">Export to CSV
                    <mat-icon matSuffix>
                        file_download
                    </mat-icon>
                </button>
                <button mat-flat-button [disabled]="selectedZone._id === undefined" color="primary" class="align-right"
                        (click)="fetchReport()">Apply</button>

            </div>
        </mat-card>
        <div class="compare">

        </div>
        <div class="charts-container">
            <apx-chart
                       [colors]="chartOptions.colors"
                       [series]="chartOptions.series"
                       [chart]="chartOptions.chart"
                       [xaxis]="chartOptions.xaxis"
                       [markers]="chartOptions.markers"
                       [stroke]="chartOptions.stroke"
                       [yaxis]="chartOptions.yaxis"
                       [dataLabels]="chartOptions.dataLabels"
                       [title]="chartOptions.title"
                       [fill]="chartOptions.fill"
                       [tooltip]="chartOptions.tooltip"
                       [legend]="chartOptions.legend"></apx-chart>
            <apx-chart *ngIf="shouldCompare"
                       [colors]="comparedChartOptions.colors"
                       [series]="comparedChartOptions.series"
                       [chart]="comparedChartOptions.chart"
                       [xaxis]="comparedChartOptions.xaxis"
                       [markers]="comparedChartOptions.markers"
                       [stroke]="comparedChartOptions.stroke"
                       [yaxis]="comparedChartOptions.yaxis"
                       [dataLabels]="comparedChartOptions.dataLabels"
                       [title]="comparedChartOptions.title"
                       [fill]="comparedChartOptions.fill"
                       [tooltip]="chartOptions.tooltip"
                       [legend]="chartOptions.legend"></apx-chart>

        </div>
        <div class="loading-wrapper-transparent" *ngIf="isLoading">
            <mat-spinner diameter="30"></mat-spinner>
        </div>
    </div>

</div>