export const SensorFormats = {
    temperature: {
        celsius: "°C",
        fahrenheit: "°F"
    },
    length: {
        meter: "Meter",
        feet: "Feet"
    },
    humidity: "RH%",
    hpa: "hPa",
    lux: "lux",
    depth: "Meter",
    orp: "Orp",
    watt: "WATT",
    tds: "PPM",
    con: "mS/cm",
    ph: "pH",
    rhs: "RH%",
    lqi: "LQI",
    co2: "PPM",
    rssi: "dB",
    wdo: "PPM",
    uv: "UV",
    vpd: 'kPa',
    ecs: 'mS/cm',
    battery: '%',
    ethylene: "PPM",
    flow: "L/H",
    pressure: "Bar"
}