import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sensor } from 'src/app/models/sensor';
import { Zone } from 'src/app/models/zone';
import { DataService } from 'src/app/services/data.service';
import { SensorService } from 'src/app/services/sensor.service';
import { ZoneService } from 'src/app/services/zone.service';

@Component({
  selector: 'app-sensor-settings',
  templateUrl: './sensor-settings.component.html',
  styleUrls: ['./sensor-settings.component.less']
})
export class SensorSettingsComponent implements OnInit {
  zones: Zone[];
  selectedZoneId: string;
  sensor: Sensor;
  constructor(private zoneService: ZoneService,
    private dataService: DataService,
    private sensorService: SensorService,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.zoneService.zones(true).subscribe(zones => {
      this.zones = zones;
      this.dataService.sharedSensor$.subscribe(sensor => {
        this.sensor = sensor;
        this.selectedZoneId = this.zones?.find(x => x._id === this.sensor.zone)?._id;
      })
    });

  }

  onZoneSelect(event: MatSelectChange) {
    // this.sensorService.assignSensorToZone(this.sensor._id, event.value).subscribe(result => {
    //   console.log(result);
    //   this.sensor.zone = event.value;
    // }
    // );
    this.openSnackBar('Successfully Assigned Sensor')

  }

  openSnackBar(message: string, action: string = '') {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

}
