import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { min } from 'rxjs/operators';

export class CustomTime {
    hours: number;
    minutes: number;
    seconds: number;
    milliseconds?: number;
}

@Component({
    selector: 'custom-time-input-field',
    templateUrl: './custom-time-input-field.component.html',
    styleUrls: ['./custom-time-input-field.component.less']
})


export class CustomTimeInputField implements OnInit {

    time: CustomTime = new CustomTime();

    @Input() data: number;
    @Input() hasMilliseconds: boolean;
    @Output() onChange = new EventEmitter();


    constructor() {
    }

    ngOnInit() {
        this.millisecondsToTime();


    }

    millisecondsToTime() {
        if (this.hasMilliseconds) {
            let value = this.data / 1000;
            let hours = Math.floor(value / 3600);
            let minutes = Math.floor((value - (hours * 3600)) / 60)
            let seconds = Math.floor((value - (hours * 3600) - (minutes * 60)))
            let milliseconds = Math.round((value - (hours * 3600) - (minutes * 60) - (seconds)) * 1000)
            this.time = {
                hours: hours === 0 ? NaN : hours,
                minutes: minutes === 0 ? NaN : minutes,
                seconds: seconds === 0 ? NaN : seconds,
                milliseconds: milliseconds === 0 ? NaN : milliseconds
            }
            return;
        }

        let value = this.data / 1000;
        let hours = Math.floor(value / 3600);
        let minutes = Math.floor((value - (hours * 3600)) / 60)
        let seconds = (value - (hours * 3600) - (minutes * 60))
        this.time = {
            hours: hours === 0 ? NaN : hours,
            minutes: minutes === 0 ? NaN : minutes,
            seconds: seconds === 0 ? NaN : seconds,
        }
    }

    timeToMilliseconds() {
        let hours = !Number.isNaN(this.time.hours) ? this.time.hours * 3600 : 0;
        let minutes = !Number.isNaN(this.time.minutes) ? this.time.minutes * 60 : 0;
        let seconds = !Number.isNaN(this.time.seconds) ? this.time.seconds : 0;
        let milliseconds = !Number.isNaN(this.time.milliseconds) ? this.time.milliseconds : 0;
        return (hours + minutes + seconds) * 1000 + (this.hasMilliseconds ? milliseconds : 0);
    }

    change() {
        this.onChange.emit(this.timeToMilliseconds());

    }







}