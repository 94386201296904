import { Pipe, PipeTransform } from '@angular/core';
import { ZoneService } from '../services/zone.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const API_PREFIX = environment.apiEndpoint;


@Pipe({
  name: 'authImage'
})



export class AuthImagePipe implements PipeTransform {

  constructor(
    private http: HttpClient
  ) { }

  transform(src: string) {

    return new Observable<string>((observer) => {

      // This is a tiny blank image
      // observer.next('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
      // The next and error callbacks from the observer
      const { next, error } = observer;
      let url = API_PREFIX + src;
      this.http.get(url, { responseType: 'blob' }).subscribe(response => {
        const reader = new FileReader();
        reader.readAsDataURL(response);
        reader.onloadend = function () {
          observer.next(reader.result as string);
        };
      });

      return { unsubscribe() { } };
    });
  }

}
