<!-- <mat-card class="card">
    
    <div class="row" *ngFor="let sensor of sensors">
        <div class="sensor-title">{{sensor.type | sensorDisplayName}}</div>
        <div class="actions-group">
            <button mat-flat-button color="primary"
                    (click)="calibrate(sensor.type)">Calibrate</button>
        </div>
        <div class="loading-wrapper" *ngIf="sensor.isLoading">
            <mat-spinner diameter="30"></mat-spinner>
        </div>
    </div>

</mat-card> -->

<button mat-flat-button color="primary"
        (click)="calibrate()">Calibrate</button>