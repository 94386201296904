<div class="page-container">
    <div class="page-header">
        Settings
    </div>
    <div class="container">
        <mat-card class="card">
            <!-- <div class="row">
                <mat-form-field class="half">
                    <mat-label>Timezone</mat-label>
                    <mat-select>
                        <mat-option *ngFor="let option of timezoneOptions" [value]="option.value">
                            {{option.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div> -->
            <div class="row">
                <div class="wrapper">
                    <div class="title">Measure Units</div>
                    <mat-radio-group class="wrapper" [(ngModel)]="settings.measureUnit"
                                     (change)="onMeasureUnitChange($event)">
                        <mat-radio-button class="radio-button" [value]="'metric'">Metric</mat-radio-button>
                        <mat-radio-button class="radio-button" [value]="'imperial'">Imperial</mat-radio-button>
                        <mat-radio-button class="radio-button" [value]="'custom'">Custom</mat-radio-button>
                    </mat-radio-group>
                    <div class="custom" *ngIf="settings.measureUnit === 'custom'">
                        <div class="title">Temperature</div>
                        <mat-radio-group class="wrapper" [(ngModel)]="settings.temperature"
                                         (change)="settingChanged('temperature', $event)">
                            <div class="actions-group">
                                <mat-radio-button class="radio-button" [value]="'celsius'">Celsius</mat-radio-button>
                                <mat-radio-button class="radio-button" [value]="'fahrenheit'">Fahrenheit
                                </mat-radio-button>
                            </div>
                        </mat-radio-group>
                        <div class="title">Volume</div>
                        <mat-radio-group class="wrapper" [(ngModel)]="settings.volume"
                                         (change)="settingChanged('volume', $event)">
                            <div class="actions-group">
                                <mat-radio-button class="radio-button" [value]="'liter'">Liter</mat-radio-button>
                                <mat-radio-button class="radio-button" [value]="'gallon'">Gallon</mat-radio-button>
                            </div>

                        </mat-radio-group>
                        <div class="title">Length</div>
                        <mat-radio-group class="wrapper" [(ngModel)]="settings.length"
                                         (change)="settingChanged('length', $event)">
                            <div class="actions-group">
                                <mat-radio-button class="radio-button" [value]="'meter'">Meters</mat-radio-button>
                                <mat-radio-button class="radio-button" [value]="'feet'">Feet</mat-radio-button>
                            </div>

                        </mat-radio-group>
                        <div class="title">Weight</div>
                        <mat-radio-group class="wrapper" [(ngModel)]="settings.weight"
                                         (change)="settingChanged('weight', $event)">
                            <div class="actions-group">
                                <mat-radio-button class="radio-button" [value]="'gram'">Grams</mat-radio-button>
                                <mat-radio-button class="radio-button" [value]="'ounce'">Ounces</mat-radio-button>
                            </div>

                        </mat-radio-group>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="wrapper">
                    <div class="title">Water Sensor EC Unit</div>
                    <mat-radio-group class="wrapper" [(ngModel)]="settings.ecUnit"
                                     (change)="settingChanged('ecUnit', $event)">
                        <mat-radio-button class="radio-button" [value]="'EC'">EC (mS/cm)</mat-radio-button>
                        <mat-radio-button class="radio-button" [value]="'TDS'">TDS (PPM)</mat-radio-button>
                    </mat-radio-group>

                </div>
            </div>
        </mat-card>

    </div>
</div>